import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import { Card, Page, Layout, Button, Stack, Badge, Banner } from '@shopify/polaris';
import Form from './Form';
import ExtensionPreview from './ExtensionPreview';
import { template } from 'lodash';
import { API_APP, NEW_API_APP } from '../../../../config';
import { http } from '../../../../utils/http';
import * as _ from 'lodash';
import { PlusMinor } from '@shopify/polaris-icons';
import history from '../../../../utils/history';

interface Props {}

interface IProduct {
  [order: string]: {
    id: string;
    label: string;
    image?: string;
    variants?: string;
  };
}

interface State {
  products: any;
  fetched_products: any;
  selected_product_id: string;
  current_product: any;
  discount: string;
  title: string;
  offer_name: string;
  subtitle: string;
  countdown_text: string;
  countdown_minutes: string;
  rendered_template: string | boolean;
  filtered_products: any;
  loading: boolean;
  selected_variants: any;
  productsById: any;
  product_position: any;
  triggers: any;
  products_info: any;
  offerId: any;
  is_active: boolean;
  triggers_with_conflicts: any;
  accept_button_title: string;
  decline_button_title: string;

  [k: string]: string | boolean | any;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class PostPurchaseUpsell extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const { postPurchaseExtension } = settings;

    const offerId: any = _.chain(history)
      //@ts-ignore
      .get('location.pathname', '/new')
      .split('/')
      .last()
      .value();

    const emptyOffer = {
      products: {},
      discount: '20',
      title: 'Before you go!',
      subtitle: "Here's a special one-time offer. Add this to your order and save",
      countdown_text: 'This special offer ends in',
      countdown_minutes: '03:00',
      accept_button_title: 'Pay now',
      decline_button_title: 'Decline this offer',
      selected_variants: [],
      triggers: {
        specific_products: {
          wildcard: true,
        },
        specific_product_types: {
          wildcard: true,
        },
        specific_collections: {
          wildcard: true,
        },
        specific_tags: {
          wildcard: true,
        },
        specific_amount: {
          wildcard: true,
        },
      },
      offer_name: 'New Offer',
    };

    let currentOffer: any = {};

    if (offerId === 'new') {
      currentOffer = emptyOffer;
    }

    currentOffer = _.get(postPurchaseExtension, ['offers', offerId], emptyOffer);

    const {
      products,
      discount,
      title,
      subtitle,
      countdown_text,
      countdown_minutes,
      selected_variants,
      triggers,
      offer_name,
      is_active,
      accept_button_title,
      decline_button_title,
    } = currentOffer;

    const product_position = 0;
    const current_product = _.get(products, [product_position], {});
    const selected_product_id = current_product.value;
    const filter_criteria = current_product.label;

    this.state = {
      products: products,
      triggers: triggers,
      product_position: product_position,
      current_product: current_product,
      selected_product_id: selected_product_id,
      filter_criteria: filter_criteria,
      discount: discount,
      title: title,
      offer_name: offer_name,
      subtitle: subtitle,
      countdown_text: countdown_text,
      countdown_minutes: countdown_minutes,
      accept_button_title: accept_button_title,
      decline_button_title: decline_button_title,
      rendered_template: '',
      filtered_products: [],
      selected_variants: selected_variants || [],
      loading: true,
      productsById: {},
      fetched_products: [],
      products_info: [],
      is_active: is_active || false,
      offerId,
      triggers_with_conflicts: {},
    };
  }

  toastText: string = 'Settings has been updated';

  handleChange = (obj: { [key: string]: string | any }) => {
    if (obj.selected_variants) {
      let { current_product } = this.state;
      obj.current_product = current_product;
      obj.current_product.selected_variants = obj.selected_variants;
    }

    if (obj.selected_product_id) {
      const { productsById } = this.state;
      let current_product = productsById[obj.selected_product_id] || {};
      obj.filter_criteria = current_product.label;
      obj.current_product = current_product;
      obj.products = {
        ...this.state.products,
        [this.state.product_position]: current_product,
      };
      obj.current_product.selected_variants = _.map(obj.current_product.variants, 'value');
    }

    if (obj.filter_criteria === '') {
      obj.filtered_products = this.state.fetched_products;
    }

    if (obj.product_position) {
      obj.current_product = this.state.products[obj.product_position] || {};
      obj.selected_product_id = obj.current_product.value;
      obj.filter_criteria = obj.current_product.label;
    }

    if (obj.filter_criteria) {
      obj.filtered_products = _.filter(this.state.fetched_products, (product) => {
        return _.lowerCase(product.label).startsWith(_.lowerCase(obj.filter_criteria));
      });
    }
    this.setState(obj);
  };

  checkForCollisions = async () => {
    const { settings } = this.injected.rootStore.settingsStore;
    const { triggers, offerId } = this.state;
    const offers = _.get(settings, 'postPurchaseExtension.offers', {});
    let triggers_with_conflicts: any = {};
    const processed_offers = _.chain(offers)
      .filter((value, index) => {
        //exclude current offer
        return index !== offerId;
      })
      .filter('is_active')
      .forEach((offer, key) => {
        const offer_triggers = offer.triggers;
        _.forEach(offer_triggers, (trigger, trigger_name) => {
          const current_offer_trigger = triggers[trigger_name];
          if (trigger.wildcard || current_offer_trigger.wildcard) {
            const offers_list = _.get(triggers_with_conflicts, [trigger_name, 'offers'], []);
            triggers_with_conflicts[trigger_name] = {
              fields: 'wildcard',
              offer_name: offer.offer_name,
            };
            return;
          } else {
            if (trigger_name === 'specific_products') {
              console.log(
                'trigger.settings.products',
                trigger.settings.products,
                current_offer_trigger,
              );
              const intersection = _.intersection(
                trigger.settings.products,
                current_offer_trigger.settings.products,
              );
              if (intersection.length) {
                triggers_with_conflicts[trigger_name] = {
                  offer_name: offer.offer_name,
                  intersection,
                };
              }
            }
            if (trigger_name === 'specific_tags') {
              const intersection = _.intersection(
                trigger.settings.tags,
                current_offer_trigger.settings.tags,
              );
              if (intersection.length) {
                triggers_with_conflicts[trigger_name] = {
                  offer_name: offer.offer_name,
                  intersection,
                };
              }
            }
            if (trigger_name === 'specific_collections') {
              const intersection = _.intersection(
                trigger.settings.collections,
                current_offer_trigger.settings.collections,
              );
              if (intersection.length) {
                triggers_with_conflicts[trigger_name] = {
                  offer_name: offer.offer_name,
                  intersection,
                };
              }
            }
            if (trigger_name === 'specific_product_types') {
              const intersection = _.intersection(
                trigger.settings.types,
                current_offer_trigger.settings.types,
              );
              if (intersection.length) {
                triggers_with_conflicts[trigger_name] = {
                  offer_name: offer.offer_name,
                  intersection,
                };
              }
            }
            if (trigger_name === 'specific_amount') {
              if (
                (current_offer_trigger.settings.min >= trigger.settings.min &&
                  current_offer_trigger.settings.min <= trigger.settings.max) ||
                (current_offer_trigger.settings.max <= trigger.settings.max &&
                  current_offer_trigger.settings.max >= trigger.settings.min)
              ) {
                triggers_with_conflicts[trigger_name] = {
                  offer_name: offer.offer_name,
                };
              }
            }
          }
        });
      })
      .value();

    // console.log('triggers_with_conflicts', triggers_with_conflicts);
    this.setState({
      triggers_with_conflicts,
    });
    return triggers_with_conflicts;
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
      settings,
    } = this.injected.rootStore.settingsStore;
    const {
      discount,
      title,
      subtitle,
      countdown_text,
      countdown_minutes,
      selected_product_id,
      products,
      triggers,
      offer_name,
      offerId,
      accept_button_title,
      decline_button_title,
    } = this.state;

    if (!selected_product_id) {
      openToast('Please select product');
      return;
    }
    const existingOffers = _.get(settings, 'postPurchaseExtension.offers', {});
    let preparedOfferId = offerId;

    if (offerId === 'new') {
      //generate new offer id
      preparedOfferId = _.chain(settings)
        .get('postPurchaseExtension.offers', { 0: {} })
        .keys()
        .last()
        .toNumber()
        .add(1)
        .value();
    }
    const collisions = await this.checkForCollisions();
    if (!_.isEmpty(collisions)) {
      return;
    }
    if (_.some(products, (product) => !product.image)) {
      return;
    }
    try {
      closeToast();
      await updateSettings({
        type: 'postPurchaseExtension',
        data: {
          offers: {
            ...existingOffers,
            [preparedOfferId]: {
              ...existingOffers[preparedOfferId],
              products: {
                ...products,
              },
              triggers,
              discount,
              title,
              offer_name,
              subtitle,
              countdown_minutes,
              countdown_text,
              accept_button_title,
              decline_button_title,
            },
          },
        },
      });
      this.setState({
        offerId: preparedOfferId,
      });
      openToast(this.toastText);
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  toggleActive = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
      settings,
    } = this.injected.rootStore.settingsStore;
    const { offerId, is_active } = this.state;
    const existingOffers = _.get(settings, 'postPurchaseExtension.offers', {});

    try {
      closeToast();
      await updateSettings({
        type: 'postPurchaseExtension',
        data: {
          offers: {
            ...existingOffers,
            [offerId]: {
              ...existingOffers[offerId],
              is_active: !existingOffers[offerId].is_active,
            },
          },
        },
      });
      openToast(this.toastText);
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  onToggleUpsell = async () => {
    const { updateSettings, settings } = this.injected.rootStore.settingsStore;
    try {
      await updateSettings({
        data: {
          extensionEnabled: !settings.postPurchaseExtension.extensionEnabled,
        },
        type: 'postPurchaseExtension',
      });
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  fetchProducts = async () => {
    try {
      let response: Response = await http(`${API_APP}/get-products`, 'GET');
      response = await response.json();
      let fetched_products = _.get(response, 'products', []);
      let products_info = _.chain(response)
        .get('products_info', [])
        //@ts-ignore
        .mapValues('edges')
        .mapValues((value: any) => {
          return _.map(value, (item) => {
            if (typeof item.node !== 'object') {
              return {
                label: item.node,
                value: _.lowerCase(item.node),
              };
            }
            return {
              label: item.node.title,
              value: _.lowerCase(item.node.title),
            };
          });
        })
        .value();

      fetched_products = _.map(fetched_products, (product) => {
        return {
          label: product.title,
          value: product.id.toString(),
          id: product.id.toString(),
          image: _.get(product, 'image.src', ''),
          variants: _.map(product.variants, (variant) => {
            return {
              ...variant,
              value: variant.id.toString(),
              label: variant.title,
            };
          }),
          selected_variants: product.selected_variants || [],
        };
      });
      const productsById = _.keyBy(fetched_products, 'value');

      let filtered_products = [];
      if (this.state.filter_criteria) {
        filtered_products = _.filter(fetched_products, (product) => {
          return _.lowerCase(product.label).startsWith(_.lowerCase(this.state.filter_criteria));
        });
      }

      this.setState({
        loading: false,
        filtered_products: this.state.filter_criteria ? filtered_products : fetched_products,
        fetched_products,
        products_info,
        productsById,
      });
    } catch (err) {
      console.log('[error]', err);
    }
  };

  async componentDidMount() {
    await this.fetchProducts();
  }

  render() {
    const {
      selected_product_id,
      discount,
      title,
      offer_name,
      subtitle,
      countdown_text,
      countdown_minutes,
      accept_button_title,
      decline_button_title,
      products,
      filtered_products,
      filter_criteria,
      loading,
      product_variants,
      selected_variants,
      current_product,
      product_position,
      fetched_products,
      triggers,
      products_info,
      offerId,
      triggers_with_conflicts,
    } = this.state;
    const { settings } = this.injected.rootStore.settingsStore;
    const is_active = _.get(
      settings,
      ['postPurchaseExtension', 'offers', offerId, 'is_active'],
      false,
    );
    return (
      <>
        {!_.isEmpty(triggers_with_conflicts) && (
          <Page>
            <Banner status={'warning'}>
              Your trigger settings cannot be the same for multiple offers. Please adjust the
              trigger settings.
            </Banner>
          </Page>
        )}
        <Page
          title={offer_name}
          breadcrumbs={[
            { content: 'Products', onAction: () => history.push(`/post-purchase-upsell`) },
          ]}
          titleMetadata={
            offerId !== 'new' ? (
              <Badge status={is_active ? 'success' : 'warning'}>
                {is_active ? 'Published' : 'Unpublished'}
              </Badge>
            ) : (
              <Badge status="new">Draft</Badge>
            )
          }
          primaryAction={
            <Stack>
              <Button onClick={this.toggleActive} disabled={offerId === 'new'}>
                {is_active ? 'Unpublish' : 'Publish'}
              </Button>
              <Button primary onClick={this.handleSubmit}>
                Save
              </Button>
              {/*<Button monochrome>Preview</Button>*/}
              {/*<Button destructive>Delete</Button>*/}
            </Stack>
          }
          subtitle={'Create a post-purchase page and one-time offer'}
        >
          <Layout>
            <Layout.Section oneHalf>
              <Form
                products={products}
                fetched_products={fetched_products}
                products_info={products_info}
                current_product={current_product}
                selected_product_id={selected_product_id}
                discount={discount}
                title={title}
                offer_name={offer_name}
                subtitle={subtitle}
                countdown_text={countdown_text}
                countdown_minutes={countdown_minutes}
                changeField={this.handleChange}
                handleSubmit={this.handleSubmit}
                filter_criteria={filter_criteria}
                triggers={triggers}
                filtered_products={filtered_products}
                product_variants={product_variants}
                selected_variants={selected_variants}
                product_position={product_position}
                loading={loading}
                triggers_with_conflicts={triggers_with_conflicts}
                accept_button_title={accept_button_title}
                decline_button_title={decline_button_title}
              />
            </Layout.Section>
            <Layout.Section oneHalf>
              <ExtensionPreview
                product={current_product}
                discount={discount}
                title={title}
                subtitle={subtitle}
                countdown_text={countdown_text}
                countdown_minutes={countdown_minutes}
                store_name={settings.shopData.name}
                accept_button_title={accept_button_title}
                decline_button_title={decline_button_title}
                products={products}
              />
            </Layout.Section>
          </Layout>
        </Page>
      </>
    );
  }
}

export default PostPurchaseUpsell;
