import { action, computed, observable, runInAction } from 'mobx';
import RootStore from '../RootStore';
import {
  AnalyticsTotalType,
  BlueprintsType,
  IAnalytic,
  IAnalytics,
  IDiscountCode,
  IDiscountCodeResponse,
  IItemProgressBar,
  INotification,
  INotificationData,
  IProfile,
  ISidebarItems,
  IToast,
  NotificationType,
  Settings,
} from '../../typings/elements.typing';
import { API_APP, NEW_API_APP } from '../../config';
import { http } from '../../utils/http';

export default class SettingsStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable settings: Settings = {
    shopName: '',
    profile: {
      onBoardingComplete: false,
      optimizeCheckout: false,
      firstSale: false,
      phone: '',
      email: '',
      firstName: '',
    },
    shopData: {
      name: '',
    },
    campaignsSettings: {
      'shipping-notification': false,
      're-order': true,
      'order-confirmation': false,
    },
    payment: {
      name: '',
    },
    customTemplates: {
      'shipping-notification': [],
      're-order': [],
      'order-confirmation': [],
    },
    selectedProducts: {},
    upsell: {},
  };

  @observable
  analytics: IAnalytics = {
    total: [],
    contact: {
      contactCountFromLastVisit: 0,
      contactCount: 0,
    },
  };

  @observable
  profile: IProfile = {
    id: '',
    firstName: '',
    lastName: '',
    picture: '',
  };

  @observable
  toast: IToast = {
    show: false,
    content: '',
  };

  @observable
  sidebar: ISidebarItems = {
    isCouponPopupExtend: false,
  };

  @observable
  isLoaded: boolean = false;

  @observable
  notifications: INotification[] = [];

  @action.bound
  setSidebar = (settings: ISidebarItems): void => {
    this.sidebar = { ...this.sidebar, ...settings };
  };

  @action.bound
  closeToast = (): void => {
    this.toast = {
      show: false,
      content: '',
    };
  };

  @action.bound
  openToast = (content: string): void => {
    this.toast = {
      show: true,
      content,
    };
  };

  @observable
  itemsProgressBarData: IItemProgressBar[] = [
    {
      id: 'cartUpsellCampaign',
      title: 'Launch Cart Upsell Campaign',
      link: '/pre-purchase-upsell/new',
      isChecked: true,
    },
    {
      id: 'postPurchaseUpsellCampaign',
      title: 'Launch Post Purchase Upsell Campaign',
      link: '/post-purchase-upsell/new',
      isChecked: true,
    },
    {
      id: 'firstCartUpsell',
      title: 'First Cart Upsell ',
      link: '/campaigns/automated-reorder/products',
      isChecked: true,
    },
    {
      id: 'firstPostPurchaseUsell',
      title: 'First Post Purchase Upsell',
      link: '/post-purchase-upsell/basic-configuration',
      isChecked: true,
    },
  ];

  @observable discountCodes: IDiscountCode[] = [];

  @action.bound
  updateWelcomeMessage = async (): Promise<void> => {
    try {
      const response: Response = await http(`${API_APP}/settings`, 'GET');
      const settings = await response.json();
      this.settings = { ...this.settings, ...settings };
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      error.redirect = true;

      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  getSettings = async (): Promise<void> => {
    try {
      const response: Response = await http(`${API_APP}/settings`, 'GET');
      const settings = await response.json();
      this.settings = { ...this.settings, ...settings };
    } catch (e) {
      const error = await e.json();
      console.log('error', e);
      error.isShow = true;
      error.status = e.status;
      error.error = `We were not able to connect your Facebook page to our Shopify app. Please connect Maxwell to at least one Facebook page and make sure to allow us to manage your page(s) as well as page conversations in Messenger. We only use this permission to allow you to send messages to your store visitors. Learn more <a target="_blank" href="https://help.maxwell.ai/en/articles/3005428-how-to-connect-your-facebook-page-to-the-maxwell-shopify-app">here</a>`;
      this.rootStore.errorStore.setError(error);
      throw new Error();
    }
  };

  @action.bound
  updateSettings = async (
    settings: Partial<Settings>,
    isRequest: boolean = true,
    isAsync: boolean = true,
  ): Promise<void> => {
    try {
      if (isRequest) {
        if (isAsync) {
          await http(`${API_APP}/settings`, 'POST', settings);
        } else {
          console.log('async');
          http(`${API_APP}/settings`, 'POST', settings);
        }
      }

      const AUTOUPDATED_FIELDS = [
        'campaignsSettings',
        'profile',
        'reorderSettings',
        'selectedProducts',
        'postPurchaseExtension',
        'upsell',
      ];
      if (AUTOUPDATED_FIELDS.includes(settings.type)) {
        this.settings[settings.type] = {
          ...this.settings[settings.type],
          ...settings.data,
        };
        return;
      }
      this.settings = { ...this.settings, ...settings };
    } catch (e) {
      console.log('[error]', e);
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);

      throw new Error('Something went wrong');
    }
  };

  @action.bound
  getProfile = async (): Promise<void> => {
    try {
      const response: Response = await http(`${API_APP}/profile`, 'GET');
      const profile = await response.json();

      this.updateProfile(profile);
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  updateProfile = (profile: IProfile) => {
    this.profile = { ...this.profile, ...profile };
  };

  @action.bound
  getAnalytics = async (): Promise<void> => {
    try {
      const response: Response = await http(`${API_APP}/analytics`, 'GET');
      const analytics = await response.json();
      const analyticsData = analytics
        ? analytics.duration.all
        : {
            total: [],
            contact: { contactCountFromLastVisit: 0, contactCount: 0 },
          };
      let analyticsTotalItems: IAnalytic[] = [
        {
          id: 1,
          value: analyticsData.contact.contactCount,
          title: AnalyticsTotalType.contacts,
        },
        {
          id: 2,
          value: analyticsData.total.sent,
          title: AnalyticsTotalType.messages,
        },
        {
          id: 3,
          value: analyticsData.total.orderCount,
          title: AnalyticsTotalType.orders,
        },
        {
          id: 4,
          value:
            analyticsData.total.orderAmount.value + ' ' + analyticsData.total.orderAmount.currency,
          title: AnalyticsTotalType.sales,
        },
      ];

      runInAction(() => {
        this.analytics = {
          total: analyticsTotalItems,
          contact: {
            contactCountFromLastVisit: analyticsData.contact.contactCountFromLastVisit,
            contactCount: analyticsData.contact.contactCount,
          },
        };
      });
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  setNotificationAsRead = (type: NotificationType): void => {
    switch (type) {
      case NotificationType.contact:
        runInAction(
          () =>
            (this.analytics[NotificationType.contact] = {
              contactCountFromLastVisit: 0,
            }),
        );
        break;
    }
  };

  @action.bound
  sendPreviewMessages = async (
    refId: string,
    settings: Partial<Settings>,
  ): Promise<object | undefined> => {
    try {
      return await http(`${API_APP}/previewBlueprint`, 'POST', { refId, settings });
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @computed
  get itemsProgressBar() {
    const { settings } = this.rootStore.settingsStore;

    return this.itemsProgressBarData.map((el: IItemProgressBar) => {
      if (el.id === 'cartUpsellCampaign') {
        return {
          ...el,
          ...{
            isChecked: (settings.profile || {}).cartUpsellCampaign || false,
          },
        };
      } else if (el.id === 'postPurchaseUpsellCampaign') {
        return {
          ...el,
          ...{ isChecked: (settings.profile || {}).postPurchaseUpsellCampaign || false },
        };
      } else if (el.id === 'firstCartUpsell') {
        return { ...el, ...{ isChecked: (settings.profile || {}).firstCartUpsell || false } };
      } else if (el.id === 'firstPostPurchaseUsell') {
        return {
          ...el,
          ...{ isChecked: (settings.profile || {}).firstPostPurchaseUsell || false },
        };
      } else {
        return { ...el, ...{ isChecked: !!this.settings[el.id] } };
      }
    });
  }

  @computed
  get progressBar(): number {
    let num = 0;
    this.itemsProgressBar.forEach((el) => {
      if (el.isChecked) {
        num = num + 100 / this.itemsProgressBar.length;
      }
    });
    return num;
  }

  @action.bound
  getNotifications = async (): Promise<void> => {
    try {
      const response: Response = await http(`${API_APP}/notification`, 'GET');
      const data: INotificationData = await response.json();
      this.notifications = data.notifications;
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @computed
  get progressBarNotification(): INotification | undefined {
    return this.notifications.find(
      (el: INotification) => el.notification === 'onboarding-progress-bar',
    );
  }

  @computed
  get isProgressBarNotification(): boolean {
    return !!this.progressBarNotification;
  }

  @computed
  get notificationsWithoutProgressBar(): INotification[] {
    return this.notifications.filter((el) => el.notification !== 'onboarding-progress-bar');
  }

  @action.bound
  updateNotification = async (data: Partial<INotification>): Promise<void> => {
    try {
      await http(`${API_APP}/notification/update`, 'POST', data);

      this.notifications = this.notifications.map((el) => {
        if (el.notificationId === data.notificationId) {
          return { ...el, ...data };
        }
        return el;
      });
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  getDiscountCodes = async (): Promise<IDiscountCode[] | undefined> => {
    try {
      const response: Response = await http(`${NEW_API_APP}/discountCodes`, 'GET');
      const { data, shopCurrency } = await response.json();
      this.discountCodes = data.price_rules.map((el: IDiscountCodeResponse) => {
        const value =
          el.value_type === 'fixed_amount'
            ? Math.abs(el.value) + shopCurrency.currency + ' OFF'
            : Math.abs(el.value) + '% OFF';

        return {
          label: el.title + ' - ' + value,
          value: el.title,
        };
      });
      return this.discountCodes;
    } catch (e) {
      if (e.json) {
        const error = await e.json();

        error.status = e.status;

        this.rootStore.errorStore.setError(error);
      }
    }
  };

  @action.bound
  setIsLoaded = (bool: boolean): void => {
    runInAction(() => (this.isLoaded = bool));
  };
}
