import React, { ChangeEvent, Component, Ref, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import InputText from '../../../shared/InputText/InputText';
import { Card } from '@shopify/polaris';
import { ContentCardButton } from '../Content.styled';
import { IToken } from '../../../../typings/elements.typing';
import jwtDecode from 'jwt-decode';
//@ts-ignore;
import MixedTags from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import '@yaireo/tagify/dist/tagify.css'; // Tagify CSS
import './form.css';
//@ts-ignore
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';
import TagArea from './TagArea';

interface Props {
  text_message: string;

  changeField({ field, value }: { [key: string]: string }): void;
  handleSubmit(): void;
}

interface State {
  text_message_highlights: string;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class Form extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }
  errorText: string = 'This field is required';

  constructor(props: Props) {
    super(props);
  }

  handleChange = (obj: { [key: string]: string }) => {
    this.props.changeField(obj);
  };

  handleSubmit = () => {
    this.props.handleSubmit();
  };

  getDomain = () => {
    const token: string = localStorage.getItem('accessToken') || '';
    const parseToken: IToken = token ? jwtDecode(token) : {};

    const shopDomain = parseToken.shopDomain || '';

    return shopDomain;
  };

  render() {
    const { text_message } = this.props;

    return (
      <Card.Section>
        <label>Text Message</label>
        <TagArea
          text_message={text_message}
          field={`text_message`}
          changeField={this.handleChange}
          tags={['first_name_customer', 'orderlink', 'shopname', 'ordernumber']}
        />
        <ContentCardButton onClick={this.handleSubmit}>Update</ContentCardButton>
      </Card.Section>
    );
  }
}

export default Form;
