import styled from 'styled-components';
import background from '../../images/background.jpg';

export const Centered = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  line-height: 1;
  color: #ffffff;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 400;
  margin: 10px 0 10px 0;
`;

export const SubTitle = styled.h1`
  font-size: 58px;
  margin-top: 50px;
  white-space: nowrap;
  font-weight: 400;
`;

export const LoadingText = styled.h1`
  font-size: 14px;
  font-weight: 300;
  color: #d8d8d8;
  margin-top: 15px;
`;

export const PageContainer = styled.div`
  position: absolute;
  background-color: #3b3b3b;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`;
