import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import { ContentBody, ContentCard, ContentHeader, ContentTitle } from '../Content.styled';
import {
  CardReminderPreview,
  PreviewButtonQuickReplies,
  PreviewButtonQuickRepliesWrapper,
  PreviewContent,
  PreviewMessageWithMargin,
} from '../AutomatedReorder/AutomatedReorder.styled';
import { Card } from '@shopify/polaris';
import { Mixpanel } from '../../../../utils/analytics';
import Form from './Form';
import { template } from 'lodash';

interface Props {}

interface State {
  text_message: string;
  rendered_template: string | boolean;
  [k: string]: string | boolean;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class OrderConfirmation extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 'order-confirmation';

    this.state = {
      text_message: settings.customTemplates[campaign][0].text,
      rendered_template: '',
    };
  }

  toastText: string = 'Message template has been updated';

  handleChange = (obj: { [key: string]: string }) => {
    const [key] = Object.keys(obj);
    const renderedDummyData = this.renderDummyData(obj[key]);
    if (renderedDummyData) {
      this.setState({
        rendered_template: renderedDummyData,
      });
    }
    this.setState(obj);
  };

  renderDummyData = (text_message: string) => {
    const { settings } = this.injected.rootStore.settingsStore;
    const shopProfile = settings.profile;
    const testData = {
      shopname: settings.shopName || settings.shopData.name || 'shopName',
      ordernumber: '#1005',
      first_name_customer: 'Steve',
      orderlink: 'https://shopify.domain.name.com/orderlink',
    };

    let templateString;
    let renderedTemplate;
    try {
      templateString = template(text_message);
      renderedTemplate = templateString(testData);
      return renderedTemplate;
    } catch (err) {
      console.log('[template]: syntax issue, skipping ');
    }
    return '';
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const { text_message } = this.state;
    try {
      closeToast();
      await updateSettings({
        campaign: 'order-confirmation',
        type: 'template',
        data: [
          {
            step: 0,
            text_message: text_message,
          },
        ],
      });
      openToast(this.toastText);
      // this.setState({ submitted: true });
      await getSettings();
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  componentDidMount() {
    const { text_message } = this.state;
    const renderedDummyData = this.renderDummyData(text_message);
    if (renderedDummyData) {
      this.setState({
        rendered_template: renderedDummyData,
      });
    }
  }

  render() {
    const { text_message, rendered_template } = this.state;
    const { settings } = this.injected.rootStore.settingsStore;

    return (
      <Fragment>
        <ContentHeader>
          <ContentTitle>Order Confirmation</ContentTitle>
        </ContentHeader>
        <ContentBody>
          <ContentCard>
            <Card>
              <Form
                text_message={text_message}
                changeField={this.handleChange}
                handleSubmit={this.handleSubmit}
              />
            </Card>
          </ContentCard>
          <CardReminderPreview>
            <PreviewContent>
              {text_message && (
                //@ts-ignore
                <PreviewMessageWithMargin data-highlight="preview-message-1">
                  {rendered_template}
                </PreviewMessageWithMargin>
              )}
            </PreviewContent>
          </CardReminderPreview>
        </ContentBody>
      </Fragment>
    );
  }
}

export default OrderConfirmation;
