import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../typings/stores.typing';
// import { ContentBody, ContentCard } from '../../Content.styled';
import {
  ContentBody,
  ContentHeader,
  ContentTitle,
  ContentSubtitle,
  ContentWrapper,
  ContentFooter,
  PreviewLabel,
  PreviewContainer,
  PreviewLine,
} from './Settings.styled';
import { Card, Button, Select, FormLayout } from '@shopify/polaris';
import _ from 'lodash';
import history from '../../../../../utils/history';
import 'react-step-progress-bar/styles.css';
//@ts-ignore;
import { ProgressBar, Step } from 'react-step-progress-bar';
import './Settings.css';
import timezones from 'timezones.json';

interface Props {}

interface State {
  // submitted: boolean;
  selectedTimezone: string;
  selectedDayTime: string;
  [k: string]: string | boolean;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

interface IStep {
  accomplished: boolean;
  index: number;
}

@inject('rootStore')
@observer
class Preview extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  timezonesOptions = _.map(timezones, (timezone, index) => {
    return {
      label: timezone.text,
      value: `${index}:${timezone.offset}`,
    };
  });

  dayTimeOptions = _.fill(new Array(24), {
    label: '',
    value: '',
  });

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 're-order';
    console.log('settings');
    this.state = {
      selectedTimezone: '',
      selectedDayTime: '',
    };

    this.dayTimeOptions = _.map(this.dayTimeOptions, (option, index) => {
      return {
        label: `${index}:00`,
        value: `${index}:00`,
      };
    });
  }

  toastText: string = 'Message template has been updated';

  handleChange = (obj: { [key: string]: string }) => {
    const [key] = Object.keys(obj);

    this.setState(obj);
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const { text_message, text_message2, text_message3 } = this.state;
    try {
      closeToast();
      // await updateSettings({
      //   campaign: 're-order',
      //   type: 'template',
      //   data: [
      //     {
      //       step: 0,
      //       text_message: text_message,
      //     },
      //     {
      //       step: 1,
      //       text_message: text_message2,
      //     },
      //     {
      //       step: 2,
      //       text_message: text_message3,
      //     },
      //   ],
      // });
      // openToast(this.toastText);
      this.setState({ submitted: true });
      await getSettings();
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  back = () => {
    history.push('/campaigns/automated-reorder/planning');
  };

  next = () => {
    const { openToast } = this.injected.rootStore.settingsStore;
    openToast('Campaign Updated Successfully');
    history.push('/campaigns/automated-reorder');
  };

  handleTimezoneChange = (value: string) => {
    this.setState({
      selectedTimezone: value,
    });
  };

  handleDayTimeChange = (value: string) => {
    this.setState({
      selectedDayTime: value,
    });
  };

  componentDidMount() {}

  render() {
    const { selectedTimezone, selectedDayTime } = this.state;
    const { settings } = this.injected.rootStore.settingsStore;
    const products = _.get(settings, 'reorderSettings.productIds', []);
    const customers = _.get(settings, 'analysisData.customerCount', 0);
    const reorderInterval = _.get(settings, 'reorderSettings.reorderInterval', 0);
    const deliveryTime = _.get(settings, 'reorderSettings.daytime', 0);
    return (
      <Fragment>
        <ContentWrapper>
          <ContentHeader>
            <ContentTitle>ReOrder Campaign Preview</ContentTitle>
            <ContentSubtitle>
              Let's preview the settings and current target audience
            </ContentSubtitle>
          </ContentHeader>
          <ContentBody>
            <ProgressBar percent={100} width={'780px'} filledBackground={'#47c1bf'}>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Products</div> */}
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Customers</div> */}
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    <div className={`indexedLabel`}>Planning</div>
                  </Fragment>
                )}
              </Step>
            </ProgressBar>

            <PreviewContainer>
              <PreviewLabel>Here's your campaign overiview</PreviewLabel>
              <PreviewLine>Products: {products.length}</PreviewLine>
              <PreviewLine space>Customers: {customers}</PreviewLine>

              <PreviewLine>Reorder interval: {reorderInterval}</PreviewLine>
              <PreviewLine>Campaign Delivery Time: {deliveryTime}</PreviewLine>
            </PreviewContainer>
          </ContentBody>
          <ContentFooter>
            <Button plain onClick={this.back}>
              ← PREVIOUS
            </Button>
            <Button primary onClick={this.next}>
              UPDATE CAMPAIGN
            </Button>
          </ContentFooter>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default Preview;
