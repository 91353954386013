import React, { ChangeEvent, Component, Ref, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import InputText from '../../../shared/InputText/InputText';
import { Card } from '@shopify/polaris';
import { ContentCardButton } from '../Content.styled';
import { IToken } from '../../../../typings/elements.typing';
import jwtDecode from 'jwt-decode';
//@ts-ignore;
import MixedTags from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import '@yaireo/tagify/dist/tagify.css'; // Tagify CSS
import './form.css';
//@ts-ignore
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import '@webscopeio/react-textarea-autocomplete/style.css';

interface Props {
  text_message: string;
  tags: string[];
  field: string;
  changeField({ field, value }: { [key: string]: string }): void;
}

interface State {
  text_message_highlights: string;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class TagArea extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }
  rta: object;
  backdrop: Ref<any>;
  onScroll = () => {};

  constructor(props: Props) {
    super(props);
    this.state = {
      text_message_highlights: '',
    };
    this.rta = {};
    this.backdrop = React.createRef();
  }

  handleChange = (field: string) => (value: { target: { value: string } }) => {
    this.applyHighlights(value.target.value);
    this.props.changeField({ [field]: value.target.value });
  };

  insertTag = (tag: string) => {
    let { text_message, field } = this.props;
    //@ts-ignore;
    const caretPosition = this.rta.getCaretPosition();
    const nextCaretPosition = caretPosition + tag.length;
    text_message = [
      text_message.slice(0, caretPosition),
      tag,
      text_message.slice(caretPosition),
    ].join('');
    //@ts-ignore
    const scrollTop = this.textarea.scrollTop;
    this.props.changeField({ [field]: text_message });
    setTimeout(() => {
      //@ts-ignore
      this.rta.setCaretPosition(nextCaretPosition);
      //@ts-ignore
      this.textarea.scrollTop = scrollTop;
    });
  };

  applyHighlights = (text: string) => {
    const highlightedText = text
      .replace(/\n$/g, '\n\n')
      //@ts-ignore
      .replaceAll(/\${(.*?)}/g, '<mark>${$1}</mark>');
    this.setState({
      text_message_highlights: highlightedText,
    });
  };

  componentDidMount() {
    //@ts-ignore
    this.onScroll = this.textarea.addEventListener('scroll', (e) => {
      //@ts-ignore
      var scrollTop = e.currentTarget.scrollTop;
      //@ts-ignore
      this.backdrop.current.scrollTop = scrollTop;
    });
    this.applyHighlights(this.props.text_message);
  }

  componentWillUnmount() {
    //@ts-ignore
    this.textarea.removeEventListener('scroll', this.onScroll);
  }

  render() {
    const { text_message, tags } = this.props;
    const { text_message_highlights } = this.state;
    //@ts-ignore
    const Item = ({ entity: { name, char } }) => <div>{`${char}`}</div>;

    return (
      <div className={'tagarea-wrapper'}>
        <div className="container tagarea">
          <div className="backdrop" ref={this.backdrop}>
            <div
              className="highlights"
              dangerouslySetInnerHTML={{ __html: text_message_highlights }}
            />
          </div>

          <ReactTextareaAutocomplete
            className="text_message"
            loadingComponent={() => <span>Loading</span>}
            //@ts-ignore
            innerRef={(textarea) => {
              //@ts-ignore
              this.textarea = textarea;
            }}
            trigger={{
              $: {
                dataProvider: (token: string) => {
                  return this.props.tags.map((tag) => {
                    return {
                      name: tag,
                      char: tag,
                    };
                  });
                },
                component: Item,
                output: (item: { char: string }) => `\${${item.char}}`,
              },
            }}
            ref={(rta: object) => {
              this.rta = rta;
            }}
            onChange={this.handleChange(this.props.field)}
            value={text_message}
          />
        </div>
        <div className={`available-tags`}>
          <span>Available tags:</span>
          <div>
            {tags &&
              tags.map((tag) => (
                <button
                  key={tag}
                  onClick={() => {
                    this.insertTag('${' + tag + '}');
                  }}
                >
                  {tag}
                </button>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TagArea;
