import styled from 'styled-components';

export const AnalyticsSectionWrapper = styled.div`
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
    justify-content: space-around;
  }
`;

export const AnalyticsBlock = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 50%;
  margin-bottom: 10px;
  min-width: 75px;

  @media only screen and (min-width: 768px) {
    align-items: center;
    text-align: center;
    flex: 0 0 auto;
    margin-bottom: 0;
  }
`;

export const AnalyticsNumber = styled.div`
  font-size: 24px;
  line-height: 35px;
  color: #123d55;
`;

export const AnalyticsNumberSmall = styled.div`
  font-size: 19px;
  line-height: 35px;
  color: #123d55;
`;

export const AnalyticsTitle = styled.div`
  color: #637381;
  font-size: 12px;
  text-transform: uppercase;
`;

export const AnalyticsSeparator = styled.div`
  border: 0.1px solid #dfe3e8;
  display: block;
  margin: 5px 0;
  width: 1px;
  display: none;

  @media only screen and (min-width: 768px) {
    display: block;
  }
`;
