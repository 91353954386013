import styled from 'styled-components';
import { ContentCardButton } from '../Dashboard/Content/Content.styled';

export const ModalSubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SubscriptionTextContainer = styled.span`
  font-size: 15px;
`;

export const SubscriptionButton = styled(ContentCardButton)`
  margin-top: 15px;
`;
