import React, { Component } from 'react';
import { PlanLabelWrapper } from './PlanLabel.styled';
import { IRootStore } from '../../../typings/stores.typing';
import { inject, observer } from 'mobx-react';

interface Props {}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class PlanLabel extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  render(): React.ReactNode {
    // const {
    //   settings: { isSubscribed },
    // } = this.injected.rootStore.billingStore;
    const { settings } = this.injected.rootStore.settingsStore;
    return <PlanLabelWrapper>{settings.payment.name}</PlanLabelWrapper>;
  }
}

export default PlanLabel;
