import { action, computed, observable } from 'mobx';
import RootStore from '../RootStore';
import { API_APP } from '../../config';
import { http } from '../../utils/http';
import { AuthorizeData, TokenData, IToken } from '../../typings/elements.typing';
import jwtDecode from 'jwt-decode';
import { Mixpanel } from '../../utils/analytics';

export default class AuthStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable token: string = '';

  @action.bound
  setToken(token: string): void {
    this.token = token;
  }

  @computed
  get storeUrl(): string {
    const token: string = localStorage.getItem('accessToken') || '';
    let shopDomain = '';

    try {
      const parseToken: IToken = token ? jwtDecode(token) : {};
      shopDomain = parseToken.shopDomain || '';
    } catch (e) {
      console.log(e);
    }
    return shopDomain ? 'https://' + shopDomain : 'https://www.shopify.com/';
  }

  @action.bound
  getTokenFromServer = async (data: TokenData): Promise<void> => {
    const token = localStorage.getItem('accessToken');

    if ((data.code && data.hmac && data.shop) || !token) {
      try {
        const response: Response = await http(`${API_APP}/get-token`, 'POST', data);
        const responseJson = await response.json();
        await localStorage.setItem('accessToken', responseJson.token);
        if (data.shop) {
          Mixpanel.identify(data.shop);
        }
        Mixpanel.track('session_start', {
          distinct_id: data.shop,
        });
      } catch (e) {
        const error = await e.json();

        error.status = e.status;

        error.isButtonBack = true;

        this.rootStore.errorStore.setError(error);

        throw new Error();
      }
    }
  };

  @action.bound
  authorize = async (data: AuthorizeData): Promise<void> => {
    try {
      const response: Response = await http(`${API_APP}/authorize`, 'POST', data);
      this.token = await response.json();
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      error.isButtonBack = false;

      error.error = `We were not able to connect your Facebook page to our Shopify app. Please connect Maxwell to at least one Facebook page and make sure to allow us to manage your page(s) as well as page conversations in Messenger. We only use this permission to allow you to send messages to your store visitors. Learn more <a target="_blank" href="https://help.maxwell.ai/en/articles/3005428-how-to-connect-your-facebook-page-to-the-maxwell-shopify-app">here</a>`;

      this.rootStore.errorStore.setError(error);

      throw new Error();
    }
  };

  @action.bound
  logout = (): void => {
    const token: string = localStorage.getItem('accessToken') || '';
    let shopDomain = '';

    try {
      const parseToken: IToken = token ? jwtDecode(token) : {};
      shopDomain = parseToken.shopDomain || '';
    } finally {
      localStorage.removeItem('accessToken');

      window.location.href = shopDomain ? `//${shopDomain}/admin/apps` : 'https://www.shopify.com/';
    }
  };
}
