import { action, computed, observable } from 'mobx';
import RootStore from '../RootStore';
import { NEW_API_APP } from '../../config';
import { http } from '../../utils/http';
import {
  IBlueprint,
  IBlueprintData,
  BlueprintsType,
  ICampaign,
  ICartReminder,
  IBlueprintsTypes,
  IShipment,
} from '../../typings/elements.typing';

export default class BlueprintStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable blueprints: IBlueprint[] = [];
  @observable selectedType: BlueprintsType = BlueprintsType.cartReminder;

  @observable campaigns: ICampaign[] = [];

  @observable cartReminders: IBlueprintsTypes = {
    cartReminder: {
      data: {
        blueprintMessage1: '',
        blueprintMessage2: '',
        blueprintCTA: '',
      },
      isActive: false,
      isTouched: false,
      delayHours: 1,
    },
    secondCartReminder: {
      data: {
        blueprintMessage1: '',
        blueprintCTA: '',
      },
      isActive: false,
      isTouched: false,
      delayHours: 23,
    },
    thirdCartReminder: {
      data: {
        blueprintMessage1: '',
      },
      isActive: false,
      isTouched: false,
      delayHours: 22,
    },
  };

  @observable couponCode: ICartReminder = {
    data: {
      blueprintCTA: '',
      blueprintMessage1: '',
      blueprintMessage2: '',
      blueprintMessage3: '',
    },
    isActive: false,
    isTouched: false,
    delayHours: 1,
  };

  @observable shipment: IShipment = {
    data: {
      blueprintCTA: '',
      blueprintMessage1: '',
      blueprintMessage2: '',
      blueprintCTA2: '',
      initial: '',
      confirmed: '',
      in_transit: '',
      out_for_delivery: '',
      attempted_delivery: '',
      ready_for_pickup: '',
      delivered: '',
      failure: '',
    },
    isActive: false,
    isTouched: false,
    delayHours: 1,
  };

  @computed
  get activeCartReminder(): ICartReminder {
    return this.cartReminders[this.selectedType];
  }

  @action.bound
  markAsTouched = (selectedType: BlueprintsType): void => {
    this.cartReminders[selectedType].isTouched = true;
  };

  @action.bound
  markAllAsUntouched = (): void => {
    for (let key in this.cartReminders) {
      if (this.cartReminders.hasOwnProperty(key)) {
        this.cartReminders[key].isTouched = false;
      }
    }
  };

  @action.bound
  updateField = async (field: keyof IBlueprintData, value: string): Promise<void> => {
    this.activeCartReminder.data[field] = value;
  };

  @action.bound
  setSelectedType(value: BlueprintsType) {
    this.selectedType = value;
  }

  @action.bound
  getBlueprints = async (): Promise<void> => {
    try {
      let responseApi = await http(`${NEW_API_APP}/blueprints`, 'GET');
      const blueprints = await responseApi.json();

      blueprints.forEach((blueprint: IBlueprint) => {
        if (this.cartReminders[blueprint.type]) {
          Object.assign(this.cartReminders[blueprint.type], blueprint);
        }
      });
    } catch (e) {
      const error = await e.json();
      error.status = e.status;
      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  getBlueprintByType = async (type: BlueprintsType): Promise<void> => {
    try {
      let responseApi = await http(`${NEW_API_APP}/blueprints/${type}`, 'GET');
      switch (type) {
        case BlueprintsType.cartReminder:
        case BlueprintsType.secondCartReminder:
        case BlueprintsType.thirdCartReminder:
          this.cartReminders[type] = await responseApi.json();
          break;
        case BlueprintsType.couponCode:
          const res = await responseApi.json();
          if (res.length) {
            Object.assign(this.couponCode, res[0]);
          }
          break;
        case BlueprintsType.shipment:
          const resShipment = await responseApi.json();
          if (resShipment.length) {
            Object.assign(this.shipment, resShipment[0]);
          }
          break;
      }
    } catch (e) {
      const error = await e.json();
      error.status = e.status;
      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  updateBlueprintByType = async (
    type: BlueprintsType,
    data: ICartReminder & IShipment,
  ): Promise<void> => {
    try {
      await http(`${NEW_API_APP}/blueprints/${type}`, 'POST', data);
      switch (type) {
        case BlueprintsType.cartReminder:
        case BlueprintsType.secondCartReminder:
        case BlueprintsType.thirdCartReminder:
          this.cartReminders[type] = { ...this.cartReminders[type], ...data };
          break;
        case BlueprintsType.couponCode:
          this.couponCode = data;
          break;
        case BlueprintsType.shipment:
          this.shipment = data;
          break;
      }
    } catch (e) {
      const error = await e.json();
      error.status = e.status;
      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  getCampaigns = async (): Promise<ICampaign[] | undefined> => {
    try {
      const response: Response = await http(`${NEW_API_APP}/campaigns`, 'GET');
      this.campaigns = await response.json();
      return this.campaigns;
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  createCampaign = async (data: ICampaign): Promise<void> => {
    try {
      await http(`${NEW_API_APP}/campaigns`, 'POST', data);
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };
}
