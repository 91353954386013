import styled, { css } from 'styled-components';
import { ProgressBar } from '@shopify/polaris';
import { NavLink } from 'react-router-dom';

interface IAttributionProgressBar {
  progress?: number;
}

export const SidebarWrapper = styled.div`
  flex: 0 0 260px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  // @media only screen and (max-width: 1100px) {
  //   display: none;
  // }
  @media only screen and (min-width: 1600px) {
    flex: 0 0 300px;
  }
`;

export const TitleSidebarLogo = styled.h1`
  font-size: 20px;
  margin-left: 15px;
`;

export const SidebarLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  padding: 25px;
`;

export const SideMenuAttribution = styled.div`
  padding: 25px;
`;

export const AttributionTitle = styled.div``;

export const AttributionSubTitle = styled.div`
  padding: 5px 0 10px 0;
`;

export const AttributionProgressBarWrapper = styled.div`
  & > .Polaris-ProgressBar {
    & .Polaris-ProgressBar__Indicator {
      ${(props: IAttributionProgressBar) => {
        const progress = props.progress || 0;
        if (progress >= 50) {
          return css`
            background-color: #5bccda;
          `;
        }
        if (progress > 20 && progress < 50) {
          return css`
            background-color: #ffc353;
          `;
        }

        return css`
          background-color: #d82b0c;
        `;
      }}
    }
  }
`;

export const AttributionProgressBar = styled(ProgressBar)``;

export const SidebarMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const SidebarItem = styled(NavLink)`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 15px 12px 25px;
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;
    & > .Polaris-Icon > svg {
      fill: #ffffff;
    }
    &.active {
        & > .Polaris-Icon > svg{
            fill: #fc5085;
        }
        &:before{
        content: '';
        position: absolute;
        top: 15px;
        right: 0;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 5px 5px 0;
        border-color: transparent #ffffff transparent transparent;
    }
`;

export const SidebarItemDisabled = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 15px 12px 25px;
  cursor: pointer;
  color: #5c5f62;
  text-decoration: none;
  & > .Polaris-Icon > svg {
    fill: #5c5f62;
  }
`;

export const SidebarItemLink = styled.a`
   position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 15px 12px 25px;
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;
    
    &.active {
        .Polaris-Icon svg{
            fill: #fc5085;
        }
        &:before{
        content: '';
        position: absolute;
        top: 15px;
        right: 0;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 5px 5px 0;
        border-color: transparent #ffffff transparent transparent;
    }
`;

export const TooltipMessage = styled.div`
  padding: 5px;
`;

export const SidebarItemWithSpacing = styled.div`
  margin: 20px 0 0;
  @media only screen and (min-width: 1600px) {
    margin: 20px 0 40px;
  }
`;

export const SidebarItemTitle = styled.div`
  position: relative;
  flex: 1;
  margin-left: 20px;
`;

export const SidebarSubItem = styled(NavLink)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  padding-top: 15px;
  cursor: pointer;
  & > .Polaris-Icon > svg {
    fill: #ffffff;
  }
  &.active > .Polaris-Icon > svg {
    fill: #fc5085;
  }
`;

export const SidebarSubItemWrapper = styled.div`
  margin-left: 20px;
  cursor: pointer;
  flex: 1 0 100%;
`;

export const SidebarSettings = styled.div`
  border-top: 1px solid rgba(151, 151, 151, 0.2);
  padding: 25px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SidebarSettingsAccount = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SidebarSettingsText = styled.div`
  position: relative;
  color: #ffffff;
  margin-left: 20px;
`;

export const SidebarSettingsIcon = styled.div`
  cursor: pointer;
`;

export const SidebarGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarGroupWithBorder = styled(SidebarGroup)`
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
`;

export const SidebarBottomGroup = styled(SidebarGroup)`
  padding: 20px 0;
`;

export const SidebarBillingGroup = styled(SidebarGroup)`
  padding: 12px 15px 12px 25px;
  align-items: flex-start;
`;

export const SidebarBillingProgressBar = styled.div`
  width: 100%;

  .Polaris-ProgressBar__Indicator {
    background: #fc5085;
  }
`;

export const SidebarBillingProgressBarTitle = styled.div`
  margin: 5px 0;
  font-size: 12px;
`;

export const SidebarItemNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 35px;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  background-color: #fc5085;
  z-index: 9;
  font-size: 11px;
`;
