import styled, { css } from 'styled-components';
import Toggle from 'react-toggle';
import { ContentBodyProps } from '../../../typings/elements.typing';

export const ContentWrapper: any = styled.div`
  flex: 1 1 auto;
  background: rgba(246, 246, 247, 1);
  overflow: auto;
  margin: 0;
  position: relative;

  @media only screen and (min-width: 1100px) {
    margin: 25px 25px 25px 0;
  }
`;

export const BannerWrapper = styled.div`
  padding: 20px;
  // position: absolute;
  width: 100%;

  @media only screen and (min-width: 1100px) {
    padding: 40px;
  }

  @media only screen and (min-width: 1600px) {
    padding: 60px 60px 0 60px;
  }
`;

export const ContentRoutes = styled.div`
  padding: 20px;
  position: absolute;
  width: 100%;

  @media only screen and (min-width: 1100px) {
    padding: 40px;
  }

  @media only screen and (min-width: 1600px) {
    padding: 60px;
  }
`;

export const ContentTitle = styled.div`
  font-size: 24px;
  color: #123d55;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
  vertical-text: center;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardToggle = styled(Toggle)`
  &.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb,
  &.react-toggle--focus .react-toggle-thumb,
  &.react-toggle--checked .react-toggle-thumb {
    box-shadow: none;
  }
  height: 24px;
`;

export const ContentToggleButton = styled(CardToggle)`
  margin: 0 20px;
`;

export const ContentBody = styled.div`
  display: flex;
  margin-top: 25px;
  // justify-content: space-between;
  ${(props: ContentBodyProps) =>
    props.column &&
    css`
      flex-direction: column;
    `}
  @media only screen and (max-width: 1100px) {
    margin-top: 20px;
  }
`;

export const ContentBodyOnboarding = styled.div`
  display: flex;
  margin-top: 25px;
  // justify-content: space-between;
  ${(props: ContentBodyProps) =>
    props.column &&
    css`
      flex-direction: column;
    `}
  @media only screen and (max-width: 1100px) {
    margin-top: 20px;
  }
`;

export const ContentBodyProductInsights = styled.div`
  display: flex;
  margin-top: 25px;
  flex-direction: column;
  ${(props: ContentBodyProps) =>
    props.column &&
    css`
      flex-direction: column;
    `}
  @media only screen and (max-width: 1100px) {
    margin-top: 20px;
  }
`;

export const ContentCard = styled.div`
  flex: 1 1 100%;
  max-width: 40%;
  & :first-child {
    margin-right: 35px;
  }
  .Polaris-Frame {
    min-height: auto;
    display: block;
    background-color: transparent;
  }
  .Polaris-Card {
    overflow: unset;
  }
  .Polaris-Card__Header {
    border-bottom: 1px solid rgba(63, 63, 68, 0.05);
    padding: 1rem 1rem 0 2rem;
  }
  .Polaris-Heading {
    font-weight: normal;
  }
  @media only screen and (max-width: 1100px) {
    max-width: none;
  }
  .Polaris-OptionList {
    padding: 0;
    p {
      padding: 0;
      font-weight: 400;
      text-transform: initial;
      font-size: 1.4rem;
      color: currentColor;
    }
  }
`;

export const ContentCardSmall = styled.div`
  flex: 1 1 100%;
  width: 30%;
  max-width: 425px;

  & :first-child {
    margin-right: 35px;
  }
  .Polaris-Frame {
    min-height: auto;
    display: block;
    background-color: transparent;
  }
  .Polaris-Card {
    overflow: unset;
  }
  .Polaris-Card__Header {
    border-bottom: 1px solid rgba(63, 63, 68, 0.05);
    padding: 1rem 1rem 0 2rem;
  }
  .Polaris-Heading {
    font-weight: normal;
  }
  @media only screen and (max-width: 1100px) {
    max-width: none;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 330px;
  }
`;

export const SettingsCard = styled.div`
  flex: 1 1 100%;
  max-width: 900px;
  .Polaris-Frame {
    min-height: auto;
    display: block;
    background-color: transparent;
  }
  .Polaris-Card {
    overflow: unset;
  }
  .Polaris-Card__Header {
    border-bottom: 1px solid rgba(63, 63, 68, 0.05);
    padding: 1rem 1rem 0 2rem;
  }
  .Polaris-Heading {
    font-weight: normal;
  }
  @media only screen and (max-width: 1100px) {
    max-width: none;
  }
`;

export const ContentCardCouponPopupWrapper = styled(ContentCard)`
  max-width: 330px;
  @media only screen and (min-width: 1600px) {
    max-width: 460px;
  }
`;

export const ContentCardTriggersSettings = styled(ContentCard)`
  max-width: 700px;
`;

export const ContentCardButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 3px;
  padding: 12px 27px;
  background-color: #fc5085;
  ${(props: ContentBodyProps) =>
    !props.basic &&
    css`
      margin-top: 40px;
    `}
  ${(props: ContentBodyProps) =>
    props.basic &&
    css`
      display: none;
    `}
    color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  background-color: #fc5085;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border: none;

  &:hover {
    background-color: #e13264;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }

  @media only screen and (min-width: 768px) {
    ${(props: ContentBodyProps) =>
      props.basic &&
      css`
        display: block;
      `}
    margin-left: 0;
    margin-right: 10px;
  }

  @media only screen and (max-width: 1100px) {
    ${(props: ContentBodyProps) =>
      !props.basic &&
      css`
        margin-top: 20px;
      `}
  }
`;

export const ContentCardSubSection = styled.div`
  min-height: 247px;
`;

export const ContentCardSubtitle = styled.div`
  font-size: 12px;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  color: #000;
  max-width: 330px;
  @media only screen and (min-width: 1600px) {
    max-width: 460px;
  }
`;

export const PaginationBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationText = styled.div`
  font-size: 14px;
  color: #637381;
  margin-right: 20px;
`;
