import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Banner, Modal, TextContainer } from '@shopify/polaris';
import { IRootStore } from '../../typings/stores.typing';
import { withRouter } from 'react-router';
import { IToken } from '../../typings/elements.typing';
import { getParsedToken } from '../../utils/token';
import * as Sentry from '@sentry/react';

interface Props {
  location?: Location;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
// @ts-ignore
@withRouter
@observer
export class ModalError extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleChange = () => {
    const { errorStore, authStore } = this.injected.rootStore;
    const { location } = this.props;
    const { error } = errorStore;

    if (
      location &&
      (location.pathname === '/' || (/onboarding/.test(location.pathname) && error.redirect))
    ) {
      authStore.logout();
    }

    errorStore.clearError();
  };

  getUrl = (): string => {
    let parseToken: IToken = {};
    try {
      parseToken = getParsedToken();
    } catch (e) {
      Sentry.captureException(e);
    }
    return parseToken.shopDomain || 'https://shopify.com';
  };

  render() {
    const { errorStore } = this.injected.rootStore;
    console.log(errorStore);
    return (
      <Modal
        open={errorStore.error.isShow}
        onClose={this.handleChange}
        title="Something went wrong"
      >
        <Modal.Section>
          <TextContainer>
            {errorStore.error.isButtonBack ? (
              <Banner
                status="critical"
                action={{ content: 'Return to Shopify', url: this.getUrl() }}
              >
                <p dangerouslySetInnerHTML={{ __html: errorStore.error.message }} />
              </Banner>
            ) : (
              <Banner status="critical">
                <p dangerouslySetInnerHTML={{ __html: errorStore.error.message }} />
              </Banner>
            )}
          </TextContainer>
        </Modal.Section>
      </Modal>
    );
  }
}

export default ModalError;
