import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import {
  Card,
  Page,
  Layout,
  Button,
  Stack,
  Badge,
  Banner,
  ResourceList,
  EmptyState,
  ResourceItem,
  TextStyle,
} from '@shopify/polaris';
import { http } from '../../../../utils/http';
import * as _ from 'lodash';
import history from '../../../../utils/history';
import { PlusMinor } from '@shopify/polaris-icons';
import {
  AnalyticsBlock,
  AnalyticsNumber,
  AnalyticsNumberSmall,
  AnalyticsSectionWrapper,
  AnalyticsTitle,
} from '../DashboardHome/AnalyticsSection/AnalyticsSection.styled';
import { renderPrice } from '../../../../utils/number';

interface Props {}

interface State {
  offers: any;

  [k: string]: string | boolean | any;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class PrePurchaseUpsellList extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  analyticsPerOffer: any = {};

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const offers = _.get(settings, 'upsell.offers', []);
    let offersWithId = _.map(offers, (value, key) => {
      return {
        ...value,
        id: key,
      };
    });
    this.state = {
      offers: offersWithId || [],
    };
  }

  handleSubmit = async () => {
    const { closeToast, updateSettings } = this.injected.rootStore.settingsStore;
    try {
      closeToast();
      await updateSettings({
        type: 'upsell',
        data: {},
      });
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  renderItem = (offer: any) => {
    const { settings } = this.injected.rootStore.settingsStore;
    const { cartUpsellAnalytics } = settings;
    const { campaignAttribution } = cartUpsellAnalytics;
    const offerAttribution = campaignAttribution[offer.id] || {
      ordersCount: 0,
      totalSales: 0,
    };
    const cartUpsellAOV = offerAttribution.totalSales / offerAttribution.ordersCount || 0;
    const offerAnalytics = this.analyticsPerOffer[offer.id] || {
      view: 0,
      activation: 0,
      deactivation: 0,
    };

    return (
      <ResourceItem
        id={offer.id}
        accessibilityLabel={`View details for: ${offer.id}`}
        onClick={() => this.openOffer(offer.id)}
      >
        <div className={'item-container'}>
          <div>
            <h3>
              <TextStyle variation="strong">{offer.offer_section_title}</TextStyle>
            </h3>
          </div>
          <div className={'analytics-container'}>
            <AnalyticsSectionWrapper>
              <AnalyticsBlock>
                <AnalyticsNumberSmall>{offerAnalytics.view || 0}</AnalyticsNumberSmall>
                <AnalyticsTitle>Views</AnalyticsTitle>
              </AnalyticsBlock>
              <AnalyticsBlock>
                <AnalyticsNumberSmall>{offerAnalytics.activation || 0}</AnalyticsNumberSmall>
                <AnalyticsTitle>Activations</AnalyticsTitle>
              </AnalyticsBlock>
              <AnalyticsBlock>
                <AnalyticsNumberSmall>{offerAnalytics.deactivation || 0}</AnalyticsNumberSmall>
                <AnalyticsTitle>Deactivations</AnalyticsTitle>
              </AnalyticsBlock>
              <AnalyticsBlock>
                <AnalyticsNumberSmall>{offerAttribution.ordersCount}</AnalyticsNumberSmall>
                <AnalyticsTitle>Orders</AnalyticsTitle>
              </AnalyticsBlock>
              <AnalyticsBlock>
                <AnalyticsNumberSmall>
                  {renderPrice(cartUpsellAOV, settings.shopData.money_format)}
                </AnalyticsNumberSmall>
                <AnalyticsTitle>AOV</AnalyticsTitle>
              </AnalyticsBlock>
              <AnalyticsBlock>
                <AnalyticsNumberSmall>
                  {renderPrice(offerAttribution.totalSales, settings.shopData.money_format)}
                </AnalyticsNumberSmall>
                <AnalyticsTitle>Sales</AnalyticsTitle>
              </AnalyticsBlock>
            </AnalyticsSectionWrapper>
          </div>
        </div>
        {/*<TextStyle variation="subdued">Products: {productsList}</TextStyle>*/}
      </ResourceItem>
    );
  };

  openOffer(id: string) {
    history.push(`/pre-purchase-upsell/${id}`);
  }

  createOffer() {
    history.push(`/pre-purchase-upsell/new`);
  }

  async componentDidMount() {
    const { settings } = this.injected.rootStore.settingsStore;
    const { cartUpsellAnalytics } = settings;
    const { analyticsData } = cartUpsellAnalytics;
    //@ts-ignore;
    this.analyticsPerOffer = _.chain(analyticsData)
      .values()
      .flatten()
      .groupBy((item) => {
        return _.get(item, 'data.offer_id', null);
      })
      .mapValues((items) => {
        const result = _.groupBy(items, 'eventName');
        return _.mapValues(result, (items) => items.length);
      })
      .value();
  }

  render() {
    const { offers } = this.state;

    const emptyStateMarkup = !offers.length ? (
      <EmptyState
        heading="Create offer to get started"
        action={{
          content: 'Create Offer',
          onAction: () => history.push(`/pre-purchase-upsell/new`),
        }}
        image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
      >
        <p>After creating new offer your customers will be able to see them in their Carts</p>
      </EmptyState>
    ) : (
      undefined
    );

    return (
      <>
        <Page
          title={'Cart Upsell Offers'}
          primaryAction={{ content: 'Create Offer', icon: PlusMinor, onAction: this.createOffer }}
        >
          <Layout>
            <Layout.Section>
              <Card>
                <ResourceList
                  emptyState={emptyStateMarkup}
                  items={offers}
                  //@ts-ignore;
                  renderItem={this.renderItem}
                  // filterControl={filterControl}
                  resourceName={{ singular: 'offer', plural: 'offers' }}
                />
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </>
    );
  }
}

export default PrePurchaseUpsellList;
