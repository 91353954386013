import { action, observable, runInAction } from 'mobx';
import RootStore from '../RootStore';
import { IContact, IContactParams, IInfo } from '../../typings/elements.typing';
import { http } from '../../utils/http';
import { API_APP } from '../../config';

export default class ContactsStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable contacts: IContact[] = [];
  @observable info: IInfo = {
    total: 0,
    page: 0,
    pages: 0,
  };

  @action.bound
  getContacts = async (params?: IContactParams): Promise<void> => {
    try {
      const url = new URL(`${API_APP}/contacts`);

      if (params)
        Object.keys(params).forEach((key: string) =>
          url.searchParams.append(key, params[key] + ''),
        );

      const response: Response = await http(`${url}`, 'GET');
      const { contacts, total, page, pages, contactLastVisit } = await response.json();

      const contactLastVisitDate = new Date(contactLastVisit);

      contacts.forEach((contact: IContact) => {
        const contactDate = new Date(contact.createdAt);

        if (contactDate.getTime() > contactLastVisitDate.getTime()) {
          contact.isNew = true;
        }
      });

      runInAction(() => {
        this.contacts = contacts || [];
        this.info = { total, page, pages } || {};
      });
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };
}
