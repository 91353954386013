import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import {
  Card,
  Page,
  Layout,
  Button,
  Stack,
  Autocomplete,
  Icon,
  ResourceList,
  ResourceItem,
  TextStyle,
  Avatar,
  Tabs,
  RadioButton,
  TextField,
  SkeletonBodyText,
  IndexTable,
  Badge,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import { http } from '../../../../utils/http';
import * as _ from 'lodash';
import './styles.css';
import SelectProductModal from './SelectProductModal';
import { API_APP } from '../../../../config';
import history from '../../../../utils/history';
import getSymbolFromCurrency from 'currency-symbol-map';

interface Props {}

interface State {
  products: any;
  selectedProducts: any;
  isOpen: boolean;
  productsById: any;
  selectedVariants: any;
  offer_section_title: any;

  isOfferModalOpen: boolean;
  selectedOfferVariants: any;
  selectedTab: number;
  rules: any;
  products_loading: boolean;
  offerId: any;
  is_active: boolean;

  [k: string]: string | boolean | any;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class PrePurchaseUpsellItem extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  tabs = [
    {
      id: 'offer-config',
      content: 'Offer Config',
      accessibilityLabel: 'Offer Config',
      panelID: 'offer-config-content',
    },
    {
      id: 'advanced-settings',
      content: 'Advanced Settings',
      panelID: 'advanced-settings-content',
    },
  ];

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    console.log('constructor: ', settings.upsell);
    const offerId: any = _.chain(history)
      //@ts-ignore
      .get('location.pathname', '/new')
      .split('/')
      .last()
      .value();

    const emptyOffer = {
      selected_products: [],
      selected_variants: [],
      offer_section_title: 'Special Offer',
      offered_products_variants: {},
      rules: {
        products: {
          state: 'all',
          value: {},
        },
        amount: {
          state: 'all',
          value: {
            min: '0',
            max: '5',
          },
        },
      },
    };

    let currentOffer: any = {};

    if (offerId === 'new') {
      currentOffer = emptyOffer;
    }

    currentOffer = _.get(settings, ['upsell', 'offers', offerId], emptyOffer);

    const {
      selected_products,
      selected_variants,
      rules,
      offered_products_variants,
      offer_section_title,
      is_active,
    } = currentOffer;

    this.state = {
      products: [],
      selectedProducts: selected_products,
      selectedVariants: selected_variants,
      offer_section_title: offer_section_title,
      isOpen: false,
      productsById: {},
      isOfferModalOpen: false,
      selectedOfferVariants: [],
      selectedTab: 0,
      offerId: offerId,
      rules: rules,
      offered_products_variants: offered_products_variants,
      products_loading: true,
      is_active: is_active || false,
    };
  }

  public variantsWithUpdatedPrices: any = {};

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      settings,
    } = this.injected.rootStore.settingsStore;
    const {
      rules,
      offered_products_variants,
      productsById,
      offer_section_title,
      offerId,
    } = this.state;
    try {
      closeToast();
      const existingOffers = _.get(settings, 'upsell.offers', {});
      let preparedOfferId = offerId;

      if (offerId === 'new') {
        //generate new offer id
        preparedOfferId = _.chain(settings)
          .get('upsell.offers', { 0: {} })
          .keys()
          .last()
          .toNumber()
          .add(1)
          .value();
      }

      await updateSettings({
        type: 'upsell',
        data: {
          update_price: this.variantsWithUpdatedPrices,
          offers: {
            ...existingOffers,
            [preparedOfferId]: {
              ...existingOffers[preparedOfferId],
              offer_section_title: offer_section_title,
              rules: rules,
              offered_products_variants: offered_products_variants,
              productsById: _.pick(productsById, _.keys(offered_products_variants)),
            },
          },
        },
      });
      this.setState({
        offerId: preparedOfferId,
      });
      openToast('Settings was successfully saved');
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleOfferModal = () => {
    this.setState({
      isOfferModalOpen: !this.state.isOfferModalOpen,
    });
  };

  changeSelection = (items: any) => {
    this.setState({
      selectedProducts: items,
    });
  };

  handleTabChange = (tab: number) => {
    this.setState({
      selectedTab: tab,
    });
  };

  fetchProducts = async () => {
    try {
      let response: Response = await http(`${API_APP}/get-products`, 'GET');
      response = await response.json();
      let products = _.get(response, 'products', []);
      products = _.map(products, (product) => {
        let imagesById = _.chain(product)
          .get('images', [])
          //@ts-ignore;
          .keyBy('id')
          .value();

        return {
          label: product.title,
          id: product.id.toString(),
          image: _.get(product, 'image.src', ''),
          images: imagesById,
          variants: _.get(product, 'variants', []),
        };
      });

      const productsById = _.keyBy(products, 'id');

      this.setState({
        products_loading: false,
        products,
        productsById,
      });
    } catch (err) {
      console.log('[error]', err);
    }
  };

  getSelectedProducts = (products_variants: any) => {
    this.setState({
      isOpen: false,
      rules: {
        ...this.state.rules,
        products: {
          ...this.state.rules.products,
          value: products_variants,
        },
      },
    });
  };

  getSelectedProductsOffer = (products_variants: any) => {
    this.setState({
      offered_products_variants: products_variants,
      isOfferModalOpen: false,
    });
  };

  handleChangeState = (checked: boolean, newValue: any) => {
    const [field, value] = newValue.split('-');
    this.setState({
      rules: {
        ...this.state.rules,
        [field]: {
          ...this.state.rules[field],
          state: value,
        },
      },
    });
  };

  handleChangeValue = (field: string, value: any) => {
    this.setState({
      rules: {
        ...this.state.rules,
        [field]: {
          ...this.state.rules[field],
          value: {
            ...this.state.rules[field].value,
            ...value,
          },
        },
      },
    });
  };

  handleChangeTitle = (value: any) => {
    this.setState({
      offer_section_title: value,
    });
  };

  deleteProduct = (productId: any) => {
    let { offered_products_variants } = this.state;
    delete offered_products_variants[productId];
    this.setState({
      offered_products_variants: offered_products_variants,
    });
  };

  updatePrice = (productId: string, variantId: string, field: string, value: string) => {
    let { productsById } = this.state;

    let variants = productsById[productId].variants;
    let variant = _.find(variants, { id: variantId });
    variant[field] = value;

    _.set(this.variantsWithUpdatedPrices, [variantId, field], value);

    this.setState({
      productsById,
    });
  };

  toggleActive = async () => {
    const {
      closeToast,
      openToast,
      updateSettings,
      settings,
    } = this.injected.rootStore.settingsStore;
    const { offerId, is_active } = this.state;
    const existingOffers = _.get(settings, 'upsell.offers', {});

    try {
      closeToast();
      await updateSettings({
        type: 'upsell',
        data: {
          offers: {
            ...existingOffers,
            [offerId]: {
              ...existingOffers[offerId],
              is_active: !existingOffers[offerId].is_active,
            },
          },
        },
      });
      openToast('Settings was successfully saved');
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  async componentDidMount() {
    await this.fetchProducts();
  }

  render() {
    const {
      products,
      isOpen,
      selectedTab,
      isOfferModalOpen,
      productsById,
      rules,
      offered_products_variants,
      products_loading,
      offer_section_title,
      offerId,
    } = this.state;

    const { settings } = this.injected.rootStore.settingsStore;
    const is_active = _.get(settings, ['upsell', 'offers', offerId, 'is_active'], false);
    const textField = (
      <Autocomplete.TextField
        onChange={this.toggleModal}
        autoComplete={''}
        label="Products"
        value={''}
        prefix={<Icon source={SearchMinor} color="base" />}
        connectedRight={<Button onClick={this.toggleModal}>Browse</Button>}
        placeholder="Search"
      />
    );

    const textFieldOffer = (
      <Autocomplete.TextField
        onChange={this.toggleOfferModal}
        autoComplete={''}
        label="Products"
        value={''}
        prefix={<Icon source={SearchMinor} color="base" />}
        connectedRight={<Button onClick={this.toggleOfferModal}>Browse</Button>}
        placeholder="Search"
      />
    );

    return (
      <>
        <Page
          title={'Upsell Offer Settings'}
          breadcrumbs={[
            { content: 'Products', onAction: () => history.push(`/pre-purchase-upsell`) },
          ]}
          titleMetadata={
            offerId !== 'new' ? (
              <Badge status={is_active ? 'success' : 'warning'}>
                {is_active ? 'Published' : 'Unpublished'}
              </Badge>
            ) : (
              <Badge status="new">Draft</Badge>
            )
          }
          primaryAction={
            <Stack>
              <Button onClick={this.toggleActive} disabled={offerId === 'new'}>
                {is_active ? 'Unpublish' : 'Publish'}
              </Button>
              <Button primary onClick={this.handleSubmit}>
                Save
              </Button>
              {/*<Button monochrome>Preview</Button>*/}
              {/*<Button destructive>Delete</Button>*/}
            </Stack>
          }
        >
          <Layout>
            <Layout.Section>
              <Card>
                <Tabs tabs={this.tabs} selected={selectedTab} onSelect={this.handleTabChange}>
                  {this.tabs[selectedTab].id === 'offer-config' && (
                    <>
                      <Card.Section title={'Customize Offer Section'}>
                        <TextField
                          onChange={this.handleChangeTitle}
                          label="Section title"
                          autoComplete={'off'}
                          value={offer_section_title}
                          placeholder="Special Offer"
                        />
                      </Card.Section>
                      <Card.Section title={'Choose upsell products'}>
                        <Autocomplete
                          options={[]}
                          selected={[]}
                          onSelect={this.toggleOfferModal}
                          textField={textFieldOffer}
                        />
                        <br />
                        {products_loading && <SkeletonBodyText />}
                        {!products_loading && (
                          <ResourceList
                            resourceName={{ singular: 'Products', plural: 'products' }}
                            items={_.keys(offered_products_variants)}
                            renderItem={(productId: any) => {
                              const { id, label, image, variants } = productsById[productId] || {};
                              const offered_variants = offered_products_variants[productId];
                              const media = (
                                <Avatar customer source={image} size="medium" name={label} />
                              );

                              const filtered_variants = _.filter(variants, (variant) =>
                                offered_variants.includes(variant.id),
                              );
                              return (
                                <>
                                  <ResourceItem
                                    id={id}
                                    url={''}
                                    media={media}
                                    shortcutActions={[
                                      {
                                        content: 'Delete',
                                        onAction: () => this.deleteProduct(productId),
                                      },
                                    ]}
                                    accessibilityLabel={`View details for ${label}`}
                                  >
                                    <div style={{ maxWidth: '90%' }}>
                                      <h3>
                                        <TextStyle variation="strong">{label}</TextStyle>
                                      </h3>
                                    </div>
                                  </ResourceItem>
                                  {!!filtered_variants.length && (
                                    <div>
                                      <IndexTable
                                        itemCount={filtered_variants.length}
                                        headings={[
                                          { title: 'Variant' },
                                          { title: 'Original Price' },
                                          { title: 'Discounted Price' },
                                        ]}
                                        resourceName={{
                                          singular: 'variant',
                                          plural: 'variants',
                                        }}
                                        selectable={false}
                                      >
                                        {filtered_variants.map((variant: any, index: number) => {
                                          return (
                                            <IndexTable.Row
                                              id={variant.id}
                                              key={variant.id}
                                              position={index}
                                            >
                                              <IndexTable.Cell>
                                                <TextStyle variation="strong">
                                                  {variant.title}
                                                </TextStyle>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <div style={{ width: '120px' }}>
                                                  <TextField
                                                    label={''}
                                                    autoComplete={'off'}
                                                    type="number"
                                                    onChange={(value) =>
                                                      this.updatePrice(
                                                        productId,
                                                        variant.id,
                                                        'compare_at_price',
                                                        value,
                                                      )
                                                    }
                                                    value={variant.compare_at_price}
                                                    prefix={getSymbolFromCurrency(
                                                      settings.shopData.currency,
                                                    )}
                                                  />
                                                </div>
                                              </IndexTable.Cell>
                                              <IndexTable.Cell>
                                                <div style={{ width: '120px' }}>
                                                  <TextField
                                                    label={''}
                                                    autoComplete={'off'}
                                                    type="number"
                                                    onChange={(value) =>
                                                      this.updatePrice(
                                                        productId,
                                                        variant.id,
                                                        'price',
                                                        value,
                                                      )
                                                    }
                                                    value={variant.price}
                                                    prefix={getSymbolFromCurrency(
                                                      settings.shopData.currency,
                                                    )}
                                                  />
                                                </div>
                                              </IndexTable.Cell>
                                            </IndexTable.Row>
                                          );
                                        })}
                                      </IndexTable>
                                    </div>
                                  )}
                                </>
                              );
                            }}
                          />
                        )}
                      </Card.Section>
                    </>
                  )}

                  {this.tabs[selectedTab].id === 'advanced-settings' && (
                    <>
                      <Card.Section
                        title={
                          'Show upsell products when one (ore more) of the following products are in the cart'
                        }
                      >
                        <Stack vertical spacing={'extraTight'}>
                          <Stack.Item>
                            <RadioButton
                              label="Show for all products"
                              helpText=""
                              checked={rules['products']['state'] === 'all'}
                              id="products-all"
                              name="products"
                              onChange={this.handleChangeState}
                            />
                          </Stack.Item>
                          <Stack.Item>
                            <RadioButton
                              label="Show for specific products"
                              helpText=""
                              id="products-specific"
                              name="products"
                              checked={rules['products']['state'] === 'specific'}
                              onChange={this.handleChangeState}
                            />
                          </Stack.Item>
                          {rules['products']['state'] === 'specific' && (
                            <Stack.Item>
                              <br />
                              <Autocomplete
                                options={[]}
                                selected={[]}
                                onSelect={this.toggleModal}
                                textField={textField}
                              />
                              <br />
                              <ResourceList
                                resourceName={{ singular: 'Products', plural: 'products' }}
                                items={_.keys(rules.products.value)}
                                loading={products_loading}
                                renderItem={(item: any) => {
                                  const product = productsById[item];
                                  if (!product) {
                                    return;
                                  }
                                  const { id, url, label, image } = product;
                                  const media = (
                                    <Avatar customer source={image} size="medium" name={label} />
                                  );

                                  return (
                                    <ResourceItem
                                      id={id}
                                      url={url}
                                      media={media}
                                      accessibilityLabel={`View details for ${label}`}
                                    >
                                      <h3>
                                        <TextStyle variation="strong">{label}</TextStyle>
                                      </h3>
                                    </ResourceItem>
                                  );
                                }}
                              />
                            </Stack.Item>
                          )}
                        </Stack>
                      </Card.Section>
                      <Card.Section
                        title={`Show for any Quantity of Items in Cart or Specific Quantity of Items`}
                      >
                        <Stack vertical spacing={'extraTight'}>
                          <Stack.Item>
                            <RadioButton
                              label="Show for any order quantity"
                              helpText=""
                              id="amount-all"
                              checked={rules['amount']['state'] === 'all'}
                              name="filter_amount"
                              onChange={this.handleChangeState}
                            />
                          </Stack.Item>
                          <Stack.Item>
                            <RadioButton
                              label="Show for specific order quantity"
                              helpText=""
                              id="amount-specific"
                              name="filter_amount"
                              checked={rules['amount']['state'] === 'specific'}
                              onChange={this.handleChangeState}
                            />
                          </Stack.Item>
                          {rules['amount']['state'] === 'specific' && (
                            <Stack.Item>
                              <br />
                              <Stack wrap={false} alignment="center">
                                <Stack.Item>Between</Stack.Item>
                                <Stack.Item fill>
                                  <TextField
                                    label=""
                                    autoComplete={''}
                                    labelHidden
                                    maxLength={500}
                                    type="number"
                                    id={'min'}
                                    value={rules['amount']['value']['min']}
                                    onChange={(value) =>
                                      this.handleChangeValue('amount', { min: value })
                                    }
                                  />
                                </Stack.Item>
                                <Stack.Item>and</Stack.Item>
                                <Stack.Item fill>
                                  <TextField
                                    label=""
                                    autoComplete={''}
                                    id={'max'}
                                    labelHidden
                                    maxLength={500}
                                    type="number"
                                    value={rules['amount']['value']['max']}
                                    onChange={(value) =>
                                      this.handleChangeValue('amount', { max: value })
                                    }
                                  />
                                </Stack.Item>
                              </Stack>
                            </Stack.Item>
                          )}
                        </Stack>
                      </Card.Section>
                    </>
                  )}
                </Tabs>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>

        {isOpen && (
          <SelectProductModal
            products={products}
            stored_product_variants={rules.products.value}
            onSave={this.getSelectedProducts}
            isOpen={isOpen}
            toggleEvent={this.toggleModal}
            loading={products_loading}
          />
        )}

        {isOfferModalOpen && (
          <SelectProductModal
            products={products}
            stored_product_variants={offered_products_variants}
            onSave={this.getSelectedProductsOffer}
            isOpen={isOfferModalOpen}
            toggleEvent={this.toggleOfferModal}
            loading={products_loading}
          />
        )}
      </>
    );
  }
}

export default PrePurchaseUpsellItem;
