import { action, observable } from 'mobx';
import RootStore from '../RootStore';
import { IRoute } from '../../typings/elements.typing';
import {
  ChatMajor,
  OrdersMajor,
  PackageMajor,
  SocialAdMajor,
  ProductsMajor,
  CheckoutMajor,
} from '@shopify/polaris-icons';

export default class RouteStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable routes: IRoute[] = [
    {
      id: 'pre-purchase-upsell',
      title: 'Cart Upsell',
      path: '/pre-purchase-upsell',
      icon: CheckoutMajor,
    },
    {
      id: 'post-purchase-upsell',
      title: 'Post Purchase Upsell',
      path: '/post-purchase-upsell',
      icon: PackageMajor,
    },
    // {
    //   id: 'post-purchase-sms',
    //   title: 'Post-Purchase SMS',
    //   path: '/campaigns',
    //   hasLimitations: true,
    //   icon: ChatMajor,
    //   childs: [
    //     {
    //       id: 'order-confirmation',
    //       title: 'Order Confirmation',
    //       path: '/campaigns/order-confirmation',
    //       icon: SocialAdMajor,
    //     },
    //     {
    //       id: 'shipping-notification',
    //       title: 'Shipping Notification',
    //       path: '/campaigns/shipping-notification',
    //       icon: OrdersMajor,
    //     },
    //     {
    //       id: 'automated-reorder',
    //       title: 'Automated Reorder',
    //       path: '/campaigns/automated-reorder',
    //       icon: PackageMajor,
    //     },
    //     {
    //       id: 'product-insights',
    //       title: 'Product Insights',
    //       path: '/campaigns/product-insights',
    //       icon: ProductsMajor,
    //     },
    //   ],
    // },
  ];

  @action.bound
  updateRoutes = async (pathname: string): Promise<void> => {
    try {
      this.routes.forEach((route: IRoute) => {
        const reg = new RegExp(route.path, 'ig');
        route.isOpen = !!reg.test(pathname);
        if (route.childs) {
          route.childs.forEach((subRoute: IRoute) => {
            const subReg = new RegExp(subRoute.path, 'ig');
            subRoute.isOpen = !!subReg.test(pathname);
          });
        }
      });
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };
}
