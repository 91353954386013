import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import InputText from '../../../shared/InputText/InputText';
import { Card } from '@shopify/polaris';
import { ContentCardButton } from '../Content.styled';
import { IToken } from '../../../../typings/elements.typing';
import jwtDecode from 'jwt-decode';
import TagArea from '../OrderConfirmation/TagArea';

interface Props {
  text_message: string;
  changeField({ field, value }: { [key: string]: string }): void;
  handleSubmit(): void;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class ShippingNotificationsForm extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }
  errorText: string = 'This field is required';

  handleChange = (obj: { [key: string]: string }) => this.props.changeField(obj);

  handleSubmit = () => {
    this.props.handleSubmit();
  };

  getDomain = () => {
    const token: string = localStorage.getItem('accessToken') || '';
    const parseToken: IToken = token ? jwtDecode(token) : {};

    const shopDomain = parseToken.shopDomain || '';

    return shopDomain;
  };

  render() {
    const { text_message } = this.props;

    return (
      <Card.Section>
        <TagArea
          text_message={text_message}
          field={`text_message`}
          changeField={this.handleChange}
          tags={['first_name_customer', 'first_name_staff', 'shop_name', 'track_trace_url']}
        />
        {/*<InputText*/}
        {/*  label="Text Message"*/}
        {/*  placeholder=""*/}
        {/*  value={text_message}*/}
        {/*  multiline={true}*/}
        {/*  onChange={this.handleChange('text_message')}*/}
        {/*  showCharacterCount={true}*/}
        {/*  maxLength={250}*/}
        {/*  readOnly={false}*/}
        {/*  error={submitted && !text_message && this.errorText}*/}
        {/*  isEmoji={true}*/}
        {/*/>*/}
        {/*<br />*/}
        {/*<InputText*/}
        {/*  label="Second Message"*/}
        {/*  placeholder=""*/}
        {/*  value={blueprintMessage2}*/}
        {/*  multiline={true}*/}
        {/*  onChange={this.handleChange('blueprintMessage2')}*/}
        {/*  showCharacterCount={true}*/}
        {/*  maxLength={250}*/}
        {/*  readOnly={false}*/}
        {/*  error={submitted && !blueprintMessage2 && this.errorText}*/}
        {/*  isEmoji={true}*/}
        {/*/>*/}
        {/*<br />*/}
        {/*<InputText*/}
        {/*  label="Third Message"*/}
        {/*  placeholder=""*/}
        {/*  value={blueprintMessage3}*/}
        {/*  multiline={true}*/}
        {/*  onChange={this.handleChange('blueprintMessage3')}*/}
        {/*  showCharacterCount={true}*/}
        {/*  maxLength={250}*/}
        {/*  readOnly={false}*/}
        {/*  error={submitted && !blueprintMessage3 && this.errorText}*/}
        {/*  isEmoji={true}*/}
        {/*/>*/}
        {/*<br />*/}
        {/*<InputText*/}
        {/*  label="Call to Action Button"*/}
        {/*  placeholder=""*/}
        {/*  onChange={this.handleChange('blueprintCTA')}*/}
        {/*  showCharacterCount={true}*/}
        {/*  maxLength={20}*/}
        {/*  readOnly={false}*/}
        {/*  error={submitted && this.errorText}*/}
        {/*  isEmoji={true}*/}
        {/*  multiline={true}*/}
        {/*/>*/}
        {/*<br />*/}
        {/*<InputText*/}
        {/*  label="Call to Action Button URL"*/}
        {/*  placeholder="this.getDomain()"*/}
        {/*  onChange={this.handleChange('blueprintCTAURL')}*/}
        {/*  readOnly={false}*/}
        {/*  error={submitted && this.errorText}*/}
        {/*  multiline={true}*/}
        {/*/>*/}
        <ContentCardButton onClick={this.handleSubmit}>Update</ContentCardButton>
      </Card.Section>
    );
  }
}

export default ShippingNotificationsForm;
