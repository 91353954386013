import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import {
  Card,
  Button,
  Image,
  SkeletonThumbnail,
  SkeletonDisplayText,
  SkeletonBodyText,
  Select,
} from '@shopify/polaris';
import { ContentCardSmall } from '../Content.styled';
import {
  ExtensionTopBanner,
  ExtensionBody,
  ExtensionCountdown,
  ExtensionBanner,
  ProductImage,
  ProductName,
  ProductPrice,
  ExtensionContainer,
  ButtonContainer,
  PreviewTitle,
} from './ExtensionPreview.styled';
import { template } from 'lodash';
import * as _ from 'lodash';
import { renderPrice } from '../../../../utils/number';

interface IProduct {
  id: string;
  image?: string;
  label?: string;
  variants?: any;
}

interface Props {
  product: IProduct;
  discount: string;
  title: string;
  subtitle: string;
  countdown_text: string;
  countdown_minutes: string;
  price?: string;
  discount_price?: string;
  store_name?: string;
  accept_button_title?: string;
  decline_button_title?: string;
  products?: any;
}

interface State {
  discounted_price: number;
  products: any;
  selected_product: any;
  [k: string]: string | boolean | any;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class ExtensionPreview extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const { postPurchaseExtension } = settings;
    const {
      product,
      discount,
      title,
      subtitle,
      countdown_text,
      countdown_minutes,
    } = postPurchaseExtension;

    this.state = {
      discounted_price: 0,
      products: [],
      selected_product: 0,
    };
  }

  componentDidMount() {}

  calculatePrice = (price: number, discount: number) => {
    const discount_price = price - price * (discount / 100);
    return discount_price.toFixed(2);
  };
  selectProduct = (id: any) => {
    this.setState({
      selected_product: id,
    });
  };
  render() {
    const {
      product,
      title,
      subtitle,
      countdown_minutes,
      countdown_text,
      discount,
      store_name,
      accept_button_title,
      decline_button_title,
      products,
    } = this.props;
    const { selected_product } = this.state;
    const { settings } = this.injected.rootStore.settingsStore;
    const price = _.get(product, 'variants[0].price', 0);
    const discount_price = this.calculatePrice(+price, +discount);

    let products_list: any = [];
    let variants_list: any = [];

    if (!_.isEmpty(products)) {
      const product_by_id = _.chain(products)
        .keyBy('value')
        .mapValues((product) => {
          return _.map(product.variants, (variant) => {
            return {
              label: variant.title,
              value: variant.title,
            };
          });
        })
        .value();
      products_list = _.map(products, (product) => {
        return {
          label: product.label,
          value: product.value,
        };
      });
      const first_product_id = products_list[0].value;
      variants_list = selected_product
        ? product_by_id[selected_product]
        : product_by_id[first_product_id];
    }

    return (
      <Fragment>
        {/*<ContentCardSmall>*/}
        <Card title="Preview">
          <ExtensionContainer>
            {/*<PreviewTitle>Preview</PreviewTitle>*/}
            <ExtensionTopBanner>
              <h1>{store_name}</h1>

              <div>
                <span>Order #1039</span>
                <h2>You’ve paid for your order.</h2>
                <a href="#">View order confirmation ›</a>
              </div>
            </ExtensionTopBanner>
            <ExtensionBanner>
              <h3>{title}</h3>
              <span>
                {subtitle} {discount}%
              </span>
            </ExtensionBanner>
            <ExtensionCountdown>
              {countdown_text} {countdown_minutes}
            </ExtensionCountdown>
            <ExtensionBody>
              {products && (
                <>
                  <Select
                    label={'Products'}
                    options={products_list}
                    onChange={this.selectProduct}
                  />
                  <br />
                  <Select label={'Variants'} options={variants_list} />
                  <br />
                  <br />
                </>
              )}
              <ProductImage>
                {product.image ? (
                  <Image source={product.image || ''} alt={'product-image'} />
                ) : (
                  <SkeletonThumbnail size="large" />
                )}
              </ProductImage>
              <ProductName>
                {product.label ? product.label : <SkeletonBodyText lines={2} />}
              </ProductName>
              <ProductPrice>
                {product.label ? (
                  <>
                    <span>{renderPrice(price, settings.shopData.money_with_currency_format)}</span>
                    <span>
                      {renderPrice(discount_price, settings.shopData.money_with_currency_format)}
                    </span>
                  </>
                ) : (
                  <SkeletonDisplayText size="large" />
                )}
              </ProductPrice>

              <ButtonContainer>
                <Button primary size={'large'}>
                  {accept_button_title}
                </Button>
                <Button plain size={'large'}>
                  {decline_button_title}
                </Button>
              </ButtonContainer>
            </ExtensionBody>
          </ExtensionContainer>
        </Card>
        {/*</ContentCardSmall>*/}
      </Fragment>
    );
  }
}

export default ExtensionPreview;
