import styled from 'styled-components';
import iphone from '../../../../images/iphone.png';
// import {PreviewMessage, PreviewMessageWithMargin} from "../CardReminder/CardReminder.styled";

interface PreviewContentProps {
  hidden?: boolean;
}

export const CardReminderPreview = styled.div`
  margin-left: 50px;
  flex: 0 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 293px;
  height: 600px;
  background: url(${iphone}) no-repeat;
  background-size: cover;
  padding: 0 15px;
  position: sticky;
  top: -5%;

  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;

export const PreviewContent = styled.div`
  width: 100%;
  height: 376px;
  overflow: auto;
  padding: 0 10px;

  ${(p: PreviewContentProps) => (p.hidden ? 'display: none' : 'display: block')};
`;

export const PreviewMessage = styled.div`
  display: block;
  border-radius: 5px;
  background: #f6f6f6;
  padding: 5px;
  font-size: 12px;
  color: #000000;
  word-break: break-word;
`;

export const PreviewMessageArea = styled.pre`
  display: block;
  border-radius: 5px;
  background: #f6f6f6;
  padding: 5px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 12px;
  color: #000000;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const PreviewMessageWithMargin = styled(PreviewMessageArea)`
  margin-top: 10px;
`;

export const PreviewMessageCustomer = styled(PreviewMessageWithMargin)`
  background-color: #66dd61;
  color: #fff;
  width: 15%;
  margin-left: 85%;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  max-width: 810px;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PreviewButtonQuickRepliesWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const PreviewButtonQuickReplies = styled.div`
  color: #0084ff;
  padding: 1px 5px 1px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #0084ff;
  margin-top: 10px;
  border-radius: 40px;
  font-size: 11px;
`;
