import React, { useState, useCallback, useEffect } from 'react';
import {
  Avatar,
  Filters,
  Modal,
  ResourceItem,
  ResourceList,
  TextContainer,
  TextStyle,
  TextField,
  Button,
  Card,
  OptionList,
  Stack,
} from '@shopify/polaris';
import * as _ from 'lodash';

interface IVariant {
  id: string;
  title: string;
  price: string;
}

interface IProduct {
  id: string;
  label: string;
  variants: IVariant[];
  image: string;
  name: string;
  variants_selected: [];
}

function SelectProductModal(props: any) {
  const { products, onSave, isOpen, toggleEvent, stored_product_variants, loading } = props;
  const [queryValue, setQueryValue] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsVariants, setProductsVariants] = useState({});
  const productsById = _.keyBy(products, 'id');

  useEffect(
    () => {
      setItems(products);
    },
    [products],
  );

  useEffect(
    () => {
      //@ts-ignore;
      setSelectedProducts(_.keys(stored_product_variants));
      setProductsVariants(stored_product_variants);
    },
    [stored_product_variants],
  );

  const storeData = () => {
    const result: any = {};
    for (const [productId, variantsId] of Object.entries(productsVariants)) {
      if (_.includes(selectedProducts, productId) && !_.isEmpty(variantsId)) {
        result[productId] = variantsId;
      }
    }
    onSave(result);
  };

  const handleQueryValueChange = useCallback(
    function(value) {
      const filtered_items: any = _.filter(products, (product) => {
        return _.lowerCase(product.label).startsWith(_.lowerCase(value));
      });
      setQueryValue(value);
      setItems(filtered_items);
    },
    [products],
  );

  //@ts-ignore;
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(
    () => {
      setQueryValue(null);
      setItems(products);
    },
    [products],
  );
  const handleClearAll = useCallback(
    () => {
      handleTaggedWithRemove();
      handleQueryValueRemove();
    },
    [handleQueryValueRemove, handleTaggedWithRemove],
  );

  const resourceName = {
    singular: 'customer',
    plural: 'customers',
  };

  const filters: any = [];

  const appliedFilters: any = [];

  const filterControl = (
    <Filters
      //@ts-ignore
      queryValue={queryValue}
      filters={filters}
      appliedFilters={appliedFilters}
      onQueryChange={handleQueryValueChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={() => {
        setItems(products);
      }}
    />
  );

  const renderItem = (item: IProduct) => {
    let id: string = item.id;
    let { label, name, image, variants } = item;
    const media = <Avatar customer size="large" source={image} name={name} />;
    return (
      <>
        <ResourceItem
          id={id}
          url={''}
          media={media}
          accessibilityLabel={`View details for ${label}`}
          verticalAlignment="baseline"
        >
          <Stack vertical>
            <Stack.Item>
              <h3>
                <TextStyle variation="strong">{label}</TextStyle>
              </h3>
            </Stack.Item>
          </Stack>
        </ResourceItem>
        {_.includes(selectedProducts, id) && (
          <Stack.Item>
            <div style={{ paddingLeft: '5rem' }}>
              <OptionList
                options={variants.map((variant: IVariant) => {
                  return {
                    value: variant.id,
                    label: variant.title,
                  };
                })}
                onChange={(selected) => {
                  setProductsVariants((prevState) => {
                    return {
                      ...prevState,
                      [id]: selected,
                    };
                  });
                }}
                title={'Variants'}
                //@ts-ignore;
                selected={productsVariants[id] || _.map(variants, 'id')}
                allowMultiple
              />
            </div>
          </Stack.Item>
        )}
      </>
    );
  };

  return (
    <>
      <div style={{ height: '800px' }}>
        <Modal
          open={isOpen}
          onClose={toggleEvent}
          title="Select Products from your Catalogue"
          primaryAction={{
            content: 'Save',
            onAction: () => storeData(),
          }}
        >
          <Modal.Section>
            <TextContainer>
              <p>
                Only when one or more products are included in the order will the upsell offer be
                shown
              </p>
            </TextContainer>

            <ResourceList
              resourceName={{
                singular: 'Product',
                plural: 'Products',
              }}
              items={items}
              loading={loading}
              renderItem={renderItem}
              selectedItems={loading ? [] : selectedProducts}
              onSelectionChange={(selection: any) => {
                const productId: string = _.last(selection) || '';
                if (!productId) {
                  setProductsVariants({});
                  setSelectedProducts([]);
                  return;
                }

                setProductsVariants((prevState) => {
                  return {
                    ...prevState,
                    [productId]: _.map(productsById[productId].variants, 'id'),
                  };
                });
                setSelectedProducts(selection);
              }}
              filterControl={filterControl}
              selectable
            />
          </Modal.Section>
        </Modal>
      </div>
    </>
  );
}

export default SelectProductModal;
