//@ts-nocheck
import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import { ContentBody, ContentCard, ContentTitle } from '../Content.styled';
import {
  CardReminderPreview,
  PreviewContent,
  PreviewMessageCustomer,
  PreviewMessageWithMargin,
  ContentWrapper,
  ContentHeader,
} from './AutomatedReorder.styled';
import { Card, Button } from '@shopify/polaris';
import { Mixpanel } from '../../../../utils/analytics';
import AutomatedReorderForm from './AutomatedReorderForm';
import _ from 'lodash';
import history from '../../../../utils/history';

interface Props {}

interface State {
  submitted: boolean;
  text_message: string;
  text_message2: string;
  text_message3: string;
  rendered_template: string | boolean;
  rendered_template2: string | boolean;
  rendered_template3: string | boolean;

  [k: string]: string | boolean;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class AutomatedReorder extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 're-order';

    this.state = {
      text_message: settings.customTemplates[campaign][0].text,
      text_message2: settings.customTemplates[campaign][1].text,
      text_message3: settings.customTemplates[campaign][2].text,
      rendered_template: '',
      rendered_template2: '',
      rendered_template3: '',
      submitted: false,
    };
  }

  toastText: string = 'Message template has been updated';

  handleChange = (obj: { [key: string]: string }) => {
    const [key] = Object.keys(obj);
    const renderedDummyData = this.renderDummyData(obj[key], key);

    if (renderedDummyData) {
      this.setState({
        rendered_template: renderedDummyData,
      });
    }
    this.setState(obj);
  };

  openSettings = () => {
    history.push('/campaigns/automated-reorder/products');
  };

  renderDummyData = (text_message: string, key: string) => {
    const { settings } = this.injected.rootStore.settingsStore;
    const { sampleOrder } = settings;
    const shopProfile = settings.profile;
    let ownerFirstName = shopProfile.firstName || (settings.shopData || {}).shop_owner;
    [ownerFirstName] = ownerFirstName.split(' ');

    let order_items = _.chain(sampleOrder)
      .get('line_items', [
        {
          quantity: 1,
          title: 'Water Filter',
        },
      ])
      //@ts-ignore
      .map((item) => {
        return `${item.quantity}x ${item.title}`;
      })
      .join('\n')
      .value();

    const { country, zip, city, address1 } = _.get(sampleOrder, 'shipping_address', {
      address1: 'Infinite Loop 1 st.',
    });
    const total = _.get(sampleOrder, 'total_price', 9.99);
    const testData = {
      text_message: {
        order: {
          ...sampleOrder,
          customer: {
            ...sampleOrder.customer,
            first_name: _.get(sampleOrder, 'customer.first_name', 'Steve'),
          },
          shopOwner: ownerFirstName,
          shopName: settings.shopName || settings.shopData.name || 'shopName',
        },
      },
      text_message2: {
        order_items: order_items + '\n',
        address: address1,
        total: total,
      },
      text_message3: {
        checkout_url: `https://${
          settings.shopData.domain
        }/55225385160/orders/4c60e0a218f871031833cc330124006c`,
      },
    };

    let templateString;
    let renderedTemplate;
    try {
      templateString = _.template(text_message);
      //@ts-ignore
      renderedTemplate = templateString(testData[key]);
      return renderedTemplate;
    } catch (err) {
      console.log('[template]: syntax issue, skipping ', err);
    }
    return '';
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const { text_message, text_message2, text_message3 } = this.state;
    try {
      closeToast();
      await updateSettings({
        campaign: 're-order',
        type: 'template',
        data: [
          {
            step: 0,
            text_message: text_message,
          },
          {
            step: 1,
            text_message: text_message2,
          },
          {
            step: 2,
            text_message: text_message3,
          },
        ],
      });
      openToast(this.toastText);
      this.setState({ submitted: true });
      await getSettings();
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  componentDidMount() {
    const { text_message, text_message2, text_message3 } = this.state;
    const renderedDummyData = this.renderDummyData(text_message, 'text_message');
    const renderedDummyData2 = this.renderDummyData(text_message2, 'text_message2');
    const renderedDummyData3 = this.renderDummyData(text_message3, 'text_message3');
    if (renderedDummyData) {
      this.setState({
        rendered_template: renderedDummyData,
        rendered_template2: renderedDummyData2,
        rendered_template3: renderedDummyData3,
      });
    }
  }

  render() {
    const {
      text_message,
      text_message2,
      text_message3,
      submitted,
      rendered_template,
      rendered_template2,
      rendered_template3,
    } = this.state;
    return (
      <Fragment>
        <ContentWrapper>
          <ContentHeader>
            <ContentTitle>Automated Re-order</ContentTitle>
            <Button plain onClick={this.openSettings}>
              Edit Settings
            </Button>
          </ContentHeader>
        </ContentWrapper>
        <ContentBody>
          <ContentCard>
            <Card>
              <AutomatedReorderForm
                text_message={text_message}
                text_message2={text_message2}
                text_message3={text_message3}
                submitted={submitted}
                changeField={this.handleChange}
                handleSubmit={this.handleSubmit}
              />
            </Card>
          </ContentCard>
          <CardReminderPreview>
            <PreviewContent>
              {rendered_template && (
                <PreviewMessageWithMargin data-highlight="preview-message-1">
                  {rendered_template}
                </PreviewMessageWithMargin>
              )}
              <PreviewMessageCustomer>yes</PreviewMessageCustomer>
              {rendered_template2 && (
                <PreviewMessageWithMargin data-highlight="preview-message-2">
                  {rendered_template2}
                </PreviewMessageWithMargin>
              )}
              <PreviewMessageCustomer>yes</PreviewMessageCustomer>
              {rendered_template3 && (
                <PreviewMessageWithMargin data-highlight="preview-message-3">
                  {rendered_template3}
                </PreviewMessageWithMargin>
              )}
            </PreviewContent>
          </CardReminderPreview>
        </ContentBody>
      </Fragment>
    );
  }
}

export default AutomatedReorder;
