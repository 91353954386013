import styled from 'styled-components';

// import {PreviewMessage, PreviewMessageWithMargin} from "../CardReminder/CardReminder.styled";

interface PreviewContentProps {
  hidden?: boolean;
}

interface IProductImage {
  src: string;
}

export const ProductItemTitle = styled.div`
  font-size: 14px;
  color: #2c6ecb;
`;

export const ToggleButtons = styled.div`
  margin-left: 8px;
  button {
    margin-left: 8px;
  }
`;

export const ProductItemVariant = styled.div`
  font-size: 12px;
  color: #637381;
`;

export const ProductItemSku = styled.div`
  font-size: 12px;
  color: #637381;
`;

export const ContentBody = styled.div`
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 30px;
`;

export const ContentTitle = styled.div`
  text-align: center;
  font-size: 24px;
  margin-bottom: 15px;
`;

export const CategoryProductsCount = styled.div`
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
`;

export const ContentSubtitle = styled.div`
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
`;

export const ContentWrapper = styled.div`
  max-width: 810px;
`;

export const CategoriesList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  font-family: 'Montserrat', sans-serif;
  margin-top: 70px;
`;

export const CategoryItem = styled.div`
  width: 30%;
  margin-right: 45px;
  cursor: pointer;
`;

export const CategoryTitle = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
`;

export const CategoryBody = styled.div`
  box-shadow: 0 0 0 1px #ddd, 0 0 0 0 transparent;
  padding: 20px 30px;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 0 0 2px #7b8dff, 0 0 0 0 transparent !important;
  }
  &.selected {
    box-shadow: 0 0 0 2px #5c6ac4, 0 0 0 0 transparent;
  }
  &:active {
    box-shadow: 0 0 0 2px #adb8ff, 0 0 0 0 transparent !important;
  }
`;

export const CategoryDescription = styled.div`
  text-align: center;
`;

export const CategoryProducts = styled.div`
  border: 1px solid #ddd;
  min-width: 100px;
  min-height: 250px;
  margin-bottom: 15px;
`;

export const ContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  justify-content: space-between;
`;

export const CustomersLabel = styled.div`
  font-size: 16px;
`;

export const CustomersBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  margin-top: 70px;
`;

export const CustomerTypes = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  padding: 15px 0;
`;

export const CustomerItem = styled.div`
  box-shadow: 0 0 0 1px #ddd, 0 0 0 0 transparent;
  padding: 20px 30px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 20px 0 0;
  &:hover {
    box-shadow: 0 0 0 2px #7b8dff, 0 0 0 0 transparent !important;
  }
  &.selected {
    box-shadow: 0 0 0 2px #5c6ac4, 0 0 0 0 transparent;
  }
  &:active {
    box-shadow: 0 0 0 2px #adb8ff, 0 0 0 0 transparent !important;
  }
`;

export const ProductImage = styled.div`
  background-image: url(${(props: IProductImage) => props.src});
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  background-size: contain;
  background-position: center;
`;

interface IPreviewLine {
  space?: boolean;
}

export const PreviewLine = styled.div`
  margin-bottom: ${(props: IPreviewLine) => (props.space ? '15px' : '')};
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Montserrat', sans-serif;
  margin-top: 70px;
`;

export const PreviewLabel = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
`;
