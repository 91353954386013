import React, { Component, Fragment } from 'react';
import { IAnalytic } from '../../../../../typings/elements.typing';
import {
  AnalyticsBlock,
  AnalyticsNumber,
  AnalyticsSectionWrapper,
  AnalyticsSeparator,
  AnalyticsTitle,
} from './AnalyticsSection.styled';
import { renderPrice } from '../../../../../utils/number';

interface Statistics {
  orders: number;
  aov: number;
  sales: number;
}

interface Props {
  orders: number;
  aov: number;
  sales: number;
  price_template: string;
  preparedAnalyticsData?: any;
}

class UpsellAnalyticsSection extends Component<Props> {
  render() {
    const { orders, aov, sales, price_template, preparedAnalyticsData } = this.props;
    return (
      <AnalyticsSectionWrapper>
        {preparedAnalyticsData && (
          <>
            <AnalyticsBlock>
              <AnalyticsNumber>{preparedAnalyticsData.view}</AnalyticsNumber>
              <AnalyticsTitle>Views</AnalyticsTitle>
            </AnalyticsBlock>
            <AnalyticsBlock>
              <AnalyticsNumber>{preparedAnalyticsData.activation}</AnalyticsNumber>
              <AnalyticsTitle>Activations</AnalyticsTitle>
            </AnalyticsBlock>
            <AnalyticsBlock>
              <AnalyticsNumber>{preparedAnalyticsData.deactivation}</AnalyticsNumber>
              <AnalyticsTitle>Deactivations</AnalyticsTitle>
            </AnalyticsBlock>
          </>
        )}
        <AnalyticsBlock>
          <AnalyticsNumber>{orders}</AnalyticsNumber>
          <AnalyticsTitle>Orders</AnalyticsTitle>
        </AnalyticsBlock>
        <AnalyticsBlock>
          <AnalyticsNumber>{renderPrice(aov, price_template)}</AnalyticsNumber>
          <AnalyticsTitle>AOV</AnalyticsTitle>
        </AnalyticsBlock>
        <AnalyticsBlock>
          <AnalyticsNumber>{renderPrice(sales, price_template)}</AnalyticsNumber>
          <AnalyticsTitle>Sales</AnalyticsTitle>
        </AnalyticsBlock>
      </AnalyticsSectionWrapper>
    );
  }
}

export default UpsellAnalyticsSection;
