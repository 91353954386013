import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import { ContentBody, ContentCard, ContentHeader, ContentTitle } from '../Content.styled';
import {
  CardReminderPreview,
  PreviewButtonQuickReplies,
  PreviewButtonQuickRepliesWrapper,
  PreviewContent,
  PreviewMessageWithMargin,
} from '../AutomatedReorder/AutomatedReorder.styled';
import { Card } from '@shopify/polaris';
import { Mixpanel } from '../../../../utils/analytics';
import ShippingNotificationsForm from './ShippingNotificationsForm';
import { template } from 'lodash';

interface Props {}

interface State {
  text_message: string;
  rendered_template: string | boolean;
  [k: string]: string | boolean;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class ShippingNotification extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 'shipping-notification';

    this.state = {
      text_message: settings.customTemplates[campaign][0].text,
      rendered_template: '',
      submitted: false,
    };
  }

  toastText: string = 'Message template has been updated';

  handleChange = (obj: { [key: string]: string }) => {
    const [key] = Object.keys(obj);
    const renderedDummyData = this.renderDummyData(obj[key]);

    if (renderedDummyData) {
      this.setState({
        rendered_template: renderedDummyData,
      });
    }
    this.setState(obj);
  };

  renderDummyData = (text_message: string) => {
    const { settings } = this.injected.rootStore.settingsStore;
    const shopProfile = settings.profile;
    let ownerFirstName = shopProfile.firstName || (settings.shopData || {}).shop_owner;
    [ownerFirstName] = ownerFirstName.split(' ');

    const testData = {
      shopname: settings.shopName || settings.shopData.name || 'shopName',
      shop_name: settings.shopName || settings.shopData.name || 'shopName',
      first_name_staff: ownerFirstName,
      first_name_customer: 'Steve',
      track_trace_url: 'https://www.fedex.com/fedextrack/?trknbr=987654321',
    };

    let templateString;
    let renderedTemplate;
    try {
      templateString = template(text_message);
      renderedTemplate = templateString(testData);
      return renderedTemplate;
    } catch (err) {
      console.log('[template]: syntax issue, skipping ', err);
    }
    return '';
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const { text_message } = this.state;
    try {
      closeToast();
      await updateSettings({
        campaign: 'shipping-notification',
        type: 'template',
        data: [
          {
            step: 0,
            text_message: text_message,
          },
        ],
      });
      openToast(this.toastText);
      this.setState({ submitted: true });
      await getSettings();
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  componentDidMount() {
    const { text_message } = this.state;
    const renderedDummyData = this.renderDummyData(text_message);
    if (renderedDummyData) {
      this.setState({
        rendered_template: renderedDummyData,
      });
    }
  }

  render() {
    const { text_message, rendered_template } = this.state;
    const { settings } = this.injected.rootStore.settingsStore;

    return (
      <Fragment>
        <ContentHeader>
          <ContentTitle>Shipping Notification</ContentTitle>
        </ContentHeader>
        <ContentBody>
          <ContentCard>
            <Card>
              <ShippingNotificationsForm
                text_message={text_message}
                changeField={this.handleChange}
                handleSubmit={this.handleSubmit}
              />
            </Card>
          </ContentCard>
          <CardReminderPreview>
            <PreviewContent>
              {text_message && (
                <PreviewMessageWithMargin data-highlight="preview-message-1">
                  {rendered_template}
                </PreviewMessageWithMargin>
              )}
            </PreviewContent>
          </CardReminderPreview>
        </ContentBody>
      </Fragment>
    );
  }
}

export default ShippingNotification;
