import styled from 'styled-components';

export const PlansList = styled.div`
  display: flex;
  // justify-content: space-around;
  justify-content: center;
  margin: 50px 0 150px 0;
`;

export const PlanCard = styled.div`
  width: 275px;
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  margin: 0 15px;
  padding: 15px 25px;
  @media only screen and (max-width: 1439px) {
    width: 280px;
  }
  @media only screen and (max-width: 1380px) {
    width: 270px;
  }
  @media only screen and (max-width: 1280px) {
    width: 250px;
  }
  @media only screen and (max-width: 1100px) {
    width: 210px;
  }
`;

export const PlanTitle = styled.div`
  font-height: 400;
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #757575;
  text-align: left;
  @media only screen and (max-width: 1100px) {
    font-size: 1.3em;
  }
`;

export const PlanPrice = styled.div`
  font-size: 1.3em;
  font-height: 400;
  margin-bottom: 10px;
  text-align: left;
  @media only screen and (max-width: 1100px) {
    font-size: 1.1em;
  }
`;

export const PlanSubPrice = styled.div`
  font-size: 1em;
  font-height: 400;
  margin-bottom: 10px;
  min-height: 40px;
`;

export const PlanSubPricePayG = styled.div`
  min-height: 0;
  margin-bottom: 0;
  font-size: 1.3em;
`;

export const PlanSubDescription = styled.div`
  font-size: 0.9em;
`;

export const PlanFeaturesList = styled.div`
  font-size: 1em;
  text-align: left;
  ul {
    list-style: none;
    padding-left: 0;
    li {
      margin-bottom: 5px;
    }
    li:before {
      content: '-';
      text-indent: -5px;
    }
  }
  @media only screen and (max-width: 1100px) {
    font-size: 0.8em;
  }
`;

export const PlanCardHeader = styled.div`
  min-height: 70px;
  border-bottom: 1px solid #d3d3d3;
`;
