import React, { PureComponent, Fragment } from 'react';
import { BaseEmoji, Picker } from 'emoji-mart';
import { TextField } from '@shopify/polaris';

import { EmojiIconWrapper, Wrapper } from './InputText.styled';
// import { TextFieldProps } from '@shopify/polaris/types/latest/src/components/TextField/TextField';
import { LabelledProps } from '@shopify/polaris';
import { Error } from '@shopify/polaris/build/ts/3.4/src/types';

declare type Type =
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week'
  | 'currency';
declare type Alignment = 'left' | 'center' | 'right';

interface TextFieldProps {
  /** Text to display before value */
  prefix?: React.ReactNode;
  /** Text to display after value */
  suffix?: React.ReactNode;
  /** Hint text to display */
  placeholder?: string;
  /** Initial value for the input */
  value?: string;
  /** Additional hint text to display */
  helpText?: React.ReactNode;
  /** Label for the input */
  label: string;
  /** Adds an action to the label */
  labelAction?: LabelledProps['action'];
  /** Visually hide the label */
  labelHidden?: boolean;
  /** Disable the input */
  disabled?: boolean;
  /** Show a clear text button in the input */
  clearButton?: boolean;
  /** Disable editing of the input */
  readOnly?: boolean;
  /** Automatically focus the input */
  autoFocus?: boolean;
  /** Force the focus state on the input */
  focused?: boolean;
  /** Allow for multiple lines of input */
  multiline?: boolean | number;
  /** Error to display beneath the label */
  error?: Error | boolean;
  /** An element connected to the right of the input */
  connectedRight?: React.ReactNode;
  /** An element connected to the left of the input */
  connectedLeft?: React.ReactNode;
  /** Determine type of input */
  type?: Type;
  /** Name of the input */
  name?: string;
  /** ID for the input */
  id?: string;
  /** Defines a specific role attribute for the input */
  role?: string;
  /** Limit increment value for numeric and date-time inputs */
  step?: number;
  /** Enable automatic completion by the browser */
  autoComplete?: boolean | string;
  /** Mimics the behavior of the native HTML attribute, limiting the maximum value */
  max?: number | string;
  /** Maximum character length for an input */
  maxLength?: number;
  /** Mimics the behavior of the native HTML attribute, limiting the minimum value */
  min?: number | string;
  /** Minimum character length for an input */
  minLength?: number;
  /** A regular expression to check the value against */
  pattern?: string;
  /** Indicate whether value should have spelling checked */
  spellCheck?: boolean;
  /** Indicates the id of a component owned by the input */
  ariaOwns?: string;
  /** Indicates the id of a component controlled by the input */
  ariaControls?: string;
  /** Indicates the id of a related component’s visually focused element to the input */
  ariaActiveDescendant?: string;
  /** Indicates what kind of user input completion suggestions are provided */
  ariaAutocomplete?: string;
  /** Indicates whether or not the character count should be displayed */
  showCharacterCount?: boolean;
  /** Determines the alignment of the text in the input */
  align?: Alignment;

  /** Callback when clear button is clicked */
  onClearButtonClick?(id: string): void;

  /** Callback when value is changed */
  onChange?(value: string, id: string): void;

  /** Callback when input is focused */
  onFocus?(): void;

  /** Callback when focus is removed */
  onBlur?(): void;
}

interface Props extends TextFieldProps {
  isEmoji?: boolean;
  error?: string | boolean;

  onChange(value: string): void;
}

interface State {
  isShowEmoji: boolean;
}

class InputText extends PureComponent<Props> {
  static defaultProps: Partial<Props> = {
    isEmoji: false,
  };
  state: State = {
    isShowEmoji: false,
  };
  wrapperRef: HTMLDivElement = document.createElement('div');
  errorMaxLength: string = 'The text entered exceeds the character limit';

  onSelectEmoji = (emoji: BaseEmoji): void => {
    const { onChange, value } = this.props;

    const sym = emoji.unified.split('-');
    const codesArray: number[] = [];

    sym.forEach((el: string) => codesArray.push(+('0x' + el)));
    const emojiPic = String.fromCodePoint(...codesArray);
    onChange(value + emojiPic);
  };

  onToggleEmoji = (value: boolean) => this.setState({ isShowEmoji: value });

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  getErrorMaxLength = (): string | boolean => {
    const { maxLength, value } = this.props;
    if (maxLength && value) return maxLength < value.length ? this.errorMaxLength : '';
    return false;
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node: HTMLDivElement) => {
    this.wrapperRef = node;
  };

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event: { target: any }): void => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.onToggleEmoji(false);
    }
  };

  render(): React.ReactNode {
    const { isShowEmoji } = this.state;
    const { isEmoji, onChange, label, error, ...props } = this.props;
    const customError = error || this.getErrorMaxLength();

    return (
      <Wrapper>
        <TextField
          {...props}
          autoComplete={''}
          error={customError}
          label={label}
          onChange={onChange}
        />
        {isEmoji && (
          <Fragment>
            <EmojiIconWrapper error={customError} onClick={() => this.onToggleEmoji(true)} />
            {isShowEmoji && (
              <div ref={this.setWrapperRef}>
                <Picker onClick={this.onSelectEmoji} />
              </div>
            )}
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

export default InputText;
