import { Card, ProgressBar, TextStyle } from '@shopify/polaris';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Content } from '../OnBoarding.styled';
import {
  Section,
  HeaderSubTitle,
  PageContent,
  OnboardingWrapper,
  ContentCTASection,
  HeaderTitle,
  PageHeader,
} from './CampaignList.styled';
import history from '../../../utils/history';
import { Mixpanel } from '../../../utils/analytics';
import './CampaignList.css';
import cartDrawerImage2x from '../../../images/cart-drawer@2x.png';
import postPurchasePageImage2x from '../../../images/post-purchase-page@2x.png';
import '../../../images/checkout-page.png';
import { IRootStore } from '../../../typings/stores.typing';

interface Props {}

interface State {
  cid?: string;
  vid?: string;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class CampaignsList extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      cid: '',
      vid: '',
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(history.location.search);
    const cid = query.get('cid');
    const vid = query.get('vid');
    this.setState({
      cid: cid || '',
      vid: vid || '',
    });
    Mixpanel.register({
      cid: cid,
      vid: vid,
    });
    Mixpanel.track('visit_from_campaign', {
      isLost: true,
      cid: cid,
      vid: vid,
    });
  }

  nextHandler = async () => {
    const { updateSettings } = this.injected.rootStore.settingsStore;
    await updateSettings({
      type: 'profile',
      data: {
        onBoardingComplete: true,
      },
    });
    history.push('/onboarding/pricing');
  };

  render() {
    return (
      <Fragment>
        <PageContent>
          <OnboardingWrapper>
            <Section>
              <PageHeader>
                <HeaderTitle>Boost Sales with In Cart Upsell & Cross Sell</HeaderTitle>
                <HeaderSubTitle>Choose when, where and how many products to upsell</HeaderSubTitle>
              </PageHeader>

              <Content>
                <div className={'campaign-list'}>
                  <div className={'campaign-item cart-drawer'}>
                    <img src={cartDrawerImage2x} alt="" />
                    <div className="campaign-title">Cart Drawer or Page</div>
                  </div>
                  {/*<div className={ 'campaign-item' }>*/}
                  {/*  <img src="" alt=""/>*/}
                  {/*  <div className="campaign-title">Checkout Page</div>*/}
                  {/*</div>*/}
                  <div className={'campaign-item'}>
                    <img src={postPurchasePageImage2x} alt="" />
                    <div className="campaign-title">Post Purchase Page</div>
                  </div>
                </div>
                <ContentCTASection>
                  <a onClick={this.nextHandler}>Setup Dashboard</a>
                </ContentCTASection>
              </Content>
            </Section>
          </OnboardingWrapper>
        </PageContent>
      </Fragment>
    );
  }
}

export default CampaignsList;
