// @ts-ignore
import ReactGA from 'react-ga';
import { GA_ID, MIXPANEL_ID, MIXPANEL_API_HOST } from '../config';

const ga = GA_ID;

export const initGA = () => {
  if (ga) {
    ReactGA.initialize(ga);
  }
};

export const logPageView = () => {
  if (ga) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
};

export const logEvent = (category = '', action = '', label = '') => {
  if (ga && category && action) {
    ReactGA.event({ category: category + ': ' + label, action });
  }
};

export const logException = (description = '', fatal = false) => {
  if (ga && description) {
    ReactGA.exception({ description, fatal });
  }
};

// @ts-ignore
import mixpanel from 'mixpanel-browser';
mixpanel.init(MIXPANEL_ID, { api_host: MIXPANEL_API_HOST }, '');

let actions = {
  identify: (id: string) => {
    if (MIXPANEL_ID) mixpanel.identify(id);
  },
  alias: (id: object) => {
    if (MIXPANEL_ID) mixpanel.alias(id);
  },
  track: (name: string, props: object) => {
    if (MIXPANEL_ID) mixpanel.track(name, props);
  },
  people: {
    set: (props: object) => {
      if (MIXPANEL_ID) mixpanel.people.set(props);
    },
  },
  register: (props: object) => {
    if (MIXPANEL_ID) mixpanel.register(props);
  },
};

export let Mixpanel = actions;
