import { action, observable, runInAction } from 'mobx';
import RootStore from '../RootStore';
import { IBilling, IBillingModal } from '../../typings/elements.typing';
import { API_APP } from '../../config';
import { http } from '../../utils/http';
import { Mixpanel } from '../../utils/analytics';
import history from '../../utils/history';
export default class BillingStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable settings: IBilling = {
    isFetched: false,
    isPremium: false,
    isSubscribed: false,
    isShowed: false,
    status: 'warning',
    remainingTrialDays: 0,
    totalTrialDays: 0,
  };

  @observable modal: IBillingModal = {
    isShow: false,
  };

  @action.bound
  openModal = (): void => {
    this.modal.isShow = true;
  };

  @action.bound
  closeModal = (): void => {
    this.modal.isShow = false;
  };

  @action.bound
  getStatus = async (): Promise<void> => {
    try {
      let responseApi = await http(`${API_APP}/subscription`, 'GET');
      const responseJson = await responseApi.json();
      const { isSubscribed, remainingTrialDays, totalTrialDays, isPremium } = responseJson;
      this.updateSettings({
        isFetched: true,
        isSubscribed,
        isPremium,
        remainingTrialDays,
        totalTrialDays,
      });
    } catch (e) {
      const error = await e.json();
      error.status = e.status;
      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  getRedirectURL = async (plan = 'basic', callback_path = ''): Promise<string> => {
    try {
      let responseApi = await http(
        `${API_APP}/subscribe?plan=${plan}&callback_path=${callback_path}`,
        'GET',
      );
      const responseJson = await responseApi.json();
      return responseJson.redirectUrl;
    } catch (e) {
      const error = await e.json();
      error.status = e.status;
      this.rootStore.errorStore.setError(error);
    }
    return '';
  };

  @action.bound
  subscriptionUpdate = async (chargeId: string): Promise<void> => {
    const data = { chargeId: chargeId };

    try {
      await http(`${API_APP}/subscribe/confirm`, 'POST', data);
      this.updateSettings({
        status: 'success',
      });
    } catch (e) {
      this.updateSettings({
        status: 'critical',
      });
    }
  };

  @action.bound
  updateSettings = (settings: Partial<IBilling>) => {
    runInAction(() => (this.settings = { ...this.settings, ...settings }));
  };
}
