import React, { Component } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

export default class CloseNavigationIcon extends Component<Props> {
  static defaultProps: Partial<Props> = {
    width: '16px',
    height: '16px',
    color: '#ffffff',
  };

  render(): React.ReactNode {
    const { width, height, color } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 16 16" version="1.1">
        <g id="2---Dashboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Artboard-Copy-4" transform="translate(-30.000000, -22.000000)" fill={color}>
            <g id="Monotone" transform="translate(30.000000, 22.000000)">
              <path
                d="M9.4141,8 L15.7071,1.707 C16.0981,1.316 16.0981,0.684 15.7071,0.293 C15.3161,-0.098 14.6841,-0.098 14.2931,0.293 L8.0001,6.586 L1.7071,0.293 C1.3161,-0.098 0.6841,-0.098 0.2931,0.293 C-0.0979,0.684 -0.0979,1.316 0.2931,1.707 L6.5861,8 L0.2931,14.293 C-0.0979,14.684 -0.0979,15.316 0.2931,15.707 C0.4881,15.902 0.7441,16 1.0001,16 C1.2561,16 1.5121,15.902 1.7071,15.707 L8.0001,9.414 L14.2931,15.707 C14.4881,15.902 14.7441,16 15.0001,16 C15.2561,16 15.5121,15.902 15.7071,15.707 C16.0981,15.316 16.0981,14.684 15.7071,14.293 L9.4141,8 Z"
                id="Icon"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
