export const VERSION_API = '/api/1.0';
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID || '';
export const API_APP = process.env.API_APP || '';
export const NEW_API_APP = API_APP + VERSION_API || '';
export const FILESTACK_KEY = process.env.FILESTACK_KEY || '';
export const ERROR_REPORTING_KEY = process.env.ERROR_REPORTING_KEY || '';
export const FULLSTORY_ORG = process.env.FULLSTORY_ORG || '';
export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID || '';
export const GA_ID = process.env.GA_ID || '';
export const PIXEL_ID = process.env.PIXEL_ID || '';
export const LINKEDIN_ID = process.env.LINKEDIN_ID || '';
export const MIXPANEL_ID = process.env.MIXPANEL_ID || '';
export const MIXPANEL_API_HOST = process.env.MIXPANEL_API_HOST || '';
