import styled from 'styled-components';
import { CardToggle } from '../Content.styled';

export const DashboardContent = styled.div`
  margin-top: 55px;
  max-width: 820px;
  @media only screen and (max-width: 1100px) {
    margin-top: 30px;
    max-width: none;
  }
`;

export const AnalysisDataLoading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Montserrat, sans-serif;
`;

export const SpinnerWrapper = styled.div`
  margin-right: 10px;
  padding-top: 2px;
  img {
    height: 1.5rem;
  }
`;

export const LoadingTextMessage = styled.div`
  margin-left: 15px;
`;

export const BannerEEAWrapper = styled.div`
  max-width: 820px;
  margin-bottom: 55px;
  @media only screen and (max-width: 1100px) {
    max-width: none;
  }
`;

export const DashboardCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  @media only screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`;

export const DashboardCardText = styled.div`
  width: 275px;
`;

export const DashboardCardTitle = styled.div`
  line-height: 1.2;
  font-weight: 400;
  color: #637381;
  font-size: 18px;
`;

export const DashboardCardButton = styled.button`
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  padding: 5px 25px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  background-color: #fc5085;
  line-height: 2rem;
  border: none;
  &:hover {
    background-color: #e13264;
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  }
  &:focus {
    outline: none;
  }
`;

export const DashboardUpgradeButton = styled(DashboardCardButton)`
  margin-top: 15px;
  min-width: 100px;
`;

export const DashboardCardTitleBlock = styled.div`
  font-size: 20px;
  line-height: 1.5;
`;

export const DashboardCardSubSection = styled.div`
  max-width: 500px;
`;

export const DashboardCardToggle = styled(CardToggle)`
  margin-left: 20px;
`;

export const DashboardCardButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    margin-top: 10px;
  }
`;

export const DashboardBillingBar = styled.div`
  margin-bottom: 40px;
`;

export const FeatureSectionWrapper = styled.div`
  margin: 30px 0;
`;

export const FeatureSectionTitle = styled.h1`
  font-size: 24px;
  color: #123d55;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
  vertical-text: center;
  margin-bottom: 15px;
`;

export const BannerWrapper = styled.div`
  margin-bottom: 25px;
`;
