import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Sidebar from './Sidebar/Sidebar';
import Content from './Content/Content';
import { DashboardBackground, DashboardFrame, DashboardWrapper } from './Dashboard.styled';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import { Frame, Toast } from '@shopify/polaris';
import { IRootStore } from '../../typings/stores.typing';
//@ts-ignore
import { FullStoryAPI } from 'react-fullstory';
import { TourProvider, withTour } from '@reactour/tour';

interface Props {}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class Dashboard extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  steps = [
    {
      selector: `[data-tour="dashboard-tour-step-1"]`,
      content: <p>Your cart upsell analytics will be displayed here.</p>,
    },
    {
      selector: `[data-tour="dashboard-tour-step-2"]`,
      content: <p>Your post purchase analytics will be displayed here.</p>,
    },
    {
      selector: `[data-tour="dashboard-tour-step-3"]`,
      content: (
        <p>
          Use the toggle to activate or deactivate your cart upsell campaigns. Edit the pencil icon
          to edit them.
        </p>
      ),
    },
  ];

  async componentDidMount() {
    const {
      getProfile,
      getNotifications,
      getAnalytics,
      settings,
      profile,
    } = this.injected.rootStore.settingsStore;
    const { getStatus } = this.injected.rootStore.billingStore;

    if (!profile.id) {
      // getProfile().then(() =>
      //   FullStoryAPI('identify', profile.id, {
      //     firstName: profile.first_name,
      //     lastName: profile.last_name,
      //   }),
      // );
    }

    // await getNotifications();
    // await getAnalytics();
    // if (!settings.isFetched) await getStatus();
  }

  render() {
    const { toast } = this.injected.rootStore.settingsStore;
    const { closeToast } = this.injected.rootStore.settingsStore;

    const skipTour = localStorage.getItem('skipTour');
    return (
      <Fragment>
        <DashboardBackground />
        <DashboardWrapper>
          <TourProvider
            defaultOpen={!skipTour}
            badgeContent={({ totalSteps, currentStep }) => `${currentStep + 1}/${totalSteps}`}
            beforeClose={() => {
              localStorage.setItem('skipTour', 'true');
            }}
            scrollSmooth
            steps={this.steps}
          >
            {/*<MobileNavigation/>*/}
            <Sidebar />
            <Content />
          </TourProvider>
        </DashboardWrapper>
        <DashboardFrame>
          <Frame>
            {toast.show ? <Toast content={toast.content} onDismiss={closeToast} /> : null}
          </Frame>
        </DashboardFrame>
      </Fragment>
    );
  }
}

export default Dashboard;
