import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../typings/stores.typing';
import { Card } from '@shopify/polaris';
import { ProfileStoreName, ProfileTitle, ProfileWrapper } from './ProfileSettings.styled';

interface Props {}

interface State {
  shopDomain: string;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class ProfileSettings extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
  }

  render() {
    const { settings } = this.injected.rootStore.settingsStore;
    return (
      <Fragment>
        <Card.Section>
          <ProfileWrapper>
            <ProfileTitle>{settings.shopData.shop_owner}</ProfileTitle>
            <ProfileStoreName href={'https://' + settings.domainName} target="_blank">
              {settings.domainName}
            </ProfileStoreName>
          </ProfileWrapper>
        </Card.Section>
      </Fragment>
    );
  }
}

export default ProfileSettings;
