import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProfileTitle = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #123d55;
`;

export const ProfileStoreName = styled.a`
  margin-top: 10px;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: #000000;
`;
