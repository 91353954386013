import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../typings/stores.typing';
import { ContentBodyProductInsights, ContentTitle, CardToggle } from '../../Content.styled';
import {
  ProductItemTitle,
  ProductItemSku,
  ProductItemVariant,
  ContentHeader,
  ContentWrapper,
  ToggleButtons,
} from './Settings.styled';
import { Card, TextStyle, DataTable, Filters, ChoiceList, Button, Badge } from '@shopify/polaris';
import _ from 'lodash';
import 'react-step-progress-bar/styles.css';
import './Settings.css';

interface Props {}

interface ISelectedCategory {
  low: boolean;
  medium: boolean;
  high: boolean;

  [key: string]: boolean;
}

interface State {
  selectedCategories: ISelectedCategory;
  reorder_likelihood: [string];
  toggledProducts: [string];
  queryValue: string;
  filteredProducts: any;
  [k: string]: string | boolean | ISelectedCategory | any;
}

interface ICategoryItem {
  name: string;
  count: number;
  first_image: string;
  products: any;
  reorderPercentage: number;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

interface ICategory {
  [key: string]: string;
}

interface ICategories {
  [key: string]: string;
}

interface IStep {
  accomplished: boolean;
  index: number;
}

interface IProduct {
  productId: string;
}

@inject('rootStore')
@observer
class ProductCatalogue extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 're-order';
    this.state = {
      selectedCategories: {
        low: true,
        medium: true,
        high: true,
      },
      reorder_likelihood: [''],
      toggledProducts: [''],
      queryValue: '',
      appliedFilters: [],
      filteredProducts: [],
    };
  }

  async componentDidMount() {
    const { settings } = this.injected.rootStore.settingsStore;
    let { products, selectedProducts } = settings;
    const { selectedCategories } = this.state;
    const productCategories = _.get(
      settings,
      'reorderSettings.productCategories',
      selectedCategories,
    );
    this.setState({
      selectedCategories: productCategories,
      filteredProducts: products,
      selectedProducts: selectedProducts || [],
    });
  }

  ProductItem = (productTitle: string, productSku: string, variantTitle: string) => {
    return (
      <div>
        <ProductItemTitle>{productTitle}</ProductItemTitle>
        {variantTitle && <ProductItemVariant>Variant: {variantTitle}</ProductItemVariant>}
        {productSku && <ProductItemSku>SKU: {productSku}</ProductItemSku>}
      </div>
    );
  };

  disambiguateLabel = (key: string, value: [string]) => {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      case 'reorder_likelihood':
        return `${value}`;
      case 'productType':
        return value.join(', ');
      default:
        return value;
    }
  };

  handleReOrderChange = (selected: [string]) => {
    const { settings } = this.injected.rootStore.settingsStore;
    const { products } = settings;
    const key = 'reorder_likelihood';
    if (selected.length === 1 && selected[0] === '') {
      this.setState({
        filteredProducts: products,
        [key]: selected,
      });
      return;
    }
    let filteredProducts = _.filter(products, (item) => {
      return selected.includes(item.reorderChance);
    });

    this.setState((prevState, props) => {
      return {
        [key]: selected,
        filteredProducts: filteredProducts,
      };
    });
  };

  handleToggleFilter = (selected: [string]) => {
    const key = 'toggledProducts';
    const { settings } = this.injected.rootStore.settingsStore;
    const { products, selectedProducts } = settings;
    let { filteredProducts } = this.state;
    if (selected.length === 1 && selected[0] === '') {
      this.setState({
        [key]: selected,
        filteredProducts: products,
      });
      return;
    }
    filteredProducts = _.filter(products, (item) => {
      return selected.includes(selectedProducts[item.productId].toString());
    });
    this.setState((prevState, props) => {
      return {
        [key]: selected,
        filteredProducts: filteredProducts,
      };
    });
  };

  handleFiltersQueryChange = (query: string) => {
    const { settings } = this.injected.rootStore.settingsStore;
    const { products } = settings;
    const queryString = _.lowerCase(query);
    let filteredProducts = _.filter(products, (item) => {
      return (
        _.lowerCase(item.productTitle).includes(queryString) ||
        item.productSku.includes(queryString)
      );
    });
    this.setState({
      queryValue: query,
      filteredProducts: filteredProducts,
    });
  };

  handleQueryValueRemove = () => {
    const { settings } = this.injected.rootStore.settingsStore;
    const { products } = settings;
    this.setState({
      queryValue: '',
      filteredProducts: products,
    });
  };

  handleSort = (columnIndex: number, order: string) => {
    const { filteredProducts } = this.state;
    const columnMapping: any = {
      0: 'productTitle',
      1: 'averageOrderCount',
    };
    let result = [];
    result = _.sortBy(filteredProducts, columnMapping[columnIndex]);
    result = order === 'ascending' ? result : _.reverse(result);

    this.setState({
      filteredProducts: result,
    });
  };

  handleFiltersClearAll = () => {
    console.log('handle');
  };

  renderBadge = (chance: string) => {
    const badgeMapping: any = {
      extreme: { color: 'success', label: 'Very High' },
      high: { color: 'info', label: 'High' },
      moderate: { color: 'attention', label: 'Moderate' },
      low: { color: 'warning', label: 'Low' },
    };
    return <Badge status={badgeMapping[chance].color}>{badgeMapping[chance].label}</Badge>;
  };

  toggleProduct = async (product_id: string) => {
    const { settings, updateSettings } = this.injected.rootStore.settingsStore;
    const { selectedProducts } = settings;
    selectedProducts[product_id] = !selectedProducts[product_id];
    await updateSettings(
      {
        type: 'selectedProducts',
        data: selectedProducts,
      },
      true,
      false,
    );
  };

  toggleAllHandler = async (type: string) => {
    const { filteredProducts } = this.state;
    const { settings, updateSettings } = this.injected.rootStore.settingsStore;
    const { selectedProducts } = settings;

    _.each(filteredProducts, (product) => {
      selectedProducts[product.productId] = type === 'on';
    });
    await updateSettings(
      {
        type: 'selectedProducts',
        data: selectedProducts,
      },
      true,
      false,
    );
  };

  render() {
    const { settings } = this.injected.rootStore.settingsStore;
    const { selectedProducts } = settings;
    const {
      reorder_likelihood,
      toggledProducts,
      queryValue,
      appliedFilters,
      filteredProducts,
    } = this.state;

    let rows = [];

    for (const product of filteredProducts) {
      rows.push([
        this.ProductItem(product.productTitle, product.productSku, product.variantTitle),
        product.averageOrderCount,
        this.renderBadge(product.reorderChance),
        <CardToggle
          checked={selectedProducts[product.productId] || false}
          onClick={() => this.toggleProduct(product.productId)}
        />,
      ]);
    }

    const filters = [
      {
        key: 'reorder-likelihood',
        label: 'ReOrder Likelihood',
        filter: (
          <ChoiceList
            title="ReOrder Likelihood"
            titleHidden
            choices={[
              { label: 'Very High', value: 'extreme' },
              { label: 'High', value: 'high' },
              { label: 'Moderate', value: 'moderate' },
              { label: 'Low', value: 'low' },
            ]}
            selected={reorder_likelihood || []}
            onChange={this.handleReOrderChange}
            allowMultiple
          />
        ),
        hideClearButton: true,
        shortcut: true,
      },
      {
        key: 'toggle',
        label: 'ReOrder Toggle',
        hideClearButton: true,
        filter: (
          <ChoiceList
            title="ReOrder Toggle"
            titleHidden
            choices={[{ label: 'On', value: 'true' }, { label: 'Off', value: 'false' }]}
            selected={toggledProducts || []}
            onChange={this.handleToggleFilter}
            allowMultiple
          />
        ),
      },
    ];

    function isEmpty(value: string) {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else {
        return value === '' || value == null;
      }
    }

    return (
      <Fragment>
        <ContentWrapper>
          <ContentHeader>
            <ContentTitle>Product Insights</ContentTitle>
            <TextStyle variation="subdued">
              Toggle off the product you don't want to include in the Automated ReOrder campaign.
            </TextStyle>
          </ContentHeader>
          <ContentBodyProductInsights>
            <Card>
              <Card.Section>
                <Filters
                  queryValue={queryValue}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={this.handleFiltersQueryChange}
                  onQueryClear={this.handleQueryValueRemove}
                  onClearAll={this.handleFiltersClearAll}
                >
                  <ToggleButtons>
                    <Button onClick={() => this.toggleAllHandler('on')}>Toggle All</Button>
                    <Button onClick={() => this.toggleAllHandler('off')}>Untoggle All</Button>
                  </ToggleButtons>
                </Filters>
              </Card.Section>
              <Card.Section fullWidth={true}>
                <DataTable
                  columnContentTypes={['text', 'numeric', 'numeric', 'numeric']}
                  headings={['Product', 'ReOrder Count', 'ReOrder Likelihood', 'ReOrder Toggle']}
                  rows={rows}
                  sortable={[true, true, false, false]}
                  defaultSortDirection="descending"
                  initialSortColumnIndex={4}
                  onSort={this.handleSort}
                />
              </Card.Section>
            </Card>
          </ContentBodyProductInsights>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default ProductCatalogue;
