import React, { Component } from 'react';
import { Centered, LoadingText, PageContainer, SubTitle, Title } from './Home.styled';
import { Spinner } from '@shopify/polaris';
import Logo from '../shared/Logo/Logo';

export class Home extends Component {
  render() {
    return (
      // @ts-ignore
      <PageContainer>
        <Centered>
          <Logo />
          <Title>Maxwell</Title>
          <SubTitle>In Cart Upsell & Cross Sell</SubTitle>
          <LoadingText>
            <Spinner size="large" />
          </LoadingText>
        </Centered>
      </PageContainer>
    );
  }
}

export default Home;
