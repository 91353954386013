import React, { Component } from 'react';
import { ContentWrapper, ContentRoutes, BannerWrapper } from './Content.styled';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import DashboardHome from './DashboardHome/DashboardHome';
import Settings from './Settings/Settings';
import './Content.css';
import { IRootStore } from '../../../typings/stores.typing';
import OrderConfirmation from './OrderConfirmation/OrderConfirmation';
import ShippingNotification from './ShippingNotification/ShippingNotification';
import AutomatedReorder from './AutomatedReorder/AutomatedReorder';
import Products from './AutomatedReorder/Settings/Products';
import ProductCatalogue from './AutomatedReorder/Settings/ProductCatalogue';
import Customers from './AutomatedReorder/Settings/Customers';
import Planning from './AutomatedReorder/Settings/Planning';
import Preview from './AutomatedReorder/Settings/Preview';
import PostPurchaseUpsell from './PostPurchaseUpsell/PostPurchaseUpsell';
import PostPurchaseUpsellOffers from './PostPurchaseUpsell/PostPurchaseUpsellOffers';
import PostPurchaseUpsellAdvanced from './PostPurchaseUpsell/PostPurchaseUpsellAdvanced';
import PrePurchaseUpsellList from './PrePurchaseUpsell/PrePurchaseUpsellList';
import PrePurchaseUpsellItem from './PrePurchaseUpsell/PrePurchaseUpsellItem';
import { Banner, Button, Spinner } from '@shopify/polaris';
import { AnalysisDataLoading, SpinnerWrapper } from './DashboardHome/Dashboard.styled';
import { http } from '../../../utils/http';
import { API_APP } from '../../../config';
import * as _ from 'lodash';

interface State {
  isExtensionInstalled: boolean;
  isLoaded: boolean;
}

interface Props {
  location?: Location;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

// @ts-ignore
@withRouter
@inject('rootStore')
class Content extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      isExtensionInstalled: true,
      isLoaded: false,
    };
  }

  async componentDidMount() {
    const { settings } = this.injected.rootStore.settingsStore;
    if (!settings.domainName) {
      return;
    }
  }

  render() {
    const { settings } = this.injected.rootStore.settingsStore;
    const { shopData, extension_attribution } = settings;
    const orders_amount = (extension_attribution || {}).month_orders;
    const limits = {
      'Free Post Purchase': 50,
      'Pro Post-Purchase': 100,
      'VIP Post-Purchase': 200,
      'Star Post-Purchase': 500,
    };
    const plan_name = _.get(settings, 'payment.name');
    const orders_limit = _.get(limits, plan_name);
    const limit_threshold = orders_limit - orders_limit / 10;
    const isLimitNear = orders_amount >= limit_threshold;

    let { isExtensionInstalled, isLoaded } = this.state;
    isExtensionInstalled = true;
    return (
      <ContentWrapper>
        {isLimitNear && (
          <BannerWrapper>
            <Banner status={'warning'} title={'Please upgrade'}>
              Your monthly store limit is reached. Please upgrade to keep our upsell campaign
              running.
            </Banner>
          </BannerWrapper>
        )}
        {!settings.analysisData && (
          <Banner status={'info'}>
            <AnalysisDataLoading>
              <SpinnerWrapper>
                <Spinner size={'small'} />
              </SpinnerWrapper>
              <p>
                We’re analyzing your customers’ purchase behavior and preparing your SMS ReOrder
                campaign.
              </p>
            </AnalysisDataLoading>
          </Banner>
        )}
        <ContentRoutes>
          <Switch>
            <Route path="/home" component={DashboardHome} />

            <Redirect exact path="/campaigns" to="/campaigns/order-confirmation" />
            <Route path="/post-purchase-upsell/:id" component={PostPurchaseUpsell} />
            <Route path="/post-purchase-upsell" component={PostPurchaseUpsellOffers} />
            <Route path="/pre-purchase-upsell/:id" component={PrePurchaseUpsellItem} />
            <Route path="/pre-purchase-upsell" component={PrePurchaseUpsellList} />

            <Route
              path="/post-purchase-upsell/advanced-configuration"
              component={PostPurchaseUpsellAdvanced}
            />

            <Route path="/campaigns/order-confirmation" component={OrderConfirmation} />
            <Route path="/campaigns/shipping-notification" component={ShippingNotification} />
            <Route path="/campaigns/automated-reorder/products" component={Products} />
            <Route path="/campaigns/automated-reorder/customers" component={Customers} />
            <Route path="/campaigns/automated-reorder/planning" component={Planning} />
            <Route path="/campaigns/automated-reorder/preview" component={Preview} />
            <Route path="/campaigns/product-insights" component={ProductCatalogue} />
            <Route path="/campaigns/automated-reorder" component={AutomatedReorder} />

            <Route path="/settings" component={Settings} />
          </Switch>
        </ContentRoutes>
      </ContentWrapper>
    );
  }
}

export default Content;
