import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { HeaderTitle, PageBackground, PageHeader } from './OnBoarding.styled';
import CampaignsList from '../OnBoardingNew/CampaignsList/CampaignsList';
import Pricing from '../OnBoardingNew/Pricing/Pricing';
import { IRootStore } from '../../typings/stores.typing';

interface Props {}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
// @ts-ignore
@withRouter
@observer
class OnBoarding extends Component {
  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    return (
      <Fragment>
        <PageBackground />
        <Switch>
          <Redirect exact path="/onboarding" to="/onboarding/campaigns" />
          <Route path="/onboarding/campaigns" component={CampaignsList} />
          <Route path="/onboarding/pricing" component={Pricing} />
        </Switch>
      </Fragment>
    );
  }
}

export default OnBoarding;
