import { action, observable } from 'mobx';
import RootStore from '../RootStore';
import { ITheme, IThemeName, IThemeOptions, IDimensions } from '../../typings/elements.typing';

export default class ThemeStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable themes: Array<ITheme> = [
    { name: 'default', alias: 'Default', options: {} },
    { name: 'splitted', alias: 'Split Screen', options: {} },
    {
      name: 'sticky',
      alias: 'Sticky Widget',
      options: {
        bgColor: {
          brightness: 0,
          hue: 120,
          saturation: 0,
        },
        textColor: {
          brightness: 1,
          hue: 0,
          saturation: 0,
        },
        label: 'Get your discount code',
        buttonWidth: '280',
      },
    },
  ];

  @action.bound
  getImageDimensions = (themeName: IThemeName = 'default'): IDimensions => {
    let dimensions = {
      width: 0,
      height: 0,
    };

    switch (themeName) {
      case 'default':
        dimensions = {
          width: 150,
          height: 150,
        };
        break;
      case 'splitted':
        dimensions = {
          width: 315,
          height: 470,
        };
      case 'sticky':
        dimensions = {
          width: 315,
          height: 470,
        };
        break;
    }

    return dimensions;
  };

  @action.bound
  updateOptions = async (theme: IThemeName, options: Partial<IThemeOptions>): Promise<void> => {
    const themeIndex = this.themes.findIndex((item) => item.name === theme);
    this.themes[themeIndex] = { ...this.themes[themeIndex], options };
  };

  @action.bound
  getTheme = (themeName: IThemeName): Array<ITheme> => {
    return this.themes.filter((item) => item.name === themeName);
  };
}
