import { BannerProps, BannerStatus } from '@shopify/polaris';
import { HSBColor, IconSource } from '@shopify/polaris';

declare global {
  interface Window {
    FB: any;
    FS: any;
    Shopify: any;

    confirmOptIn(): void;

    randomId(n: number): string;

    fbAsyncInit(): void;

    _linkedin_data_partner_ids: string[];
  }
  var SHOP_URL: string;
  var APP_ID: string;
  var SENTRY_DSN: string;
  var API_ENDPOINT: string;
  interface navigator {
    clipboard: any;
  }
}

export interface Channel {
  external_id: string;
  name: string;
  platform: string;
  picture: string;
}

export interface PopupTriggerSettings {
  dismissTimeoutDays: number;
  pageWhitelist: string; // TODO: This should be an enum with 'all', 'home', 'product'
  maxTriggerCount: number;
  pageVisitThresholdForTrigger: number;
  storeVisitThresholdForTrigger: number;
  popupDelaySeconds: number;
  onExitIntent: boolean;
}

export interface IPopupTheme {
  name: 'default' | 'splitted' | 'sticky';
  options: IThemeOptions;
}

export interface ISelectedProducts {
  [key: string]: boolean;
}

export interface Settings {
  shopName: string;
  profile: IProfile;
  campaignsSettings: {
    [key: string]: boolean;
  };
  selectedProducts: ISelectedProducts;

  [key: string]: any;
}

export interface AuthorizeData {
  accessToken: string;
  name?: string;
}

export interface TokenData {
  code?: string;
  state?: string;
  hmac?: string;
  shop?: string;
  charge_id?: string;
  timestamp?: string;
  configuration?: string;
  plan?: string;
  profile?: string;
  upgrade?: string;
  cid?: string;
  vid?: string;
}

export interface IAnalytic {
  id: number;
  value: string;
  title: string;
}

export interface INotificationContact {
  contactCountFromLastVisit: number;
  contactCount?: number;
}

export interface IOrderItem {
  orderAmount: {
    currency: string;
    formattedValue: string;
    value: number;
  };
  orderCount: number;
  orderSource: 'couponCode' | 'reminder' | 'unrelated';
}

export interface IAnalytics {
  total: IAnalytic[];
  contact: INotificationContact;

  [key: string]: object;
}

export interface IProfile {
  phone?: string;
  email?: string;
  id?: string;
  firstName?: string;
  lastName?: string;
  picture?: string;
  onBoardingComplete?: boolean;
  optimizeCheckout?: boolean;
  firstSale?: boolean;
  editReorderSettings?: boolean;
  editUpsellSettings?: boolean;

  cartUpsellCampaign?: boolean;
  postPurchaseUpsellCampaign?: boolean;
  firstCartUpsell?: boolean;
  firstPostPurchaseUsell?: boolean;
  foreverFreeUser?: boolean;
}

export interface IToken {
  shopDomain?: string;
  domainName?: string;
  shopId?: string;
}

export interface IErrorResponse extends Error {
  success?: boolean;
  error: string;
  status: number;
  redirect: boolean;
  isButtonBack?: boolean;
}

export interface IError {
  isShow: boolean;
  isButtonBack: boolean;
  status: number | null;
  message: string;
  redirect?: boolean;
}

export enum AnalyticsCartReminderType {
  delivered = 'Delivered',
  read = 'Read',
  clicked = 'Clicked',
  orderAmount = 'Bought',
  orderCount = 'Ordered',
  sent = 'Sent',
}

export enum AnalyticsCouponPopupType {
  sales = 'Order(s)',
  amount = 'Order Amount',
}

export enum AnalyticsTotalType {
  contacts = 'Contacts',
  messages = 'Messages',
  orders = 'Orders',
  sales = 'Sales',
}

export interface ContentBodyProps {
  column?: boolean;
  basic?: boolean;
}

export interface IBilling extends BannerProps {
  isFetched: boolean;
  isSubscribed: boolean;
  isPremium: boolean;
  isShowed: boolean;
  remainingTrialDays: number;
  totalTrialDays: number;
  status: any;
}

export interface IBillingModal {
  isShow: boolean;
}

export interface IToast {
  show: boolean;
  content: string;
}

export interface IEmojiIconWrapper {
  error: string | boolean;
}

export interface IContact {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: string;
  profileImage: string;
  gender: string;
  isNew: boolean;
}

export interface IThemeOptions {
  bgColor?: HSBColor;
  textColor?: HSBColor;
  label?: string;
  buttonWidth?: string;
}

export type IThemeName = 'default' | 'splitted' | 'sticky';

export interface ITheme {
  name: IThemeName;
  alias: string;
  options: IThemeOptions;
}

export interface IDimensions {
  width: number;
  height: number;
}

export interface IContactParams {
  [key: string]: string | number | boolean;
}

export interface IInfo {
  total: number;
  page: number;
  pages: number;
}

export interface ISidebarItems {
  isCouponPopupExtend: boolean;
}

export enum FacebookEvents {
  clicked = 'clicked',
}
export enum NotificationType {
  contact = 'contact',
  info = 'info',
  success = 'success',
}

export interface IBlueprint {
  data: IBlueprintData;
  delayHours: number;
  isActive: boolean;
  minSentNotificationCount: number;
  type: BlueprintsType;
}

export interface IBlueprintData {
  blueprintCTA?: string;
  blueprintMessage1: string;
  blueprintMessage2?: string;
  blueprintMessage3?: string;
  blueprintCTAUrl?: string;
}

export interface IShipmentData {
  blueprintCTA?: string;
  blueprintMessage1: string;
  blueprintMessage2?: string;
  blueprintCTA2: string;
  initial: string;
  confirmed: string;
  in_transit: string;
  out_for_delivery: string;
  attempted_delivery: string;
  ready_for_pickup: string;
  delivered: string;
  failure: string;
}

export enum BlueprintsType {
  cartReminder = 'cartReminder',
  secondCartReminder = 'secondCartReminder',
  thirdCartReminder = 'thirdCartReminder',
  couponCode = 'couponCode',
  shipment = 'shipment',
}

export interface ICartReminder {
  data: IBlueprintData;
  isTouched: boolean;
  isActive: boolean;
  delayHours: number;
  feature?: string;
}

export interface IShipment {
  data: IShipmentData;
  isTouched: boolean;
  isActive: boolean;
  delayHours: number;
}

export interface IBlueprintsTypes {
  [key: string]: ICartReminder;
}

export interface ICampaign {
  analytics?: ICampaignAnalytics;
  data: ICampaignData;
  name?: string;
}

export interface ICampaignAnalytics {
  clicked: number;
  delivered: number;
  read: number;
  sent: number;
}

export interface ICampaignData {
  blueprintMessage1: string;
  blueprintCTAUrl: string;
  blueprintCTA: string;

  [key: string]: string;
}

export interface IDiscountCodeResponse {
  id: number;
  title: string;
  value: number;
  value_type: 'fixed_amount' | 'percentage';
}

export interface IDiscountCode {
  label: string;
  value: string;
}

export interface IItemProgressBar {
  id: string;
  title: string;
  link: string;
  isChecked: boolean;
}

export interface INotificationData {
  contact: INotificationContact;
  notifications: INotification[];
}

export interface INotification {
  notification: string;
  notificationId: number;
  type: BannerStatus;
  isClosed?: boolean;
}

export interface IRoute {
  id: string;
  title: string;
  isOpen?: boolean;
  isLink?: boolean;
  path: string;
  color?:
    | 'base'
    | 'success'
    | 'warning'
    | 'critical'
    | 'subdued'
    | 'interactive'
    | 'highlight'
    | 'primary'
    | undefined;
  icon: IconSource;
  childs?: IRoute[];
  hasLimitations?: boolean;
  isNew?: boolean;
}
