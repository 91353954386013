import history from './history';

export const http = (url: string, method: string, data?: Object): Promise<Response> => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: method || 'GET',
      body: JSON.stringify(data),
      // credentials: 'include',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: Response) => {
        if (response.ok) {
          resolve(response);
        } else {
          switch (response.status) {
            case 400:
              break;
            case 401:
              history.push('/onboarding');
              break;
            default:
              history.push('/');
              break;
          }
          reject(response);
        }
      })
      .catch((error) => reject(error));
  });
};
