import {
  IAuthStore,
  IErrorStore,
  IOnBoardingStore,
  ISettingsStore,
  IBillingStore,
  IThemeStore,
  IBlueprintStore,
  IContactsStore,
  IRouteStore,
} from '../typings/stores.typing';
import AuthStore from './AuthStore/AuthStore';
import BillingStore from './BillingStore/BillingStore';
import OnBoardingStore from './OnBoardingStore/OnBoardingStore';
import SettingsStore from './SettingsStore/SettingsStore';
import ErrorStore from './ErrorStore/ErrorStore';
import ContactsStore from './ContactsStore/ContactsStore';
import ThemeStore from './ThemeStore/ThemeStore';
import BlueprintStore from './BlueprintStore/BlueprintStore';
import RouteStore from './RouteStore/RouteStore';

export default class RootStore {
  public authStore: IAuthStore;
  public onBoardingStore: IOnBoardingStore;
  public settingsStore: ISettingsStore;
  public errorStore: IErrorStore;
  public billingStore: IBillingStore;
  public contactsStore: IContactsStore;
  public themeStore: IThemeStore;
  public blueprintStore: IBlueprintStore;
  public routeStore: IRouteStore;

  constructor() {
    this.authStore = new AuthStore(this);
    this.onBoardingStore = new OnBoardingStore(this);
    this.billingStore = new BillingStore(this);
    this.settingsStore = new SettingsStore(this);
    this.errorStore = new ErrorStore(this);
    this.contactsStore = new ContactsStore(this);
    this.themeStore = new ThemeStore(this);
    this.blueprintStore = new BlueprintStore(this);
    this.routeStore = new RouteStore(this);
  }
}
