import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import { ContentTitle } from '../Content.styled';
import { Card, TextStyle, ProgressBar } from '@shopify/polaris';
import {
  BannerWrapper,
  DashboardCardButtons,
  DashboardCardHeader,
  DashboardCardText,
  DashboardCardTitle,
  DashboardCardToggle,
  DashboardContent,
  FeatureSectionTitle,
  FeatureSectionWrapper,
} from './Dashboard.styled';
import history from '../../../../utils/history';
import AnalyticsSection from './AnalyticsSection/AnalyticsSection';
import UpsellAnalyticsSection from './AnalyticsSection/UpsellAnalyticsSection';
import { Banner, Spinner } from '@shopify/polaris';

import ProgressBarContainer from './ProgressBarContainer/ProgressBarContainer';
import { INotification } from '../../../../typings/elements.typing';
import PencilIcon from '../../../shared/PencilIcon/PencilIcon';
import { CloseIcon } from './ProgressBarContainer/ProgressBarContainer.styled';
import CloseNavigationIcon from '../../../shared/CloseNavigationIcon/CloseNavigationIcon';

interface State {
  bannerClicked: boolean;
  skipTour: boolean;
}

interface Props {}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class DashboardHome extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  state: State;
  toastText: string = 'Required fields are missing';

  constructor(props: Props) {
    super(props);

    this.state = {
      bannerClicked: false,
      skipTour: false,
    };
  }

  linkTo = (url: string) => history.push(url);

  async componentDidMount() {}

  shouldShowBanner = (): boolean => {
    const {
      settings: { isFetched, isShowed, status },
    } = this.injected.rootStore.billingStore;
    const { isLoaded } = this.injected.rootStore.settingsStore;
    return isLoaded && isFetched && (!isShowed && status !== 'warning');
  };

  onCampaignToggle = async (campaign: string) => {
    const {
      updateSettings,
      openToast,
      closeToast,
      settings,
    } = this.injected.rootStore.settingsStore;

    try {
      await updateSettings({
        data: {
          [campaign]: !(settings.campaignsSettings || {})[campaign],
        },
        type: 'campaignsSettings',
      });
      closeToast();
      openToast(settings.campaignsSettings[campaign] ? 'Campaign enabled' : 'Campaign disabled');
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  onToggleUpsell = async () => {
    const { updateSettings, settings } = this.injected.rootStore.settingsStore;
    try {
      await updateSettings({
        data: {
          extensionEnabled: !settings.postPurchaseExtension.extensionEnabled,
        },
        type: 'postPurchaseExtension',
      });
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  onTogglePrePurchaseUpsell = async () => {
    const { updateSettings, settings } = this.injected.rootStore.settingsStore;
    try {
      await updateSettings({
        data: {
          enabled: !settings.upsell.enabled,
        },
        type: 'upsell',
      });
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  closeNotification = (el: INotification) => {
    const { updateNotification } = this.injected.rootStore.settingsStore;
    updateNotification({ ...el, isClosed: true });
  };

  checkLimits = () => {
    const { settings } = this.injected.rootStore.settingsStore;
    const plan = settings.payment.name;
    const plan_status = settings.payment.status;
    const store_country_code = settings.shopData.country_code;

    if (
      !['NL', 'US', 'CA'].includes(store_country_code) &&
      (plan === 'Free Post Purchase' || plan_status === 'pending')
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { settings, notificationsWithoutProgressBar } = this.injected.rootStore.settingsStore;
    const { skipTour } = this.state;
    const { extension_attribution, cartUpsellAnalytics } = settings;

    const { campaignAttribution, analyticsData } = cartUpsellAnalytics;

    let cartUpsellAnalyticsTotal = {
      totalSales: 0,
      ordersCount: 0,
    };
    let preparedAnalyticsData: any = {
      view: 0,
      activation: 0,
      deactivation: 0,
    };

    for (const [paramName, items] of Object.entries(analyticsData)) {
      //@ts-ignore;
      preparedAnalyticsData[paramName] = items.length;
    }

    for (const [offer_id, analyticsData] of Object.entries(campaignAttribution)) {
      //@ts-ignore;
      const totalSales = analyticsData.totalSales || 0;
      //@ts-ignore;
      const ordersCount = analyticsData.ordersCount || 0;
      cartUpsellAnalyticsTotal.totalSales =
        cartUpsellAnalyticsTotal.totalSales + parseFloat(totalSales);
      cartUpsellAnalyticsTotal.ordersCount = cartUpsellAnalyticsTotal.ordersCount + ordersCount;
    }

    const shopProfile = settings.profile;
    const aov =
      extension_attribution.total_order_price_amount / extension_attribution.orders_amount || 0;
    const cartUpsellAOV =
      cartUpsellAnalyticsTotal.totalSales / cartUpsellAnalyticsTotal.ordersCount || 0;

    return (
      <Fragment>
        {!!notificationsWithoutProgressBar.length &&
          notificationsWithoutProgressBar.map((el) => (
            <div key={el.notificationId}>
              {!el.isClosed && (
                <BannerWrapper>
                  <Banner title="" status={el.type}>
                    <span dangerouslySetInnerHTML={{ __html: el.notification }} />
                    <CloseIcon onClick={() => this.closeNotification(el)}>
                      <CloseNavigationIcon color="#637381" width="15px" height="15px" />
                    </CloseIcon>
                  </Banner>
                </BannerWrapper>
              )}
            </div>
          ))}
        <ContentTitle>
          Hi {shopProfile.firstName || (settings.shopData || {}).shop_owner},
        </ContentTitle>
        <TextStyle variation="subdued">Welcome to your dashboard</TextStyle>

        {/*{isProgressBarNotification && <ProgressBarContainer />}*/}
        <ProgressBarContainer />
        <DashboardContent>
          <FeatureSectionTitle>Cart Upsell Analytics</FeatureSectionTitle>
          <div data-tour={`dashboard-tour-step-1`}>
            <Card>
              <Card.Section>
                <UpsellAnalyticsSection
                  orders={cartUpsellAnalyticsTotal.ordersCount || 0}
                  aov={cartUpsellAOV}
                  sales={cartUpsellAnalyticsTotal.totalSales || 0}
                  price_template={settings.shopData.money_format}
                  preparedAnalyticsData={preparedAnalyticsData}
                />
              </Card.Section>
            </Card>
          </div>
          <br />
          <br />
          <FeatureSectionTitle>Post Purchase Upsell Analytics</FeatureSectionTitle>
          <div data-tour={`dashboard-tour-step-2`}>
            <Card>
              <Card.Section>
                <UpsellAnalyticsSection
                  orders={extension_attribution.orders_amount || 0}
                  aov={aov}
                  sales={extension_attribution.total_order_price_amount || 0}
                  price_template={settings.shopData.money_format}
                />
              </Card.Section>
            </Card>
          </div>
          <br />
          <br />
          {this.checkLimits() && (
            <>
              <FeatureSectionTitle>SMS ReOrder Analytics</FeatureSectionTitle>
              <Card>
                <Card.Section>
                  <AnalyticsSection
                    attribution={settings.attribution}
                    sentSMS={settings.sentSMS}
                    receivedSMS={settings.receivedSMS}
                    price_template={settings.shopData.money_format}
                  />
                </Card.Section>
              </Card>

              <FeatureSectionWrapper>
                <FeatureSectionTitle>Automated Campaigns</FeatureSectionTitle>
                <Card>
                  <Card.Section>
                    <DashboardCardHeader>
                      <DashboardCardText>
                        <DashboardCardTitle>Order Confirmation</DashboardCardTitle>
                      </DashboardCardText>
                      <DashboardCardButtons>
                        <PencilIcon onClick={() => this.linkTo('/campaigns/order-confirmation')} />
                        <DashboardCardToggle
                          checked={settings.campaignsSettings['order-confirmation']}
                          icons={true}
                          onChange={() => this.onCampaignToggle('order-confirmation')}
                        />
                      </DashboardCardButtons>
                    </DashboardCardHeader>
                  </Card.Section>
                  <Card.Section>
                    <DashboardCardHeader>
                      <DashboardCardText>
                        <DashboardCardTitle>Shipping Notification</DashboardCardTitle>
                      </DashboardCardText>
                      <DashboardCardButtons>
                        <PencilIcon
                          onClick={() => this.linkTo('/campaigns/shipping-notification')}
                        />
                        <DashboardCardToggle
                          checked={settings.campaignsSettings['shipping-notification']}
                          icons={true}
                          onChange={() => this.onCampaignToggle('shipping-notification')}
                        />
                      </DashboardCardButtons>
                    </DashboardCardHeader>
                  </Card.Section>
                  <Card.Section>
                    <DashboardCardHeader>
                      <DashboardCardText>
                        <DashboardCardTitle>Automated Re-order</DashboardCardTitle>
                      </DashboardCardText>
                      <DashboardCardButtons>
                        <PencilIcon onClick={() => this.linkTo('/campaigns/automated-reorder')} />
                        <DashboardCardToggle
                          checked={settings.campaignsSettings['re-order']}
                          icons={true}
                          onChange={() => this.onCampaignToggle('re-order')}
                        />
                      </DashboardCardButtons>
                    </DashboardCardHeader>
                  </Card.Section>
                </Card>
              </FeatureSectionWrapper>
            </>
          )}
          <FeatureSectionWrapper>
            <FeatureSectionTitle>Cart Upsell</FeatureSectionTitle>
            <Card>
              <Card.Section>
                <DashboardCardHeader>
                  <DashboardCardText>
                    <DashboardCardTitle>Cart Upsell Offer</DashboardCardTitle>
                  </DashboardCardText>
                  <DashboardCardButtons data-tour={`dashboard-tour-step-3`}>
                    <PencilIcon onClick={() => this.linkTo('/pre-purchase-upsell/new')} />
                    <DashboardCardToggle
                      checked={settings.upsell.enabled}
                      icons={true}
                      onChange={this.onTogglePrePurchaseUpsell}
                    />
                  </DashboardCardButtons>
                </DashboardCardHeader>
              </Card.Section>
            </Card>
          </FeatureSectionWrapper>
          <FeatureSectionWrapper>
            <FeatureSectionTitle>Post Purchase Upsell</FeatureSectionTitle>
            <Card>
              <Card.Section>
                <DashboardCardHeader>
                  <DashboardCardText>
                    <DashboardCardTitle>Post-Purchase Upsell</DashboardCardTitle>
                  </DashboardCardText>
                  <DashboardCardButtons>
                    <PencilIcon
                      onClick={() => this.linkTo('/post-purchase-upsell/basic-configuration')}
                    />
                    <DashboardCardToggle
                      checked={settings.postPurchaseExtension.extensionEnabled}
                      icons={true}
                      onChange={this.onToggleUpsell}
                    />
                  </DashboardCardButtons>
                </DashboardCardHeader>
              </Card.Section>
            </Card>
          </FeatureSectionWrapper>
        </DashboardContent>
      </Fragment>
    );
  }
}

export default DashboardHome;
