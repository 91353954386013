import React, { ChangeEvent, Component, Ref, useRef } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import InputText from '../../../shared/InputText/InputText';
import {
  Card,
  Select,
  Autocomplete,
  Icon,
  OptionList,
  Stack,
  Tag,
  ButtonGroup,
  Button,
  Layout,
  Heading,
  RadioButton,
  TextField,
  Tabs,
  Modal,
  TextContainer,
  ResourceList,
  Avatar,
  ResourceItem,
  TextStyle,
  Filters,
  Banner,
} from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import './form.css';
import * as _ from 'lodash';
import AutocompleteField from './components/AutocompleteField';

interface Props {
  selected_product_id: string;
  products: any;
  current_product: any;
  discount: string;
  title: string;
  offer_name: string;
  subtitle: string;
  countdown_text: string;
  countdown_minutes: string;
  accept_button_title: string;
  decline_button_title: string;
  filtered_products: any;
  filter_criteria?: string;
  product_variants?: any;
  selected_variants?: any;
  product_position?: any;
  fetched_products?: any;
  loading?: boolean;
  triggers?: any;
  products_info?: any;
  triggers_with_conflicts?: any;

  changeField({ field, value }: { [key: string]: string | any }): void;

  handleSubmit(): void;
}

interface State {
  product?: any;
  productSearch?: string;
  selectedProducts: [];
  ordersOption: string;
  ordersAmountOption: string;
  ordersAmountValueMin: string;
  ordersAmountValueMax: string;
  isOpened: boolean;
  products: any;
  selectedTab: number;

  [k: string]: string | boolean | any;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class Form extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  errorText: string = 'This field is required';

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const { postPurchaseExtension } = settings;
    this.state = {
      selectedProducts: this.getTriggerSettings('specific_products', 'products'),
      ordersOption: postPurchaseExtension.ordersOption,
      ordersAmountOption: postPurchaseExtension.ordersAmountOption,
      ordersAmountValueMin: postPurchaseExtension.ordersAmountValueMin,
      ordersAmountValueMax: postPurchaseExtension.ordersAmountValueMax,
      products: [],
      selectedTab: 0,
      isOpened: false,
    };
  }

  handleChange = (field: string) => (value: string) => this.props.changeField({ [field]: value });

  handleSubmit = () => {
    console.log('handle submit');
    // this.props.handleSubmit();
  };

  resourceName = {
    singular: 'product',
    plural: 'products',
  };

  filters = [];

  onClearAll() {}

  filterControl = (
    <Filters
      onQueryChange={console.log}
      onClearAll={this.onClearAll}
      onQueryClear={console.log}
      hideQueryField={true}
      filters={this.filters}
    />
  );

  setSelectedItems = (items: any) => {
    this.setState({
      selectedProducts: items,
    });
  };

  renderItem(item: any) {
    const { id, label, url, name, image, location } = item;
    const media = <Avatar customer size="large" source={image} name={name} />;
    return (
      <ResourceItem
        id={id}
        url={url}
        media={media}
        accessibilityLabel={`View details for ${label}`}
      >
        <h3>
          <TextStyle variation="strong">{label}</TextStyle>
        </h3>
        <div>{location}</div>
      </ResourceItem>
    );
  }

  saveAndToggleModal = async () => {
    const { selectedProducts } = this.state;
    this.setState({
      isOpened: !this.state.isOpened,
    });
    let { triggers } = this.props;
    triggers = _.set(triggers, ['specific_products', 'settings', 'products'], selectedProducts);
    this.props.changeField({
      triggers: triggers,
    });
  };

  removeProductTag = () => {
    const { product_position, products } = this.props;
    const new_product_position = product_position >= 1 ? product_position - 1 : 0;
    delete products[product_position];

    if (!_.keys(products).length) {
      products[0] = {
        value: '',
        label: '',
        variants: [],
        selected_variants: [],
      };
    }
    const new_current_product = this.props.products[new_product_position];
    this.props.changeField({
      products: products,
      product_position: new_product_position,
      filter_criteria: new_current_product.label,
      current_product: new_current_product,
    });
  };

  selectProductTag = (productKey: string) => {
    this.props.changeField({ product_position: productKey });
  };

  addProductTag = () => {
    const { products } = this.props;
    const last_product_key = _.toInteger(_.last(_.keys(products)));
    const next_product_position = last_product_key + 1;
    products[next_product_position] = {
      value: '',
      label: '',
      variants: [],
      selected_variants: [],
    };
    this.props.changeField({
      product_position: next_product_position,
      filter_criteria: '',
      products,
    });
  };

  productsTags = () => {
    return _.map(this.props.products, (product, key) => {
      return (
        <Tag key={key} onClick={() => this.selectProductTag(key)}>
          {product.label ? product.label : 'New Product'}
        </Tag>
      );
    });
  };

  //advanced settings

  handleChangeTrigger = (newValue: any, id: any) => {
    const [value, field] = id.split('-');
    this.props.changeField({
      triggers: {
        ...this.props.triggers,
        [field]: {
          ...this.props.triggers[field],
          wildcard: value !== 'enable',
        },
      },
    });
  };

  changeTriggerSettings = (field: string, name: string, value: any) => {
    let { triggers } = this.props;
    this.props.changeField({
      triggers: {
        ...triggers,
        [field]: {
          ...triggers[field],
          settings: {
            ...triggers[field]['settings'],
            [name]: value,
          },
        },
      },
    });
  };

  handleChangeProducts = (event: any, value: string) => {
    this.setState({
      ordersOption: value,
    });
  };

  handleChangeAmount = (event: any, value: string) => {
    this.setState({
      ordersAmountOption: value,
    });
  };

  handleChangeText = (value: string, key: string) => {
    this.setState({
      [key]: value,
    });
  };

  toggleModal = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  handleTabChange = (tab: number) => {
    this.setState({
      selectedTab: tab,
    });
  };

  isSelected = (field: string) => {
    return _.get(this, ['props', 'triggers', field, 'wildcard'], true);
  };

  getTriggerSettings = (field: string, name: string) => {
    return _.get(this, ['props', 'triggers', field, 'settings', name], '');
  };

  tabs = [
    {
      id: 'offer-config',
      content: 'Offer Config',
      accessibilityLabel: 'Offer Config',
      panelID: 'offer-config-content',
    },
    {
      id: 'advanced-settings',
      content: 'Advanced Settings',
      panelID: 'advanced-settings-content',
    },
  ];

  ConflictNotification = (field: string) => {
    const trigger = _.get(this.props, ['triggers_with_conflicts', field], {});
    console.log('trigger', trigger);

    if (!_.isEmpty(trigger)) {
      return <Banner status={'warning'}>Conflict with existing offer: {trigger.offer_name}</Banner>;
    }
    return <></>;
  };

  render() {
    const {
      products,
      fetched_products,
      selected_product_id,
      discount,
      title,
      offer_name,
      subtitle,
      countdown_minutes,
      countdown_text,
      filtered_products,
      filter_criteria,
      loading,
      current_product,
      product_position,
      triggers,
      products_info,
      triggers_with_conflicts,
      accept_button_title,
      decline_button_title,
    } = this.props;

    const {
      ordersOption,
      ordersAmountOption,
      ordersAmountValueMin,
      ordersAmountValueMax,
      isOpened,
      selectedProducts,
      filteredSelectedProducts,
      selectedTab,
    } = this.state;
    const selectedProductsCount = (selectedProducts.length || 0).toString();
    return (
      <>
        <Card>
          <Tabs tabs={this.tabs} selected={selectedTab} onSelect={this.handleTabChange}>
            {this.tabs[selectedTab].id === 'offer-config' && (
              <>
                <Card.Section
                  title={'Edit Upsell Products'}
                  actions={[
                    {
                      content: 'Delete',
                      destructive: true,
                      onAction: this.removeProductTag,
                    },
                    {
                      content: 'Add',
                      onAction: this.addProductTag,
                    },
                  ]}
                >
                  <Stack spacing="tight">{this.productsTags()}</Stack>
                  <br />
                  {current_product.image === '' && (
                    <>
                      <Banner status={'warning'}>
                        Product without image is not allowed. Please upload an image for this
                        product on Shopify
                      </Banner>
                      <br />
                    </>
                  )}
                  <Autocomplete
                    options={filtered_products}
                    selected={[selected_product_id]}
                    onSelect={(value) => this.props.changeField({ selected_product_id: value[0] })}
                    loading={loading}
                    textField={
                      <Autocomplete.TextField
                        autoComplete={''}
                        onChange={this.handleChange('filter_criteria')}
                        clearButton={true}
                        onClearButtonClick={(value) =>
                          this.props.changeField({ filter_criteria: '' })
                        }
                        label="Product"
                        value={filter_criteria}
                        prefix={<Icon source={SearchMinor} color="base" />}
                        placeholder={
                          fetched_products.length
                            ? 'Search'
                            : 'Please create at least one product in your store'
                        }
                      />
                    }
                  />
                  <br />
                  {selected_product_id && current_product.variants.length > 1 && (
                    <OptionList
                      title="Product Variants"
                      onChange={(value) => this.props.changeField({ selected_variants: value })}
                      options={current_product.variants}
                      selected={current_product.selected_variants}
                      allowMultiple
                    />
                  )}
                  <br />
                </Card.Section>
                <Card.Section title={'Edit Upsell Offer'}>
                  <InputText
                    label="Offer Name"
                    placeholder="Enter your offer name"
                    value={offer_name}
                    onChange={this.handleChange('offer_name')}
                    showCharacterCount={true}
                    multiline={false}
                    maxLength={250}
                    readOnly={false}
                  />
                  <br />
                  <InputText
                    label="Discount"
                    disabled={!selected_product_id}
                    onChange={this.handleChange('discount')}
                    value={discount}
                    type="number"
                    prefix={'%'}
                    min={0}
                    max={99}
                  />
                  <br />
                  <InputText
                    label="Title"
                    placeholder="Enter your title"
                    value={title}
                    onChange={this.handleChange('title')}
                    showCharacterCount={true}
                    multiline={false}
                    maxLength={250}
                    readOnly={false}
                  />
                  <br />
                  <InputText
                    label="Subtitle"
                    placeholder=""
                    value={subtitle}
                    multiline={true}
                    onChange={this.handleChange('subtitle')}
                    showCharacterCount={true}
                    maxLength={500}
                    readOnly={false}
                    isEmoji={true}
                  />
                  <br />
                  <InputText
                    label="Countdown text"
                    placeholder=""
                    value={countdown_text}
                    multiline={true}
                    onChange={this.handleChange('countdown_text')}
                    showCharacterCount={true}
                    maxLength={250}
                    readOnly={false}
                    isEmoji={false}
                  />
                  <br />
                  <InputText
                    label="Countdown minutes timer"
                    placeholder=""
                    value={countdown_minutes}
                    multiline={false}
                    onChange={this.handleChange('countdown_minutes')}
                    showCharacterCount={true}
                    readOnly={false}
                    isEmoji={false}
                  />
                  <br />
                  <InputText
                    label="Accept Offer Button"
                    placeholder=""
                    value={accept_button_title}
                    multiline={false}
                    onChange={this.handleChange('accept_button_title')}
                    showCharacterCount={true}
                    readOnly={false}
                    isEmoji={false}
                  />
                  <br />
                  <InputText
                    label="Decline Offer Button"
                    placeholder=""
                    value={decline_button_title}
                    multiline={false}
                    onChange={this.handleChange('decline_button_title')}
                    showCharacterCount={true}
                    readOnly={false}
                    isEmoji={false}
                  />
                </Card.Section>
              </>
            )}

            {this.tabs[selectedTab].id === 'advanced-settings' && (
              <>
                <Card.Section title={`Show for All Products or Specific Products`}>
                  <Stack vertical spacing={'extraTight'}>
                    <Stack.Item>
                      <RadioButton
                        label="Show for all products"
                        helpText=""
                        checked={this.isSelected('specific_products')}
                        id="disable-specific_products"
                        name="specific_products"
                        onChange={this.handleChangeTrigger}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Stack alignment={'center'}>
                        <Stack.Item fill>
                          <RadioButton
                            label="Show for specific products"
                            helpText=""
                            id="enable-specific_products"
                            name="specific_products"
                            checked={!this.isSelected('specific_products')}
                            onChange={this.handleChangeTrigger}
                          />
                        </Stack.Item>
                        {!this.isSelected('specific_products') && (
                          <Stack.Item>
                            <Button plain onClick={this.toggleModal}>
                              Select Products ({selectedProductsCount})
                            </Button>
                          </Stack.Item>
                        )}
                      </Stack>
                    </Stack.Item>
                    {this.ConflictNotification('specific_products')}
                  </Stack>
                </Card.Section>
                <Card.Section
                  title={`Show for any Quantity of Items in Cart or Specific Quantity of Items`}
                >
                  <Stack vertical spacing={'extraTight'}>
                    <Stack.Item>
                      <RadioButton
                        label="Show for any order quantity"
                        helpText=""
                        id="disable-specific_amount"
                        checked={this.isSelected('specific_amount')}
                        name="filter_amount"
                        onChange={this.handleChangeTrigger}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <RadioButton
                        label="Show for specific order quantity"
                        helpText=""
                        id="enable-specific_amount"
                        name="filter_amount"
                        checked={!this.isSelected('specific_amount')}
                        onChange={this.handleChangeTrigger}
                      />
                    </Stack.Item>
                    <Stack.Item>
                      <Stack wrap={false} alignment="center">
                        <Stack.Item>Between</Stack.Item>
                        <Stack.Item fill>
                          <TextField
                            disabled={this.isSelected('specific_amount')}
                            label=""
                            autoComplete={''}
                            labelHidden
                            maxLength={500}
                            type="number"
                            id={'min'}
                            value={this.getTriggerSettings('specific_amount', 'min')}
                            onChange={(value) =>
                              this.changeTriggerSettings('specific_amount', 'min', value)
                            }
                          />
                        </Stack.Item>
                        <Stack.Item>and</Stack.Item>
                        <Stack.Item fill>
                          <TextField
                            disabled={this.isSelected('specific_amount')}
                            label=""
                            autoComplete={''}
                            id={'max'}
                            labelHidden
                            maxLength={500}
                            type="number"
                            value={this.getTriggerSettings('specific_amount', 'max')}
                            onChange={(value) =>
                              this.changeTriggerSettings('specific_amount', 'max', value)
                            }
                          />
                        </Stack.Item>
                      </Stack>
                    </Stack.Item>
                    {this.ConflictNotification('specific_amount')}
                  </Stack>
                </Card.Section>
              </>
            )}
          </Tabs>
        </Card>
        <div style={{ height: '800px' }}>
          <Modal
            open={isOpened}
            onClose={this.toggleModal}
            title="Select Products from your Catalogue"
            primaryAction={{
              content: 'Save',
              onAction: this.saveAndToggleModal,
            }}
          >
            <Modal.Section>
              <TextContainer>
                <p>
                  Only when one or more products are included in the order will the upsell offer be
                  shown
                </p>
              </TextContainer>
              <ResourceList
                resourceName={this.resourceName}
                items={fetched_products}
                renderItem={this.renderItem}
                selectedItems={selectedProducts}
                onSelectionChange={this.setSelectedItems}
                filterControl={this.filterControl}
                selectable
              />
            </Modal.Section>
          </Modal>
        </div>
      </>
    );
  }
}

export default Form;
