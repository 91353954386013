import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    @import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');

    .Polaris-Frame-ToastManager--toastWrapperEnterDone {
      --toast-translate-y-in: -110px !important;
    }
    
    .Polaris-Frame-ToastManager {
      position: fixed;
      z-index: 99999999;
    }
  }
  
  html, body  {
    height: 100%;
    min-height: 100%;
    min-width: 325px;
  }
  
  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  #root > div {
    position: relative; 
    min-height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .BoxHighlightShadow {
    transition: all 250ms ease-in-out;
    will-change: border;
    box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px #cf0b00;
  }
  .Polaris-Modal-CloseButton {
    z-index: 9;
  }
  
  .Polaris-Tabs__Tab:focus, .Polaris-Tabs__TabContainer:focus, .Polaris-Tabs__Title:focus, button:focus, a:focus {
    box-shadow: none !important;
    outline: none !important;
  }
`;
