import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface IChecklistItemText {
  color?: string;
}

export const ProgressBarContainerWrapper = styled.div`
  position: relative;
  margin-top: 25px;
  max-width: 820px;
  padding: 2rem;
  border-radius: 3px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
`;

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px;
`;

export const ChecklistItem = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 0 50%;
  box-sizing: border-box;
  padding: 10px;
`;

export const ChecklistItemText = styled.div<IChecklistItemText>`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  ${(p: IChecklistItemText) => p.color && 'color: ' + p.color};
`;

export const ChecklistItemButton = styled.div<IChecklistItemText>`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  text-decoration: none;
  color: inherit;
  cursor: default;
  ${(p: IChecklistItemText) => p.color && 'color: ' + p.color};
`;

export const ProgressBarContainerTitle = styled.div`
  margin-bottom: 20px;
  padding-right: 30px;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;
