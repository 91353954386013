import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import history from './../../../../utils/history';
import { IRootStore } from '../../../../typings/stores.typing';
import {
  Card,
  Page,
  Layout,
  ResourceList,
  ResourceItem,
  TextStyle,
  EmptyState,
  SkeletonPage,
  SkeletonBodyText,
  SkeletonDisplayText,
  TextContainer,
  Banner,
  Button,
  CalloutCard,
  Badge,
} from '@shopify/polaris';
import { PlusMinor } from '@shopify/polaris-icons';
import { API_APP, NEW_API_APP } from '../../../../config';
import { http } from '../../../../utils/http';
import * as _ from 'lodash';
import { BannerWrapper } from './PostPurchaseUpsellOffers.styled';

interface Props {}

interface IProduct {
  [order: string]: {
    id: string;
    label: string;
    image?: string;
    variants?: string;
  };
}

interface State {
  [k: string]: string | boolean | any;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class PostPurchaseUpsellOffers extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  fetchStatusInterval: number = 0;

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const { postPurchaseExtension } = settings;
    const { offers } = postPurchaseExtension;

    let offersWithId = _.map(offers, (value, key) => {
      return {
        ...value,
        id: key,
      };
    });

    this.state = {
      offers: offersWithId || [],
      isExtensionInstalled: true,
      isLoaded: false,
    };
  }

  fetchStatus = async () => {
    try {
      console.log('fetch');
      let response: any = await http(`${API_APP}/settings?extension=status`, 'GET');
      response = await response.json();
      const { isExtensionInstalled } = response;

      this.setState({
        isLoaded: true,
        isExtensionInstalled,
      });
      return isExtensionInstalled;
    } catch (err) {
      this.setState({
        isLoaded: true,
        isExtensionInstalled: false,
      });
    }
  };

  async componentDidMount() {
    let isExtensionInstalled = await this.fetchStatus();
    if (!isExtensionInstalled) {
      this.fetchStatusInterval = window.setInterval(async () => {
        isExtensionInstalled = await this.fetchStatus();
        if (isExtensionInstalled) {
          clearInterval(this.fetchStatusInterval);
        }
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.fetchStatusInterval);
  }

  openOffer(id: string) {
    history.push(`/post-purchase-upsell/${id}`);
  }

  createOffer() {
    history.push(`/post-purchase-upsell/new`);
  }

  render() {
    const { isExtensionInstalled, isLoaded, offers } = this.state;
    const { settings } = this.injected.rootStore.settingsStore;
    const { shopData } = settings;
    const appliedFilters: any = [];
    const filters: any = [];

    const emptyStateMarkup =
      !appliedFilters.length && !offers.length ? (
        <EmptyState
          heading="Create offer to get started"
          action={{ content: 'Create Offer', onAction: this.createOffer }}
          image="https://cdn.shopify.com/s/files/1/2376/3301/products/emptystate-files.png"
        >
          <p>
            After creating new offer your customers will be able to see Post Purchase offer page
          </p>
        </EmptyState>
      ) : (
        undefined
      );

    const renderItem = (offer: any) => {
      const triggersList = _.chain(offer)
        .get('triggers')
        //@ts-ignore;
        .map((trigger: any, name: string) => {
          return {
            ...trigger,
            name,
          };
        })
        .filter('active')
        .map((item: any) => _.startCase(item.name))
        .join(', ')
        .value();
      const productsCount = _.keys(offer.products).length;

      return (
        <ResourceItem
          id={offer.id}
          accessibilityLabel={`View details for ${offer.label}`}
          onClick={() => this.openOffer(offer.id)}
        >
          <h3>
            <TextStyle variation="strong">
              {offer.offer_name}
              {/*<Badge status={offer.is_active ? 'success' : 'warning'}>{offer.is_active ? 'Published' : 'Unpublished'}</Badge>*/}
            </TextStyle>
          </h3>
          {triggersList && (
            <>
              <TextStyle variation="subdued">Triggers: {triggersList}</TextStyle>
              <br />
            </>
          )}
          <TextStyle variation="subdued">Discount: {offer.discount}%</TextStyle>
          <br />
          <TextStyle variation="subdued">Products: {productsCount}</TextStyle>
        </ResourceItem>
      );
    };

    return (
      <>
        {isLoaded ? (
          <Page
            title="Post Purchase Offers"
            primaryAction={{ content: 'Create Offer', icon: PlusMinor, onAction: this.createOffer }}
          >
            <Layout>
              {!isExtensionInstalled ? (
                <Layout.Section>
                  <CalloutCard
                    title="Maxwell is not defined as your post purchase upsell app."
                    illustration=""
                    primaryAction={{
                      content: 'Check status',
                      onAction: () => {
                        this.fetchStatus();
                      },
                    }}
                  >
                    <p>
                      Post purchase one click offers will not be displayed to your customers until
                      you set it up
                      <ul>
                        <li>
                          Go to &nbsp;
                          <a
                            href={`https://${
                              shopData.domain
                            }/admin/settings/checkout#PostPurchaseSection`}
                            target={'_blank'}
                          >
                            Settings -> Checkout -> Post Purchase page.
                          </a>
                        </li>
                        <li>Select Maxwell SMS ReOrders</li>
                        <li>Click the 'Save' button.</li>
                      </ul>
                    </p>
                  </CalloutCard>
                </Layout.Section>
              ) : (
                <Layout.Section>
                  <Card>
                    <ResourceList
                      emptyState={emptyStateMarkup}
                      items={offers}
                      //@ts-ignore;
                      renderItem={renderItem}
                      // filterControl={filterControl}
                      resourceName={{ singular: 'offer', plural: 'offers' }}
                    />
                  </Card>
                </Layout.Section>
              )}
            </Layout>
          </Page>
        ) : (
          <SkeletonPage primaryAction>
            <Layout>
              <Layout.Section>
                <Card sectioned>
                  <SkeletonBodyText />
                </Card>
                <Card sectioned>
                  <TextContainer>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                  </TextContainer>
                </Card>
              </Layout.Section>
            </Layout>
          </SkeletonPage>
        )}
      </>
    );
  }
}

export default PostPurchaseUpsellOffers;
