import styled from 'styled-components';
import moneyBack from '../../images/money-back.png';

export const SetupFormWrapper = styled.div`
  width: 300px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  margin: 50px 0;
`;

export const LogoWrapperAbsolute = styled.div`
  position: absolute;
  left: 50px;
  top: 50px;
`;

export const PageBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f6f7fc;
  height: 100%;
  width: 100%;
  @media only screen and (max-width: 1100px) {
    height: 200px;
    width: 100%;
  }
`;

export const Content = styled.div`
  p {
    margin-bottom: 15px;
  }

  ol {
    padding-inline-start: 15px;
    line-height: 2.5rem;
  }
`;

export const ContentCentered = styled.div`
  width: 100%;
  justify-content: center;
  text-align: center;

  p {
    margin-bottom: 15px;
  }

  ol {
    padding-inline-start: 15px;
    line-height: 2.5rem;
  }
`;

export const ContentCTASection = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PageContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #fff;
  .Polaris-Card {
    overflow: inherit;
    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }

  .Polaris-Card__Section {
    max-height: 500px;
    overflow: auto;
  }

  .Polaris-ResourceList-Item__Container {
    padding: 1.2rem 0;
    border-bottom: 1px solid #c4cdd5;
  }

  .Polaris-ResourceList__ItemWrapper + .Polaris-ResourceList__ItemWrapper {
    border: none;
  }

  @media only screen and (max-width: 1100px) {
    padding: 10px;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  position: relative;
  // background-color: #eaeaea;
  @media only screen and (max-width: 1100px) {
    padding: 20px 10px 10px 10px;
    margin-bottom: 80px;
  }
`;

export const PageHeaderCentered = styled.div`
  display: flex;
  flex-direction: row;
  padding: 50px 0;
  align-items: center;
  justify-content: center;
  position: relative;
  // background-color: #eaeaea;
  @media only screen and (max-width: 1100px) {
    padding: 20px 10px 10px 10px;
    margin-bottom: 80px;
  }
`;

export const PageHeaderCongratsCentered = styled.div`
  display: flex;
  flex-direction: row;
  padding: 60px 0 30px 0;
  align-items: center;
  justify-content: center;
  position: relative;
  // background-color: #eaeaea;
  @media only screen and (max-width: 1100px) {
    padding: 20px 10px 10px 10px;
    margin-bottom: 80px;
  }
`;

export const HeaderTitle = styled.h1`
  color: #0c3450;
  font-size: 71px;
  line-height: 71px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
`;

export const ExtensionStatus = styled.div`
  margin: 20px 0;
`;

export const HeaderTitleCentered = styled.h1`
  color: #000;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (max-width: 1100px) {
    margin-left: 20px;
  }
`;

export const CardTitle = styled.div`
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 40px;
  width: 470px;
  color: #123d55;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const CardTitleBig = styled.div`
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 40px;
  width: 470px;
  color: #123d55;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const ButtonSubTitle = styled.div`
  font-style: italic;
  margin-top: 10px;
`;

export const CardTitleSmall = styled.div`
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
`;

export const CardSubTitle = styled.div`
  font-size: 14px;
  margin-bottom: 40px;
  width: 600px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const CentredSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 50px 50px 50px;
  width: 100%;
  // align-items: center;
  // text-align: center;
  // justify-content: center;
`;

export const CardSection = styled.div`
  position: relative;
  min-height: 488px;
  width: 800px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const SideWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 23%;
  background-color: #3b3b3b;
  padding: 0 0 0 35px;
`;

export const StepsWrapper = styled.div`
  display: flex;
  position: relative;
`;

export const OnboardingWrapper = styled.div`
  display: flex;
  position: relative;
  width: 85%;
  background-color: #ffffff;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const OnboardingWrapperPricing = styled.div`
  display: flex;
  position: relative;
  width: 93%;
  background-color: #ffffff;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const FixHeightCardSection = styled.div`
  position: relative;
  height: 488px;
  width: 800px;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const CardSectionTitle = styled.h1`
  color: #123d55;
  font-size: 22px;
  padding: 20px 0;
  font-family: 'Montserrat', sans-serif;
`;

export const CardButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border: none;
  padding: 15px 50px;
  color: #ffffff;
  font-size: 15px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  background-color: ${(props) => (props.disabled ? '#d7d7d7' : '#fc5085')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  &:hover {
    background-color: ${(props) => (props.disabled ? 'inherit' : '#e13264')};
    transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
  }

  &:focus {
    outline: none;
  }
`;

export const SkipUpgradeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  padding: 15px 50px;
  color: #000000;
  font-size: 15px;
  cursor: pointer;
  font-family: Montserrat, sans-serif;
  font-weight: 600;

  &:focus {
    outline: none;
  }
`;

export const MoneyBack = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  top: -75px;
  right: -60px;
  background-image: url(${moneyBack});
  background-size: cover;
  padding: 30px 10px;
`;

export const AvatarWrapper = styled.div`
  margin-bottom: 10px;
`;

export const BadgeWrapper = styled.div`
  margin-bottom: 30px;
`;

export const CardInputDiscount = styled.div`
  margin: 20px 0;
  width: 300px;
`;

export const CardInputSetting = styled.div`
  margin: 20px 0;
  width: 100%;
`;

export const CardSettingsWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

export const CardSettingsInputs = styled.div`
  flex: 1 0 100%;
  max-width: 450px;
  margin: 0 30px 0 0;
`;

export const CardSettingsText = styled.div`
  padding-top: 20px;
  flex: 1 1 auto;
  text-align: right;
`;

export const SettingsLinkWrapper = styled.div`
  display: block;
  margin-top: 20px;
`;

export const SettingsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const SkipButton = styled.div`
  cursor: pointer;
  margin-left: 40px;
  display: flex;
  font-family: 'Montserrat', sans-serif;
`;

export const SkipIcon = styled.div`
  margin-left: 10px;
`;
