import React, { Component } from 'react';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  className?: string;
  isChecked?: boolean;
}

export default class CheckIcon extends Component<Props> {
  static defaultProps: Partial<Props> = {
    width: '20px',
    height: '20px',
    color: '#637381',
    isChecked: false,
  };

  render(): React.ReactNode {
    const { width, height, className, color, isChecked } = this.props;
    return (
      <div className={className}>
        <svg
          height={height}
          version="1.1"
          viewBox="0 0 20 20"
          width={width}
          xmlns="http://www.w3.org/2000/svg"
        >
          <title />
          <desc />
          <defs />
          <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
            <g
              fill={isChecked ? '#50b83c' : color}
              id="Core"
              transform="translate(-128.000000, -86.000000)"
            >
              <g id="check-circle-outline" transform="translate(128.000000, 86.000000)">
                {isChecked ? (
                  <path
                    d="M5.9,8.1 L4.5,9.5 L9,14 L19,4 L17.6,2.6 L9,11.2 L5.9,8.1 L5.9,8.1 Z M18,10 C18,14.4 14.4,18 10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C10.8,2 11.5,2.1 12.2,2.3 L13.8,0.7 C12.6,0.3 11.3,0 10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 L18,10 L18,10 Z"
                    id="Shape"
                  />
                ) : (
                  <path
                    id="Shape"
                    d="m10,0c-5.5,0 -10,4.5 -10,10c0,5.5 4.5,10 10,10c5.5,0 10,-4.5 10,-10c0,-5.5 -4.5,-10 -10,-10l0,0zm0,18c-4.4,0 -8,-3.6 -8,-8c0,-4.4 3.6,-8 8,-8c4.4,0 8,3.6 8,8c0,4.4 -3.6,8 -8,8l0,0z"
                  />
                )}
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}
