import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from '@shopify/polaris';
import { IRootStore } from '../../typings/stores.typing';
import { withRouter } from 'react-router';
import {
  ModalSubscriptionWrapper,
  SubscriptionButton,
  SubscriptionTextContainer,
} from './ModalSubscription.styled';

interface Props {
  location?: Location;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
// @ts-ignore
@withRouter
@observer
export class ModalSubscription extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  handleClose = () => {
    const { closeModal } = this.injected.rootStore.billingStore;

    closeModal();
  };

  upgradePlan = async () => {
    const { getRedirectURL } = this.injected.rootStore.billingStore;

    this.handleClose();

    try {
      const url = await getRedirectURL();
      if (url) {
        window.location.href = url;
      }
    } catch (e) {
      const error = await e.json();
      error.status = e.status;
      this.injected.rootStore.errorStore.setError(error);
    }
  };

  render() {
    const { modal } = this.injected.rootStore.billingStore;

    return (
      <Modal open={modal.isShow} onClose={this.handleClose} title="Upgrade plan">
        <Modal.Section>
          <ModalSubscriptionWrapper>
            <SubscriptionTextContainer>
              Please upgrade to edit this field.
            </SubscriptionTextContainer>
            <SubscriptionButton onClick={this.upgradePlan}>Upgrade</SubscriptionButton>
          </ModalSubscriptionWrapper>
        </Modal.Section>
      </Modal>
    );
  }
}

export default ModalSubscription;
