import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 50px 50px 50px;
  width: 100%;
`;

export const HeaderSubTitle = styled.div`
  font-size: 19px;
  margin-top: 30px;
  font-weight: 300;
  font-family: 'Montserrat', sans-serif;
  color: #0c3450;
`;

export const HeaderTitle = styled.h1`
  color: #0c3450;
  font-size: 48px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  @media only screen and (max-width: 1100px) {
    margin-left: 20px;
  }
`;

export const ContentCTASection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  a {
    font-size: 18px;
    background-color: #f65185;
    color: #ffffff;
    padding: 10px 35px;
    font-weight: 600;
    border-radius: 20px;
    cursor: pointer;
  }
`;

export const PageContent = styled.div`
  justify-items: center;
  justify-content: center;
  height: 100vh;
  align-items: center;
  display: flex;
`;

export const OnboardingWrapper = styled.div`
  display: flex;
  position: relative;
  width: 85%;
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  position: relative;
  // background-color: #eaeaea;
  @media only screen and (max-width: 1100px) {
    padding: 20px 10px 10px 10px;
    margin-bottom: 80px;
  }
`;
