import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../typings/stores.typing';
import Logo from '../../shared/Logo/Logo';
import {
  SidebarBillingGroup,
  SidebarBottomGroup,
  SidebarGroupWithBorder,
  SidebarItem,
  SidebarItemLink,
  SidebarItemNotification,
  SidebarItemTitle,
  SidebarItemWithSpacing,
  SidebarLogo,
  SidebarMenu,
  SidebarSettings,
  SidebarSettingsAccount,
  SidebarSettingsIcon,
  SidebarSettingsText,
  SidebarSubItem,
  SidebarSubItemWrapper,
  SidebarWrapper,
  TitleSidebarLogo,
  SideMenuAttribution,
  AttributionTitle,
  AttributionSubTitle,
  AttributionProgressBar,
  SidebarItemDisabled,
  TooltipMessage,
  AttributionProgressBarWrapper,
} from './Sidebar.styled';
import { Avatar, Icon, ProgressBar, Tooltip, Button } from '@shopify/polaris';
import { withRouter } from 'react-router-dom';
import { DashboardUpgradeButton } from '../Content/DashboardHome/Dashboard.styled';
import { IRoute } from '../../../typings/elements.typing';
import {
  HomeMajor,
  NoteMajor,
  AlertMinor,
  CircleInformationMajor,
  QuestionMarkMajor,
  SettingsMinor,
} from '@shopify/polaris-icons';
import PlanLabel from '../../shared/PlanLabel/PlanLabel';
import history from '../../../utils/history';
import * as _ from 'lodash';
import { useTour } from '@reactour/tour';

interface Props {
  location?: Location;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
// @ts-ignore
@withRouter
@observer
class Sidebar extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  async componentDidMount() {
    const { location } = this.props;
    const { updateRoutes } = this.injected.rootStore.routeStore;

    if (location) {
      await updateRoutes(location.pathname);
    }
  }

  onToggleItemClick = () => {
    const {
      setSidebar,
      sidebar: { isCouponPopupExtend },
    } = this.injected.rootStore.settingsStore;
    setSidebar({ isCouponPopupExtend: !isCouponPopupExtend });
  };

  logout = () => {
    const { authStore } = this.injected.rootStore;
    authStore.logout();
  };

  // upgradePlan = async () => {
  //   const { getRedirectURL } = this.injected.rootStore.billingStore;
  //   try {
  //     const url = await getRedirectURL();
  //     if (url) {
  //       window.location.href = url;
  //     }
  //   } catch (e) {
  //     const error = await e.json();
  //     error.status = e.status;
  //     this.injected.rootStore.errorStore.setError(error);
  //   }
  // };

  upgradePlan = async () => {
    history.push('/settings?plan');
  };

  checkLimits = (hasLimitations: boolean | undefined) => {
    if (!hasLimitations) {
      return false;
    }
    const { settings } = this.injected.rootStore.settingsStore;
    const plan = settings.payment.name;
    const plan_status = settings.payment.status;
    const store_country_code = settings.shopData.country_code;

    if (!['NL', 'US', 'CA'].includes(store_country_code)) {
      return '2-way SMS campaigns are available for merchants based in United States, Canada, Netherlands';
    }

    if (plan === 'Free Post Purchase' || plan_status === 'pending') {
      return (
        <TooltipMessage>
          <span>Only available on paid plans. Please upgrade</span>
        </TooltipMessage>
      );
    }

    return false;
  };

  render() {
    const { settings } = this.injected.rootStore.settingsStore;
    let shopProfile = settings.profile;
    const { extension_attribution } = settings;
    const orders_amount = _.get(extension_attribution, 'month_orders', 0);
    const limits = {
      'Free Post Purchase': 50,
      'Pro Post-Purchase': 100,
      'VIP Post-Purchase': 200,
      'Star Post-Purchase': 500,
      'Free Cart Upsell': 50,
      'Pro Cart Upsell': 100,
      'VIP Cart Upsell': 200,
      'Star Cart Upsell': 500,
    };
    const plan_name = _.get(settings, 'payment.name');
    const orders_limit = _.get(limits, plan_name, 0);
    let orders_remaining = orders_limit - orders_amount;
    const limit_threshold = orders_limit - orders_limit / 10;
    let orders_used_progress = 100 - (orders_amount / orders_limit) * 100;
    orders_used_progress = orders_used_progress || 0;

    if (orders_remaining < 0) {
      orders_remaining = 0;
    }
    const {
      analytics: { contact },
    } = this.injected.rootStore.settingsStore;

    const {
      settings: { isSubscribed, isFetched },
    } = this.injected.rootStore.billingStore;

    const { routes } = this.injected.rootStore.routeStore;
    console.log('settings', shopProfile.foreverFreeUser);
    return (
      <SidebarWrapper>
        <SidebarLogo>
          <Logo width="50px" height="28px" />
          <TitleSidebarLogo>Maxwell</TitleSidebarLogo>
        </SidebarLogo>
        <SidebarMenu>
          <div>
            <SidebarGroupWithBorder>
              <SidebarItem to="/home">
                <Icon color="base" source={HomeMajor} />
                <SidebarItemTitle>Dashboard</SidebarItemTitle>
              </SidebarItem>
              {routes.map((route: IRoute) => {
                const { id, title, icon, color, path, hasLimitations } = route;
                return (
                  <React.Fragment key={`item-${id}`}>
                    {this.checkLimits(hasLimitations) ? (
                      <Tooltip key={`tooltip-${id}`} content={this.checkLimits(hasLimitations)}>
                        <SidebarItemDisabled>
                          <Icon color={color ? color : 'base'} source={icon} />
                          <SidebarItemTitle>{title}</SidebarItemTitle>
                        </SidebarItemDisabled>
                      </Tooltip>
                    ) : (
                      // TODO create recursive function
                      <SidebarItem key={id} to={path}>
                        <Icon color={color ? color : 'base'} source={icon} />
                        <SidebarItemTitle>{title}</SidebarItemTitle>
                        {route.childs && route.isOpen && (
                          <SidebarSubItemWrapper>
                            {route.childs.map((subRoute: IRoute) => {
                              return (
                                <SidebarSubItem key={subRoute.id} to={subRoute.path}>
                                  <Icon color={subRoute.color || 'base'} source={subRoute.icon} />
                                  <SidebarItemTitle>{subRoute.title}</SidebarItemTitle>
                                  {subRoute.childs && subRoute.isOpen && (
                                    <SidebarSubItemWrapper>
                                      {subRoute.childs.map((subSubRoute: IRoute) => {
                                        return (
                                          <SidebarSubItem
                                            key={subSubRoute.id}
                                            to={subSubRoute.path}
                                          >
                                            <Icon
                                              color={subSubRoute.color || 'base'}
                                              source={subSubRoute.icon}
                                            />
                                            <SidebarItemTitle>{subSubRoute.title}</SidebarItemTitle>
                                          </SidebarSubItem>
                                        );
                                      })}
                                    </SidebarSubItemWrapper>
                                  )}
                                </SidebarSubItem>
                              );
                            })}
                          </SidebarSubItemWrapper>
                        )}
                      </SidebarItem>
                    )}
                  </React.Fragment>
                );
              })}
              <br />
              <SidebarItem to="/settings">
                <Icon color="base" source={SettingsMinor} />
                <SidebarItemTitle>Settings</SidebarItemTitle>
              </SidebarItem>
              <TakeATour />
            </SidebarGroupWithBorder>
            {!shopProfile.foreverFreeUser && (
              <>
                <SideMenuAttribution>
                  <AttributionTitle>Monthly Orders</AttributionTitle>
                  <AttributionSubTitle>{orders_remaining} orders remaining</AttributionSubTitle>
                  <AttributionProgressBarWrapper progress={orders_used_progress}>
                    <AttributionProgressBar progress={orders_used_progress} />
                  </AttributionProgressBarWrapper>
                  <DashboardUpgradeButton onClick={this.upgradePlan}>
                    Upgrade
                  </DashboardUpgradeButton>
                </SideMenuAttribution>
                {!isSubscribed && isFetched && (
                  <SidebarBillingGroup>
                    <DashboardUpgradeButton onClick={this.upgradePlan}>
                      Upgrade
                    </DashboardUpgradeButton>
                  </SidebarBillingGroup>
                )}
              </>
            )}
          </div>
        </SidebarMenu>
        <SidebarSettings>
          <SidebarSettingsAccount>
            <Avatar
              source={shopProfile.picture}
              customer
              name={shopProfile.firstName || settings.shopData.name}
            />
            <SidebarSettingsText>
              {shopProfile.firstName || (settings.shopData || {}).shop_owner}
              <br />
              {!shopProfile.foreverFreeUser && <PlanLabel />}
            </SidebarSettingsText>
          </SidebarSettingsAccount>
          <SidebarSettingsIcon onClick={this.logout}>
            <Icon color="base" source="logOut" />
          </SidebarSettingsIcon>
        </SidebarSettings>
      </SidebarWrapper>
    );
  }
}

const TakeATour = () => {
  const { setIsOpen } = useTour();

  return (
    <>
      <SidebarBillingGroup>
        <DashboardUpgradeButton
          onClick={() => {
            if (location.pathname === '/home') {
              setIsOpen(true);
              return;
            }

            localStorage.removeItem('skipTour');
            history.push('/home');
          }}
        >
          Start Tour
        </DashboardUpgradeButton>
      </SidebarBillingGroup>
    </>
  );
};

export default Sidebar;
