//@ts-nocheck
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import InputText from '../../../shared/InputText/InputText';
import { Card } from '@shopify/polaris';
import { ContentCardButton } from '../Content.styled';
import { IToken } from '../../../../typings/elements.typing';
import jwtDecode from 'jwt-decode';
import TagArea from '../OrderConfirmation/TagArea';

interface Props {
  text_message: string;
  text_message2: string;
  text_message3: string;
  submitted: boolean;

  changeField({ field, value }: { [key: string]: string }): void;

  handleSubmit(): void;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class AutomatedReorderForm extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  errorText: string = 'This field is required';

  handleChange = (field: string) => (obj: { [key: string]: string }) => this.props.changeField(obj);

  handleSubmit = () => {
    this.props.handleSubmit();
  };

  getDomain = () => {
    const token: string = localStorage.getItem('accessToken') || '';
    const parseToken: IToken = token ? jwtDecode(token) : {};

    const shopDomain = parseToken.shopDomain || '';

    return shopDomain;
  };

  render() {
    const { text_message, text_message2, text_message3, submitted } = this.props;

    return (
      <Card.Section>
        <label>Text Message #1</label>
        <TagArea
          text_message={text_message}
          field={`text_message`}
          changeField={this.handleChange('text_message')}
          tags={['order.customer.first_name', 'order.shopOwner', 'order.shopName']}
        />
        <label>Text Message #2</label>
        <TagArea
          text_message={text_message2}
          field={`text_message2`}
          changeField={this.handleChange('text_message2')}
          tags={['order_items', 'address', 'total']}
        />
        <label>Text Message #3</label>
        <TagArea
          text_message={text_message3}
          field={`text_message3`}
          changeField={this.handleChange('text_message3')}
          tags={['checkout_url']}
        />

        <ContentCardButton onClick={this.handleSubmit}>Update</ContentCardButton>
      </Card.Section>
    );
  }
}

export default AutomatedReorderForm;
