import React, { Component } from 'react';
import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
  color?: string;
  className?: string;

  onClick?(e?: React.SyntheticEvent<EventTarget>): void;
}

const PencilWrapper = styled.div`
  cursor: pointer;
`;

export default class PencilIcon extends Component<Props> {
  static defaultProps: Partial<Props> = {
    width: '20px',
    height: '20px',
    color: '#637381',
  };

  render(): React.ReactNode {
    const { width, height, className, color, onClick } = this.props;
    return (
      <PencilWrapper onClick={onClick} className={className}>
        <svg
          height={height}
          id="pencil"
          version="1.1"
          viewBox="0 0 77.999 78"
          width={width}
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              fill={color}
              d="M71.807,6.191c-7.215-7.216-12.629-6.133-12.629-6.133l-25.26,25.259L5.049,54.185L0,78l23.812-5.051l28.869-28.869   l25.26-25.257C77.941,18.824,79.025,13.409,71.807,6.191z M22.395,70.086l-8.117,1.748c-0.785-1.467-1.727-2.932-3.455-4.659   c-1.727-1.727-3.193-2.669-4.658-3.456l1.75-8.116l2.346-2.348c0,0,4.418,0.088,9.404,5.078c4.988,4.987,5.078,9.407,5.078,9.407   L22.395,70.086z"
            />
          </g>
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
          <g />
        </svg>
      </PencilWrapper>
    );
  }
}
