import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import {
  ContentBody,
  ContentCard,
  ContentHeader,
  ContentTitle,
  ContentCardButton,
} from '../Content.styled';
import {
  Card,
  Stack,
  RadioButton,
  TextField,
  Heading,
  Modal,
  Button,
  TextContainer,
  ResourceList,
  ResourceItem,
  TextStyle,
  Avatar,
  Filters,
} from '@shopify/polaris';
import { template } from 'lodash';
import { API_APP, NEW_API_APP } from '../../../../config';
import { http } from '../../../../utils/http';
import * as _ from 'lodash';

interface Props {}

interface State {
  isOpened: boolean;
  selectedProducts: [];
  ordersOption: string;
  ordersAmountOption: string;
  ordersAmountValueMin: string;
  ordersAmountValueMax: string;
  filter: string;
  products: any;
  [k: string]: string | boolean | any;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class PostPurchaseUpsellAdvanced extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const { postPurchaseExtension } = settings;
    this.state = {
      isOpened: false,
      selectedProducts: postPurchaseExtension.selectedProducts || [],
      ordersOption: postPurchaseExtension.ordersOption,
      ordersAmountOption: postPurchaseExtension.ordersAmountOption,
      ordersAmountValueMin: postPurchaseExtension.ordersAmountValueMin,
      ordersAmountValueMax: postPurchaseExtension.ordersAmountValueMax,
      filteredItems: [],
      products: [],
      filter: '',
    };
  }

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const {
      ordersOption,
      ordersAmountOption,
      ordersAmountValueMin,
      ordersAmountValueMax,
    } = this.state;

    try {
      closeToast();
      await updateSettings({
        type: 'postPurchaseExtension',
        data: {
          ordersOption,
          ordersAmountOption,
          ordersAmountValueMin,
          ordersAmountValueMax,
        },
      });
      openToast('Settings was successfully updated');
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  fetchProducts = async () => {
    try {
      let response: Response = await http(`${API_APP}/get-products`, 'GET');
      response = await response.json();
      let products = _.get(response, 'products', []);
      products = _.map(products, (product) => {
        return {
          label: product.title,
          id: product.id.toString(),
          image: product.image.src || '',
        };
      });
      this.setState({
        filteredProducts: products,
        products,
      });
    } catch (err) {
      console.log('[error]', err);
    }
  };

  handleChangeProducts = (event: any, value: string) => {
    this.setState({
      ordersOption: value,
    });
  };

  handleChangeAmount = (event: any, value: string) => {
    this.setState({
      ordersAmountOption: value,
    });
  };

  handleChangeText = (value: string, key: string) => {
    this.setState({
      [key]: value,
    });
  };

  toggleModal = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  saveAndToggleModal = async () => {
    const { updateSettings } = this.injected.rootStore.settingsStore;
    const { selectedProducts } = this.state;

    try {
      this.setState({
        isOpened: !this.state.isOpened,
      });
      await updateSettings({
        type: 'postPurchaseExtension',
        data: {
          selectedProducts: selectedProducts,
        },
      });
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  componentDidMount() {
    this.fetchProducts();
  }

  render() {
    const { active, filter } = this.state;
    const { updateSettings, settings } = this.injected.rootStore.settingsStore;
    const {
      ordersOption,
      ordersAmountOption,
      ordersAmountValueMin,
      ordersAmountValueMax,
      isOpened,
      products,
      filteredProducts,
      selectedProducts,
      filteredSelectedProducts,
    } = this.state;
    const selectedProductsCount = (selectedProducts.length || 0).toString();
    return (
      <Fragment>
        <ContentHeader>
          <ContentTitle>Display Upsell Offer When</ContentTitle>
        </ContentHeader>
        <ContentBody>
          <ContentCard>
            <Card>
              <Card.Section title={`Any Order or Specific Order is Placed`}>
                <Stack vertical spacing={'extraTight'}>
                  <Stack.Item>
                    <RadioButton
                      label="Show for all orders"
                      helpText=""
                      checked={ordersOption === 'all_orders'}
                      id="all_orders"
                      name="filter_products"
                      onChange={this.handleChangeProducts}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Stack alignment={'center'}>
                      <Stack.Item fill>
                        <RadioButton
                          label="Show for specific orders"
                          helpText=""
                          id="for_specific"
                          name="filter_products"
                          checked={ordersOption === 'for_specific'}
                          onChange={this.handleChangeProducts}
                        />
                      </Stack.Item>
                      {ordersOption === 'for_specific' && (
                        <Stack.Item>
                          <Button plain onClick={this.toggleModal}>
                            Select Products ({selectedProductsCount})
                          </Button>
                        </Stack.Item>
                      )}
                    </Stack>
                  </Stack.Item>
                </Stack>
              </Card.Section>
              <Card.Section
                title={`Show for any Quantity of Items in Cart or Specific Quantity of Items`}
              >
                <Stack vertical spacing={'extraTight'}>
                  <Stack.Item>
                    <RadioButton
                      label="Show for any order quantity"
                      helpText=""
                      id="for_all_order_amounts"
                      checked={ordersAmountOption === 'for_all_order_amounts'}
                      name="filter_amount"
                      onChange={this.handleChangeAmount}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <RadioButton
                      label="Show for specific order quantity"
                      helpText=""
                      id="for_specific_order_amounts"
                      name="filter_amount"
                      checked={ordersAmountOption === 'for_specific_order_amounts'}
                      onChange={this.handleChangeAmount}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <Stack wrap={false} alignment="center">
                      <Stack.Item>Between</Stack.Item>
                      <Stack.Item fill>
                        <TextField
                          disabled={ordersAmountOption === 'for_all_order_amounts'}
                          label=""
                          autoComplete={''}
                          labelHidden
                          maxLength={500}
                          type="number"
                          id={'min'}
                          value={ordersAmountValueMin}
                          onChange={(value) => this.handleChangeText(value, 'ordersAmountValueMin')}
                        />
                      </Stack.Item>
                      <Stack.Item>and</Stack.Item>
                      <Stack.Item fill>
                        <TextField
                          disabled={ordersAmountOption === 'for_all_order_amounts'}
                          label=""
                          id={'max'}
                          autoComplete={''}
                          labelHidden
                          maxLength={500}
                          type="number"
                          value={ordersAmountValueMax}
                          onChange={(value) => this.handleChangeText(value, 'ordersAmountValueMax')}
                        />
                      </Stack.Item>
                    </Stack>
                  </Stack.Item>
                </Stack>
              </Card.Section>
            </Card>
            <ContentCardButton onClick={this.handleSubmit}>Update</ContentCardButton>
          </ContentCard>
        </ContentBody>
        <div style={{ height: '800px' }}>
          <Modal
            open={isOpened}
            onClose={this.toggleModal}
            title="Select Products from your Catalogue"
            primaryAction={{
              content: 'Save',
              onAction: this.saveAndToggleModal,
            }}
          >
            <Modal.Section>
              <TextContainer>
                <p>
                  Only when one or more products are included in the order will the upsell offer be
                  shown
                </p>
              </TextContainer>
              <ResourceList
                resourceName={this.resourceName}
                items={filteredProducts}
                renderItem={this.renderItem}
                selectedItems={selectedProducts}
                onSelectionChange={this.setSelectedItems}
                filterControl={this.filterControl}
                selectable
              />
            </Modal.Section>
          </Modal>
        </div>
      </Fragment>
    );
  }

  filters = [];

  filterControl = (
    <Filters
      onQueryChange={console.log}
      onClearAll={this.onClearAll}
      onQueryClear={console.log}
      hideQueryField={true}
      filters={this.filters}
    />
  );

  setSelectedItems = (items: any) => {
    this.setState({
      selectedProducts: items,
    });
  };

  onClearAll() {}

  onQueryClear = () => {
    this.setState({
      filteredProducts: this.state.products,
      filter: '',
    });
  };

  resourceName = {
    singular: 'product',
    plural: 'products',
  };

  renderItem(item: any) {
    const { id, label, url, name, image, location } = item;
    const media = <Avatar customer size="large" source={image} name={name} />;
    return (
      <ResourceItem
        id={id}
        url={url}
        media={media}
        accessibilityLabel={`View details for ${label}`}
      >
        <h3>
          <TextStyle variation="strong">{label}</TextStyle>
        </h3>
        <div>{location}</div>
      </ResourceItem>
    );
  }
}

export default PostPurchaseUpsellAdvanced;
