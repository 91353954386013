import styled from 'styled-components';

interface ISubscriptionTableCol {
  width?: string;
  alignCenter?: boolean;
  background?: string;
  iconColor?: string;
}

interface ISubscriptionTableRow {
  removeBottomBorder?: boolean;
}

export const SubscriptionTable = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  font-size: 12px;
`;

export const SubscriptionTableRow = styled.div<ISubscriptionTableRow>`
  display: flex;
  flex-direction: row;
  ${(p) => !p.removeBottomBorder && 'border-bottom: 0.1rem solid #dfe3e8;'};
`;

export const SubscriptionTableHeadRow = styled(SubscriptionTableRow)`
  font-weight: 600;
  font-size: 14px;
`;

export const SubscriptionTableCol = styled.div<ISubscriptionTableCol>`
  display: flex;
  padding: 10px;
  width: ${(p) => p.width || '50%'};
  ${(p) => p.alignCenter && `justify-content:center; align-items: center;`};
  background: ${(p) => p.background || '#ffffff'};

  .Polaris-Choice__Control {
    margin-right: 0;
  }

  .Polaris-Icon svg {
    fill: #637381;
    max-width: 90%;
  }

  .Polaris-Checkbox {
    .Polaris-Checkbox__Input:disabled + .Polaris-Checkbox__Backdrop {
      ${(p) => p.iconColor && `background: ${p.iconColor}`};
    }
    .Polaris-Checkbox__Input:disabled ~ .Polaris-Checkbox__Icon svg {
      ${(p) => p.iconColor && `fill: ${p.iconColor}`};
    }
  }
`;

export const SubscriptionSettingsCurrentPlan = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: grey;
  background-color: rgba(63, 63, 68, 0.15);
  padding: 10px 20px;
  border-radius: 40px;
  border-style: none;
  margin: 10px 15px 0;
  font-size: 14px;
  font-family: CircularStd, sans-serif;
  font-weight: 600;
  cursor: pointer;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const SubscriptionSettingsBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: #fc5085;
  padding: 10px 20px;
  border-radius: 40px;
  border-style: none;
  margin: 10px 15px 0;
  font-size: 14px;
  font-family: CircularStd, sans-serif;
  font-weight: 600;
`;
