import React, { Fragment, Component } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../typings/stores.typing';
import { Icon } from '@shopify/polaris';
import {
  SubscriptionSettingsBtn,
  SubscriptionSettingsCurrentPlan,
  SubscriptionTable,
  SubscriptionTableCol,
  SubscriptionTableHeadRow,
  SubscriptionTableRow,
} from './SubscriptionSettings.styled';
import { MobileAcceptMajor } from '@shopify/polaris-icons';
import _ from 'lodash';

interface IPlanSequence {
  [key: string]: number;
}

interface Props {}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class SubscriptionSettings extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  plans_prices: any = {
    us: {
      pay_as_you_go: {
        price: 0,
        per_sms: 0,
        per_order: 0.18,
      },
      growth: {
        price: 7.99,
        per_sms: 0.015,
        per_order: 0.08,
      },
      enterprise: {
        price: 14.99,
        per_sms: 0.0145,
        per_order: 0.06,
      },
      star: {
        price: 29.99,
        per_sms: 0.0129,
        per_order: 0.03,
      },
    },
    nl: {
      pay_as_you_go: {
        price: 0,
        per_sms: 0,
        per_order: 0.18,
      },
      growth: {
        price: 7.99,
        per_sms: 0.015,
        per_order: 0.08,
      },
      enterprise: {
        price: 14.99,
        per_sms: 0.0145,
        per_order: 0.06,
      },
      star: {
        price: 29.99,
        per_sms: 0.0129,
        per_order: 0.03,
      },
    },
    ca: {
      pay_as_you_go: {
        price: 0,
        per_sms: 0,
        per_order: 0.18,
      },
      growth: {
        price: 7.99,
        per_sms: 0.01,
        per_order: 0.08,
      },
      enterprise: {
        price: 14.99,
        per_sms: 0.009,
        per_order: 0.06,
      },
      star: {
        price: 29.99,
        per_sms: 0.008,
        per_order: 0.03,
      },
    },
  };

  selectPlan = async (plan: string) => {
    const { settings, updateSettings, getSettings } = this.injected.rootStore.settingsStore;
    const { getRedirectURL } = this.injected.rootStore.billingStore;
    const redirectUrl = await getRedirectURL(plan, 'subscribe/confirm/settings');

    window.location.href = redirectUrl;
  };

  planSequence: IPlanSequence = {
    'Free Post Purchase': 1,
    'Pro Post-Purchase': 2,
    'VIP Post-Purchase': 3,
    'Star Post-Purchase': 4,
    'Free Cart Upsell': 1,
    'Pro Cart Upsell': 2,
    'VIP Cart Upsell': 3,
    'Star Cart Upsell': 4,
  };

  render() {
    const {
      settings: { payment, profile },
      settings,
    } = this.injected.rootStore.settingsStore;
    let country_code = _.get(settings, 'shopData.country_code', 'us');
    country_code = _.lowerCase(country_code);
    if (!['us', 'ca', 'nl'].includes(country_code)) {
      country_code = 'us';
    }
    const planSequenceNumber = this.planSequence[payment.name];
    const rows = [
      {
        title: 'Price',
        pay_as_you_go: 'Free',
        growth: this.plans_prices[country_code].growth.price,
        enterprise: this.plans_prices[country_code].enterprise.price,
        star: this.plans_prices[country_code].star.price,
      },
      {
        title: 'Monthly store orders limit',
        pay_as_you_go: '50',
        growth: '100',
        enterprise: '200',
        star: '500',
      },
      {
        title: 'Price per additional order',
        pay_as_you_go: this.plans_prices[country_code].pay_as_you_go.per_order,
        growth: this.plans_prices[country_code].growth.per_order,
        enterprise: this.plans_prices[country_code].enterprise.per_order,
        star: this.plans_prices[country_code].star.per_order,
      },
      {
        title: 'In cart upsell & cross sell',
        pay_as_you_go: true,
        growth: true,
        enterprise: true,
        star: true,
      },
      {
        title: 'Post-purchase upsell',
        pay_as_you_go: true,
        growth: true,
        enterprise: true,
        star: true,
      },
      {
        title: 'A/B testing',
        pay_as_you_go: true,
        growth: true,
        enterprise: true,
        star: true,
      },
      {
        title: 'ROI analytics',
        pay_as_you_go: true,
        growth: true,
        enterprise: true,
        star: true,
      },
      {
        title: 'Chat Support',
        pay_as_you_go: true,
        growth: true,
        enterprise: true,
        star: true,
      },
    ];
    return (
      <Fragment>
        <SubscriptionTable>
          <SubscriptionTableHeadRow>
            <SubscriptionTableCol width="31%" />
            <SubscriptionTableCol width="23%" alignCenter>
              Free Cart Upsell
            </SubscriptionTableCol>
            <SubscriptionTableCol width="23%" alignCenter>
              Pro Cart Upsell
            </SubscriptionTableCol>
            <SubscriptionTableCol width="23%" alignCenter>
              VIP Cart Upsell
            </SubscriptionTableCol>
            <SubscriptionTableCol width="23%" alignCenter>
              Star Cart Upsell
            </SubscriptionTableCol>
          </SubscriptionTableHeadRow>
          {rows.map((row, index) => (
            <SubscriptionTableRow key={index}>
              <SubscriptionTableCol width="40%">{row.title}</SubscriptionTableCol>
              <SubscriptionTableCol
                background={
                  ['Free Post Purchase', 'Free Cart Upsell'].includes(payment.name)
                    ? 'rgba(63,63,68,0.05)'
                    : ''
                }
                width="30%"
                alignCenter
              >
                {typeof row.pay_as_you_go === 'boolean'
                  ? row.pay_as_you_go && <Icon source={MobileAcceptMajor} />
                  : row.pay_as_you_go}
              </SubscriptionTableCol>
              <SubscriptionTableCol
                background={
                  ['Pro Post-Purchase', 'Pro Cart Upsell'].includes(payment.name)
                    ? 'rgba(63,63,68,0.05)'
                    : ''
                }
                width="30%"
                alignCenter
              >
                {typeof row.growth === 'boolean'
                  ? row.growth && <Icon source={MobileAcceptMajor} />
                  : row.growth}
              </SubscriptionTableCol>
              <SubscriptionTableCol
                background={
                  ['VIP Post-Purchase', 'VIP Cart Upsell'].includes(payment.name)
                    ? 'rgba(63,63,68,0.05)'
                    : ''
                }
                width="30%"
                alignCenter
              >
                {typeof row.enterprise === 'boolean'
                  ? row.enterprise && <Icon source={MobileAcceptMajor} />
                  : row.enterprise}
              </SubscriptionTableCol>
              <SubscriptionTableCol
                background={
                  ['Star Post-Purchase', 'Star Cart Upsell'].includes(payment.name)
                    ? 'rgba(63,63,68,0.05)'
                    : ''
                }
                width="30%"
                alignCenter
              >
                {typeof row.star === 'boolean'
                  ? row.star && <Icon source={MobileAcceptMajor} />
                  : row.star}
              </SubscriptionTableCol>
            </SubscriptionTableRow>
          ))}
          <SubscriptionTableRow removeBottomBorder={true}>
            <SubscriptionTableCol width="31%" />
            <SubscriptionTableCol width="23%" alignCenter>
              {!['Free Post Purchase', 'Free Cart Upsell'].includes(payment.name) ? (
                <SubscriptionSettingsBtn onClick={() => this.selectPlan('pay_as_you_go')}>
                  {planSequenceNumber <= 1 || profile.foreverFreeUser ? 'Upgrade' : 'Downgrade'}
                </SubscriptionSettingsBtn>
              ) : (
                <SubscriptionSettingsCurrentPlan>Current</SubscriptionSettingsCurrentPlan>
              )}
            </SubscriptionTableCol>
            <SubscriptionTableCol width="23%" alignCenter>
              {!['Pro Post-Purchase', 'Pro Cart Upsell'].includes(payment.name) ? (
                <SubscriptionSettingsBtn onClick={() => this.selectPlan('growth')}>
                  {planSequenceNumber < 2 || profile.foreverFreeUser ? 'Upgrade' : 'Downgrade'}
                </SubscriptionSettingsBtn>
              ) : (
                <SubscriptionSettingsCurrentPlan>Current</SubscriptionSettingsCurrentPlan>
              )}
            </SubscriptionTableCol>
            <SubscriptionTableCol width="23%" alignCenter>
              {!['VIP Post-Purchase', 'VIP Cart Upsell'].includes(payment.name) ? (
                <SubscriptionSettingsBtn onClick={() => this.selectPlan('enterprise')}>
                  {planSequenceNumber < 3 || profile.foreverFreeUser ? 'Upgrade' : 'Downgrade'}
                </SubscriptionSettingsBtn>
              ) : (
                <SubscriptionSettingsCurrentPlan>Current</SubscriptionSettingsCurrentPlan>
              )}
            </SubscriptionTableCol>
            <SubscriptionTableCol width="23%" alignCenter>
              {!['Star Post-Purchase', 'Star Cart Upsell'].includes(payment.name) ? (
                <SubscriptionSettingsBtn onClick={() => this.selectPlan('star')}>
                  {planSequenceNumber < 4 || profile.foreverFreeUser ? 'Upgrade' : 'Downgrade'}
                </SubscriptionSettingsBtn>
              ) : (
                <SubscriptionSettingsCurrentPlan>Current</SubscriptionSettingsCurrentPlan>
              )}
            </SubscriptionTableCol>
          </SubscriptionTableRow>
        </SubscriptionTable>
      </Fragment>
    );
  }
}

export default SubscriptionSettings;
