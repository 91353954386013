import React, { Component } from 'react';

interface Props {
  width?: string;
  height?: string;
}

export default class Logo extends Component<Props> {
  static defaultProps: Partial<Props> = {
    width: '84px',
    height: '48px',
  };

  render(): React.ReactNode {
    const { width, height } = this.props;
    return (
      <svg width={width} height={height} viewBox="0 0 84 48" version="1.1">
        <title>Logo</title>
        <desc>Created with Sketch.</desc>
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Logo">
            <g transform="translate(-0.000000, 0.000000)">
              <path
                d="M36.2875636,47.3503636 L6.19265455,47.3503636 C3.67483636,47.3503636 1.64792727,46.2529091 0.630109091,44.3394545 C-0.384436364,42.4325455 -0.166254545,40.1525455 1.22792727,38.0852727 L24.8766545,3.00490909 C26.2064727,1.03145455 28.1853818,-0.0507272727 30.3202909,0.00163636364 C32.4573818,0.0681818182 34.3806545,1.29218182 35.5970182,3.36163636 L51.7195636,30.8078182 C52.7962909,32.6405455 52.1821091,34.9990909 50.3504727,36.0758182 C48.5188364,37.1514545 46.1592,36.5405455 45.0824727,34.7067273 L30.0453818,9.10963636 L9.45556364,39.6518182 L36.2875636,39.6529091 C38.4126545,39.6529091 40.1362909,41.3765455 40.1362909,43.5016364 C40.1362909,45.6267273 38.4126545,47.3503636 36.2875636,47.3503636"
                id="Fill-1"
                fill="#3FC1C9"
              />
              <path
                d="M77.8359273,47.3503636 L47.7399273,47.3503636 C45.6148364,47.3503636 43.8912,45.6267273 43.8912,43.5016364 C43.8912,41.3765455 45.6148364,39.6529091 47.7399273,39.6529091 L74.5719273,39.6529091 L53.9832,9.10963636 L38.9461091,34.7067273 C37.8693818,36.5383636 35.5119273,37.1514545 33.6781091,36.0758182 C31.8453818,34.9990909 31.2322909,32.6405455 32.3090182,30.8078182 L48.4304727,3.36272727 C49.6479273,1.29218182 51.5712,0.0681818182 53.7082909,0.00163636364 C55.8792,-0.0507272727 57.8210182,1.03254545 59.1519273,3.006 L82.8006545,38.0852727 C84.1937455,40.1514545 84.4119273,42.4314545 83.3995636,44.3383636 C82.3806545,46.2529091 80.3537455,47.3503636 77.8359273,47.3503636"
                id="Fill-4"
                fill="#FC5185"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
