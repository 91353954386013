import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../typings/stores.typing';
import { ContentBody, SettingsCard, ContentHeader, ContentTitle } from '../Content.styled';
import { Card, Tabs } from '@shopify/polaris';
import GeneralSettings from './GeneralSettings/GeneralSettings';
import SubscriptionSettings from './SubscriptionSettings/SubscriptionSettings';
import ProfileSettings from './ProfileSettings/ProfileSettings';
import { withRouter } from 'react-router';

interface Props {
  location?: Location;
}

interface State {
  selected: number;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
// @ts-ignore
@withRouter
@observer
class Settings extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  tabs = [
    {
      id: 'profile',
      content: 'Profile',
      panelID: 'profile-content',
      component: () => <ProfileSettings />,
    },
    {
      id: 'plan',
      content: 'Plan',
      panelID: 'plan-content',
      component: () => <SubscriptionSettings />,
    },
  ];

  constructor(props: Props) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  componentDidMount(): void {
    const { location } = this.props;
    if (location) {
      const urlParams = new URLSearchParams(location.search);
      console.log('urlParams', location.search);
      this.tabs.forEach((tab, i) => {
        if (urlParams.has(tab.id)) {
          this.setState({ selected: i });
        }
      });
    }
  }

  handleTabChange = (selectedTabIndex: number) => this.setState({ selected: selectedTabIndex });

  render() {
    const { selected } = this.state;

    return (
      <Fragment>
        <ContentHeader>
          <ContentTitle>Settings</ContentTitle>
        </ContentHeader>
        <ContentBody>
          <SettingsCard>
            <Card>
              <Tabs tabs={this.tabs} selected={selected} onSelect={this.handleTabChange}>
                {this.tabs[selected].component()}
              </Tabs>
            </Card>
          </SettingsCard>
        </ContentBody>
      </Fragment>
    );
  }
}

export default Settings;
