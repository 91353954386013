import React, { Component, Fragment } from 'react';
import { IAnalytic } from '../../../../../typings/elements.typing';
import { renderPrice } from '../../../../../utils/number';
import {
  AnalyticsBlock,
  AnalyticsNumber,
  AnalyticsSectionWrapper,
  AnalyticsSeparator,
  AnalyticsTitle,
} from './AnalyticsSection.styled';

interface Statistics {
  sentSMS: number;
  receivedSMS: number;
  attribution: IAttribution;
}

interface IAttribution {
  total_orders: number;
  total_sales: number;
}

interface Props {
  // data: IAnalytic[];
  sentSMS: number;
  receivedSMS: number;
  attribution: IAttribution;
  price_template: string;
}

class AnalyticsSection extends Component<Props> {
  render() {
    const { sentSMS, receivedSMS, attribution, price_template } = this.props;
    return (
      <AnalyticsSectionWrapper>
        <AnalyticsBlock>
          <AnalyticsNumber>0</AnalyticsNumber>
          <AnalyticsTitle>Subscribers</AnalyticsTitle>
        </AnalyticsBlock>
        <AnalyticsBlock>
          <AnalyticsNumber>{sentSMS}</AnalyticsNumber>
          <AnalyticsTitle>Outbound SMS</AnalyticsTitle>
        </AnalyticsBlock>
        <AnalyticsBlock>
          <AnalyticsNumber>{receivedSMS}</AnalyticsNumber>
          <AnalyticsTitle>Inbound SMS</AnalyticsTitle>
        </AnalyticsBlock>
        <AnalyticsBlock>
          <AnalyticsNumber>{attribution.total_orders}</AnalyticsNumber>
          <AnalyticsTitle>Reorders</AnalyticsTitle>
        </AnalyticsBlock>
        <AnalyticsBlock>
          <AnalyticsNumber>{renderPrice(attribution.total_sales, price_template)}</AnalyticsNumber>
          <AnalyticsTitle>Sales</AnalyticsTitle>
        </AnalyticsBlock>

        {/*{data.map((el: IAnalytic, index: number) => (*/}
        {/*  <Fragment key={el.id}>*/}
        {/*    <AnalyticsBlock>*/}
        {/*      <AnalyticsNumber>{el.value}</AnalyticsNumber>*/}
        {/*      <AnalyticsTitle>{el.title}</AnalyticsTitle>*/}
        {/*    </AnalyticsBlock>*/}
        {/*    {data.length - 1 !== index && <AnalyticsSeparator />}*/}
        {/*  </Fragment>*/}
        {/*))}*/}
      </AnalyticsSectionWrapper>
    );
  }
}

export default AnalyticsSection;
