import { action, computed, observable } from 'mobx';
import { runInAction } from 'mobx';
import { Channel } from '../../typings/elements.typing';
import RootStore from '../RootStore';
import { API_APP } from '../../config';
import { http } from '../../utils/http';

export default class OnBoardingStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable channels: Array<Channel> = [];

  @observable selectedChannel: Channel = {
    external_id: '',
    name: '',
    platform: '',
    picture: '',
  };

  @action.bound
  getChannelsList = async (): Promise<void> => {
    try {
      const response: Response = await http(`${API_APP}/channels`, 'GET');
      const { channels } = await response.json();

      runInAction(() => (this.channels = channels || []));
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @action.bound
  selectChannel = async (channel: Channel): Promise<void> => {
    try {
      await http(`${API_APP}/settings`, 'POST', {
        channelId: channel.external_id,
        onBoardingComplete: true,
      });

      runInAction(() => (this.selectedChannel = channel || []));
    } catch (e) {
      const error = await e.json();

      error.status = e.status;

      this.rootStore.errorStore.setError(error);
    }
  };

  @computed
  get selectedChannelBySettingsId(): Channel {
    const { settings } = this.rootStore.settingsStore;
    return (
      this.channels.find((channel: Channel) => channel.external_id === settings.channelId) ||
      this.selectedChannel
    );
  }
}
