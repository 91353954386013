import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  margin-top: 15px;
`;

export const ExtensionTopBanner = styled.div`
  padding: 17px;
  & h1 {
    color: #333;
    font-size: 1.7em;
    padding: 21px 0;
  }
  & span {
    color: #545454;
    font-size: 14px;
    margin: 0 0 3px 0;
  }
  & h2 {
    color: #333;
    font-size: 1.5em;
  }

  & a {
    color: #1878b9;
    text-decoration: none;
    line-height: 30px;
    font-size: 14px;
  }
`;

export const ExtensionBanner = styled.div`
  border: 1px solid #e1e1e1;
  background-color: #fafafa;
  color: #545454;
  padding: 17px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  & h3 {
    font-size: 14px;
  }

  & span {
    font-size: 16px;
  }
`;

export const ExtensionCountdown = styled.div`
  background-color: #fafafa;
  border: 1px solid #e1e1e1;
  text-align: center;
`;

export const ExtensionBody = styled.div`
  padding: 25px;
`;

export const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  & .Polaris-SkeletonThumbnail--sizeLarge {
    height: 25rem;
    width: 25rem;
  }

  & img {
    max-width: 95%;
  }
`;

export const ProductName = styled.div`
  color: #585858;
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 1.1;
`;

export const ProductPrice = styled.div`
  font-size: 18px;
  color: #403331;
  margin-bottom: 20px;
  & span:first-child {
    text-decoration: line-through;
    margin-right: 3px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  & button {
    margin-bottom: 20px;
    cursor: default;
  }

  & button:hover {
    text-decoration: none !important;
    cursor: default;
  }

  & button:first-child {
    background-color: #1878b9;
    color: #fff;
  }
`;

export const PaddingContainer = styled.div`
  padding: 17px;
`;

export const ExtensionContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
`;

export const PreviewTitle = styled.div`
  border-bottom: 1px solid rgba(63, 63, 68, 0.15);
  text-align: center;
  font-weight: 500;
  padding: 15px 0 15px 0;
  align-items: center;
  font-size: 18px;
  line-height: 1.5;
  color: #123d55;
  font-family: 'Montserrat', sans-serif;
`;
