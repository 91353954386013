import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../typings/stores.typing';
// import { ContentBody, ContentCard } from '../../Content.styled';
import {
  ContentBody,
  ContentHeader,
  ContentTitle,
  ContentSubtitle,
  ContentWrapper,
  ContentFooter,
  CustomersLabel,
  CustomersBody,
  CustomerTypes,
  CustomerItem,
} from './Settings.styled';
import { Card, Button } from '@shopify/polaris';
import _ from 'lodash';
import history from '../../../../../utils/history';
import 'react-step-progress-bar/styles.css';
//@ts-ignore;
import { ProgressBar, Step } from 'react-step-progress-bar';
import './Settings.css';

interface Props {}

interface State {
  selectedCustomerTypes: {
    first_time_repeat_buyers: boolean;
    repeat_buyers: boolean;
    [key: string]: boolean;
  };
  selectedOrderHistoryItem: string;
  [k: string]: string | boolean | object;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

interface IStep {
  accomplished: boolean;
  index: number;
}

@inject('rootStore')
@observer
class Customers extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 're-order';
    this.state = {
      selectedCustomerTypes: {
        first_time_repeat_buyers: true,
        repeat_buyers: true,
      },
      selectedOrderHistoryItem: '180',
    };
  }

  toastText: string = 'Message template has been updated';

  handleChange = (obj: { [key: string]: string }) => {
    const [key] = Object.keys(obj);

    this.setState(obj);
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const { text_message, text_message2, text_message3 } = this.state;
    try {
      closeToast();
      await updateSettings({
        campaign: 're-order',
        type: 'template',
        data: [
          {
            step: 0,
            text_message: text_message,
          },
          {
            step: 1,
            text_message: text_message2,
          },
          {
            step: 2,
            text_message: text_message3,
          },
        ],
      });
      openToast(this.toastText);
      this.setState({ submitted: true });
      await getSettings();
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  back = () => {
    history.push('/campaigns/automated-reorder/products');
  };

  next = () => {
    const { updateSettings } = this.injected.rootStore.settingsStore;
    const { selectedCustomerTypes, selectedOrderHistoryItem } = this.state;
    updateSettings({
      data: {
        customerTypes: selectedCustomerTypes,
        orderHistoryTimespan: selectedOrderHistoryItem,
      },
      type: 'reorderSettings',
    });
    history.push('/campaigns/automated-reorder/planning');
  };

  componentDidMount() {
    const { settings } = this.injected.rootStore.settingsStore;
    const { selectedCustomerTypes, selectedOrderHistoryItem } = this.state;
    const customerTypes = _.get(settings, 'reorderSettings.customerTypes', selectedCustomerTypes);
    const orderHistoryTimespan = _.get(
      settings,
      'reorderSettings.orderHistoryTimespan',
      selectedOrderHistoryItem,
    );
    this.setState({
      selectedCustomerTypes: customerTypes,
      selectedOrderHistoryItem: orderHistoryTimespan,
    });
  }

  selectCustomerType = (type: string) => {
    this.setState((prevState) => ({
      selectedCustomerTypes: {
        ...prevState.selectedCustomerTypes,
        [type]: !prevState.selectedCustomerTypes[type],
      },
    }));
  };

  selectOrderHistoryItem = (item: string) => {
    this.setState({
      selectedOrderHistoryItem: item,
    });
  };

  customerTypesList = [
    {
      name: 'first_time_repeat_buyers',
      description: 'First-time Repeat Buyers',
    },
    {
      name: 'repeat_buyers',
      description: 'Repeat Buyers',
    },
  ];

  orderHistory = [
    {
      name: 'Last 90 Days',
      value: '90',
    },
    {
      name: 'Last 180 Days',
      value: '180',
    },
    {
      name: 'Last 360 Days',
      value: '360',
    },
  ];

  render() {
    const { selectedCustomerTypes, selectedOrderHistoryItem } = this.state;

    return (
      <Fragment>
        <ContentWrapper>
          <ContentHeader>
            <ContentTitle>ReOrder Campaign Setup</ContentTitle>
            <ContentSubtitle>Let's select one or multiple product types to include</ContentSubtitle>
          </ContentHeader>
          <ContentBody>
            <ProgressBar percent={50} width={'780px'} filledBackground={'#47c1bf'}>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Products</div> */}
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    <div className={`indexedLabel`}>Customers</div>
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Planning</div> */}
                  </Fragment>
                )}
              </Step>
            </ProgressBar>

            <CustomersBody>
              <CustomersLabel>Choose Customer Type</CustomersLabel>
              <CustomerTypes>
                {this.customerTypesList.map((customerType) => (
                  <CustomerItem
                    onClick={() => this.selectCustomerType(customerType.name)}
                    className={selectedCustomerTypes[customerType.name] ? 'selected' : ''}
                  >
                    {customerType.description}
                  </CustomerItem>
                ))}
              </CustomerTypes>

              <CustomersLabel>Order History to Consider for Product Reorders</CustomersLabel>
              <CustomerTypes>
                {this.orderHistory.map((orderHistoryItem, index) => (
                  <CustomerItem
                    key={index}
                    onClick={() => this.selectOrderHistoryItem(orderHistoryItem.value)}
                    className={
                      selectedOrderHistoryItem === orderHistoryItem.value ? 'selected' : ''
                    }
                  >
                    {orderHistoryItem.name}
                  </CustomerItem>
                ))}
              </CustomerTypes>
            </CustomersBody>
          </ContentBody>
          <ContentFooter>
            <Button plain onClick={this.back}>
              ← PREVIOUS
            </Button>
            <Button primary onClick={this.next}>
              NEXT →
            </Button>
          </ContentFooter>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default Customers;
