import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../typings/stores.typing';
// import { ContentBody, ContentCard } from '../../Content.styled';
import {
  ContentBody,
  ContentHeader,
  ContentTitle,
  ContentSubtitle,
  ContentWrapper,
  ContentFooter,
  CustomersLabel,
  CustomersBody,
  CustomerTypes,
  CustomerItem,
} from './Settings.styled';
import { Card, Button, Select, FormLayout } from '@shopify/polaris';
import _ from 'lodash';
import history from '../../../../../utils/history';
import 'react-step-progress-bar/styles.css';
//@ts-ignore;
import { ProgressBar, Step } from 'react-step-progress-bar';
import './Settings.css';
import timezones from 'timezones.json';

interface Props {}

interface State {
  // submitted: boolean;
  selectedTimezone: string;
  selectedDayTime: string;
  selectedReorderInterval: string;
  [k: string]: string | boolean;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

interface IStep {
  accomplished: boolean;
  index: number;
}

@inject('rootStore')
@observer
class Planning extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  timezonesOptions = _.map(timezones, (timezone, index) => {
    return {
      label: timezone.text,
      value: `${index}:${timezone.offset}`,
    };
  });

  dayTimeOptions = _.chain(new Array(24))
    .fill({
      label: '',
      value: '',
    })
    .map((option, index) => {
      return {
        label: `${index}:00`,
        value: `${index}:00`,
      };
    })
    .value();

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 're-order';
    this.state = {
      selectedTimezone: '11:-5',
      selectedDayTime: '8:00',
      selectedReorderInterval: '30',
    };
  }

  toastText: string = 'Message template has been updated';

  handleChange = (obj: { [key: string]: string }) => {
    const [key] = Object.keys(obj);

    this.setState(obj);
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const { text_message, text_message2, text_message3 } = this.state;
    try {
      closeToast();
      // await updateSettings({
      //   campaign: 're-order',
      //   type: 'template',
      //   data: [
      //     {
      //       step: 0,
      //       text_message: text_message,
      //     },
      //     {
      //       step: 1,
      //       text_message: text_message2,
      //     },
      //     {
      //       step: 2,
      //       text_message: text_message3,
      //     },
      //   ],
      // });
      // openToast(this.toastText);
      this.setState({ submitted: true });
      await getSettings();
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  back = () => {
    history.push('/campaigns/automated-reorder/customers');
  };

  next = () => {
    const { updateSettings } = this.injected.rootStore.settingsStore;
    const { selectedTimezone, selectedDayTime, selectedReorderInterval } = this.state;
    updateSettings({
      data: {
        timezone: selectedTimezone,
        daytime: selectedDayTime,
        reorderInterval: selectedReorderInterval,
      },
      type: 'reorderSettings',
    });
    history.push('/campaigns/automated-reorder/preview');
  };

  handleTimezoneChange = (value: string) => {
    this.setState({
      selectedTimezone: value,
    });
  };

  handleDayTimeChange = (value: string) => {
    this.setState({
      selectedDayTime: value,
    });
  };

  componentDidMount() {
    const { settings } = this.injected.rootStore.settingsStore;
    const { selectedTimezone, selectedDayTime, selectedReorderInterval } = this.state;
    const timezone = _.get(settings, 'reorderSettings.timezone', selectedTimezone);
    const daytime = _.get(settings, 'reorderSettings.daytime', selectedDayTime);
    const reorderInterval = _.get(
      settings,
      'reorderSettings.reorderInterval',
      selectedReorderInterval,
    );
    this.setState({
      selectedTimezone: timezone,
      selectedDayTime: daytime,
      selectedReorderInterval: reorderInterval,
    });
  }

  selectReorderInterval = (interval: string) => {
    this.setState({
      selectedReorderInterval: interval,
    });
  };

  reorderInterval = [
    {
      name: 'Every 30 Days',
      value: '30',
    },
    {
      name: 'Every 60 Days',
      value: '60',
    },
    {
      name: 'Every 90 Days',
      value: '90',
    },
  ];

  render() {
    const { selectedTimezone, selectedDayTime, selectedReorderInterval } = this.state;

    return (
      <Fragment>
        <ContentWrapper>
          <ContentHeader>
            <ContentTitle>ReOrder Campaign Setup</ContentTitle>
            <ContentSubtitle>Let's select one or multiple product types to include</ContentSubtitle>
          </ContentHeader>
          <ContentBody>
            <ProgressBar percent={100} width={'780px'} filledBackground={'#47c1bf'}>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Products</div> */}
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Customers</div> */}
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    <div className={`indexedLabel`}>Planning</div>
                  </Fragment>
                )}
              </Step>
            </ProgressBar>

            <CustomersBody>
              <CustomersLabel>Choose Reorder Interval</CustomersLabel>
              <CustomerTypes>
                {this.reorderInterval.map((interval, index) => (
                  <CustomerItem
                    key={index}
                    onClick={() => this.selectReorderInterval(interval.value)}
                    className={selectedReorderInterval === interval.value ? 'selected' : ''}
                  >
                    {interval.name}
                  </CustomerItem>
                ))}
              </CustomerTypes>

              <CustomersLabel>Choose Campaign Delivery Time</CustomersLabel>

              <CustomerTypes>
                <FormLayout>
                  <FormLayout.Group>
                    <Select
                      label="Time Zone"
                      options={this.timezonesOptions}
                      onChange={this.handleTimezoneChange}
                      value={selectedTimezone}
                    />
                    <Select
                      label="Time"
                      options={this.dayTimeOptions}
                      onChange={this.handleDayTimeChange}
                      value={selectedDayTime}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </CustomerTypes>
            </CustomersBody>
          </ContentBody>
          <ContentFooter>
            <Button plain onClick={this.back}>
              ← PREVIOUS
            </Button>
            <Button primary onClick={this.next}>
              NEXT →
            </Button>
          </ContentFooter>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default Planning;
