import { action, observable } from 'mobx';
import RootStore from '../RootStore';
import { IError, IErrorResponse } from '../../typings/elements.typing';
import * as Sentry from '@sentry/react';

export default class ErrorStore {
  constructor(private rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable error: IError = {
    isShow: false,
    status: null,
    message: '',
    redirect: false,
    isButtonBack: false,
  };

  @action.bound
  setError(e: IErrorResponse): void {
    this.error = {
      isShow: true,
      status: e.status,
      message: e.error,
      redirect: e.redirect,
      isButtonBack: e.isButtonBack || false,
    };

    Sentry.captureException(e.status + ' ' + e.error);
  }

  @action.bound
  clearError(): void {
    this.error = {
      isShow: false,
      status: null,
      message: '',
      redirect: false,
      isButtonBack: false,
    };
  }
}
