import styled from 'styled-components';
import EmojiIcon from '../EmojiIcon/EmojiIcon';
import { IEmojiIconWrapper } from '../../../typings/elements.typing';

export const Wrapper = styled.div`
  position: relative;
  .emoji-mart {
    position: absolute;
    left: 0;
    z-index: 100;
  }
  .emoji-mart-bar {
    display: none;
  }
  button.emoji-mart-emoji,
  .emoji-mart-category .emoji-mart-emoji span {
    cursor: pointer;
  }
  button.emoji-mart-emoji:focus {
    outline: none;
  }
`;

export const EmojiIconWrapper = styled(EmojiIcon)`
  position: absolute;
  cursor: pointer;
  display: block;
  bottom: ${(props: IEmojiIconWrapper) => (props.error ? '34' : '10')}px;
  left: 10px;
  z-index: 99;
  line-height: 1;
`;
