import { Button } from '@shopify/polaris';
import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import {
  CentredSection,
  PageContent,
  OnboardingWrapper,
  LogoWrapperAbsolute,
  HeaderTitle,
  PageHeaderCentered,
  ContentCentered,
  OnboardingWrapperPricing,
} from '../OnBoarding.styled';
import {
  PlansList,
  PlanCard,
  PlanTitle,
  PlanPrice,
  PlanSubPrice,
  PlanSubDescription,
  PlanFeaturesList,
  PlanCardHeader,
  PlanSubPricePayG,
} from './Pricing.styled';
import history from '../../../utils/history';
import Logo from '../../shared/Logo/Logo';
import { IRootStore } from '../../../typings/stores.typing';
import _ from 'lodash';
import './Pricing.css';

interface Props {}

interface State {
  disabled_button: {
    [key: string]: boolean;
  };
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

interface IPlansPrices {
  [key: string]: {
    [key: string]: {
      price: number;
      per_sms: number;
      per_order: number;
    };
  };
}

@inject('rootStore')
@observer
class Pricing extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      disabled_button: {},
    };
  }

  componentDidMount() {}

  selectPlan = async (plan: string) => {
    const { settings, updateSettings, getSettings } = this.injected.rootStore.settingsStore;
    const { getRedirectURL } = this.injected.rootStore.billingStore;
    const { disabled_button } = this.state;
    disabled_button[plan] = true;
    this.setState({
      disabled_button: disabled_button,
    });
    const redirectUrl = await getRedirectURL(plan);

    window.location.href = redirectUrl;
  };

  plans_prices: IPlansPrices = {
    us: {
      pay_as_you_go: {
        price: 0,
        per_sms: 0.015,
        per_order: 0,
      },
      growth: {
        price: 7.99,
        per_sms: 0.015,
        per_order: 0,
      },
      enterprise: {
        price: 14.99,
        per_sms: 0.0145,
        per_order: 0,
      },
      star: {
        price: 29.99,
        per_sms: 0.0129,
        per_order: 0.03,
      },
    },
    nl: {
      pay_as_you_go: {
        price: 0,
        per_sms: 0.015,
        per_order: 0,
      },
      growth: {
        price: 7.99,
        per_sms: 0.015,
        per_order: 0,
      },
      enterprise: {
        price: 14.99,
        per_sms: 0.0145,
        per_order: 0,
      },
      star: {
        price: 29.99,
        per_sms: 0.0129,
        per_order: 0.03,
      },
    },
    ca: {
      pay_as_you_go: {
        price: 0,
        per_sms: 0.01,
        per_order: 0,
      },
      growth: {
        price: 7.99,
        per_sms: 0.01,
        per_order: 0,
      },
      enterprise: {
        price: 14.99,
        per_sms: 0.009,
        per_order: 0,
      },
      star: {
        price: 29.99,
        per_sms: 0.008,
        per_order: 0.03,
      },
    },
  };

  apply_discount = (price: number, digits: number = 0) => {
    return price;
    // discounts is disabled for now, just bypass
    // return (price * 0.8).toFixed(digits);
  };

  render() {
    const { settings } = this.injected.rootStore.settingsStore;
    let country_code = _.get(settings, 'shopData.country_code', 'us');
    country_code = _.lowerCase(country_code);
    // if country is not supported -> make us as default country
    if (!['us', 'nl', 'ca'].includes(country_code)) {
      country_code = 'us';
    }
    const { disabled_button } = this.state;

    return (
      <Fragment>
        <PageContent>
          <OnboardingWrapperPricing>
            <LogoWrapperAbsolute>
              <Logo width="57px" height="32px" />
            </LogoWrapperAbsolute>
            <CentredSection>
              <PageHeaderCentered>
                <HeaderTitle>Select a Plan</HeaderTitle>
              </PageHeaderCentered>

              <ContentCentered>
                <h2>
                  Get started for free. Only pay when you pass 50 monthly store orders. Change or
                  cancel your plan at any time.
                </h2>

                <PlansList>
                  <PlanCard>
                    <div>
                      {/*<div className="ribbon">20% OFF</div>*/}
                      <PlanCardHeader>
                        <PlanTitle>Free Cart Upsell</PlanTitle>
                        <PlanPrice>Free</PlanPrice>
                        {/*<PlanSubDescription>*/}
                        {/*  Pay as you go or buy credits to save on SMS costs. Change or cancel your*/}
                        {/*  plan at any time.*/}
                        {/*</PlanSubDescription>*/}
                      </PlanCardHeader>
                      <PlanFeaturesList>
                        <ul>
                          <li>Up to 50 monthly store orders</li>
                          <li>In cart upsell & cross sell</li>
                          <li>Post-purchase page</li>
                          <li>A/B testing</li>
                          <li>ROI analytics</li>
                          <li>Chat Support</li>
                        </ul>
                      </PlanFeaturesList>
                    </div>
                    <Button
                      primary
                      size="large"
                      disabled={disabled_button['pay_as_you_go']}
                      onClick={() => this.selectPlan('pay_as_you_go')}
                    >
                      Select Plan
                    </Button>
                  </PlanCard>

                  <PlanCard>
                    <div>
                      {/*<div className="ribbon">20% OFF</div>*/}
                      <PlanCardHeader>
                        <PlanTitle>Pro Cart Upsell</PlanTitle>
                        <PlanPrice>
                          $ {this.apply_discount(this.plans_prices[country_code].growth.price)} /
                          month
                        </PlanPrice>
                        {/*<PlanSubPrice>*/}
                        {/*  $ {this.apply_discount(this.plans_prices[country_code].growth.per_sms, 4)}{' '}*/}
                        {/*  per additional SMS after all SMS credits are used*/}
                        {/*</PlanSubPrice>*/}
                      </PlanCardHeader>
                      <PlanFeaturesList>
                        <ul>
                          <li>Up to 100 monthly store orders</li>
                          <li>In cart upsell & cross sell</li>
                          <li>Post-purchase page</li>
                          <li>A/B testing</li>
                          <li>ROI analytics</li>
                          <li>Chat Support</li>
                        </ul>
                      </PlanFeaturesList>
                    </div>
                    <Button
                      primary
                      size="large"
                      disabled={disabled_button['growth']}
                      onClick={() => this.selectPlan('growth')}
                    >
                      Select Plan
                    </Button>
                  </PlanCard>

                  <PlanCard>
                    <div>
                      <div className="ribbon">Most Popular</div>
                      <PlanCardHeader>
                        <PlanTitle>VIP Cart Upsell</PlanTitle>
                        <PlanPrice>
                          $ {this.apply_discount(this.plans_prices[country_code].enterprise.price)}{' '}
                          / month
                        </PlanPrice>
                      </PlanCardHeader>
                      <PlanFeaturesList>
                        <ul>
                          <li>Up to 200 monthly store orders</li>
                          <li>In cart upsell & cross sell</li>
                          <li>Post-purchase page</li>
                          <li>A/B testing</li>
                          <li>ROI analytics</li>
                          <li>Chat Support</li>
                        </ul>
                      </PlanFeaturesList>
                    </div>
                    <Button
                      primary
                      size="large"
                      disabled={disabled_button['enterprise']}
                      onClick={() => this.selectPlan('enterprise')}
                    >
                      Select Plan
                    </Button>
                  </PlanCard>

                  <PlanCard>
                    <div>
                      <PlanCardHeader>
                        <PlanTitle>Star Cart Upsell</PlanTitle>
                        <PlanPrice>
                          $ {this.apply_discount(this.plans_prices[country_code].star.price)} /
                          month
                        </PlanPrice>
                      </PlanCardHeader>
                      <PlanFeaturesList>
                        <ul>
                          <li>
                            Up to 500 monthly store orders ($
                            {this.plans_prices[country_code].star.per_order} per additional order)
                          </li>
                          <li>In cart upsell & cross sell</li>
                          <li>Post-purchase page</li>
                          <li>A/B testing</li>
                          <li>ROI analytics</li>
                          <li>Chat Support</li>
                        </ul>
                      </PlanFeaturesList>
                    </div>
                    <Button
                      primary
                      size="large"
                      disabled={disabled_button['star']}
                      onClick={() => this.selectPlan('star')}
                    >
                      Select Plan
                    </Button>
                  </PlanCard>
                </PlansList>
              </ContentCentered>
            </CentredSection>
          </OnboardingWrapperPricing>
        </PageContent>
      </Fragment>
    );
  }
}

export default Pricing;
