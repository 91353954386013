import React, { Component, ErrorInfo, Fragment } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import OnBoarding from '../OnBoardingNew/OnBoarding';
import { inject, observer } from 'mobx-react';
import Home from '../Home/Home';
import Dashboard from '../Dashboard/Dashboard';
import { GlobalStyles } from './App.styled';
import history from '../../utils/history';
import { IRootStore } from '../../typings/stores.typing';
import { IToken, TokenData } from '../../typings/elements.typing';
import ModalError from '../ModalError/ModalError';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';

// @ts-ignore
import FullStory from 'react-fullstory';
import { Mixpanel } from '../../utils/analytics';
import jwtDecode from 'jwt-decode';

import {
  FULLSTORY_ORG,
  FACEBOOK_APP_ID,
  INTERCOM_APP_ID,
  PIXEL_ID,
  // LINKEDIN_ID,
} from '../../config';
import Intercom from 'react-intercom';
import { initGA, logPageView } from '../../utils/analytics';
// import LinkedInTag from '../../utils/linkedin';

// @ts-ignore
import { getParsedToken } from '../../utils/token';

// @ts-ignore
import ReactPixel from 'react-facebook-pixel';
//@ts-ignore
import { FullStoryAPI, getCurrentSessionUrl } from 'react-fullstory';
import ModalSubscription from '../ModalSubscription/ModalSubscription';
import { fromEntries } from '../../utils/entries';

interface Props {
  location?: Location;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
// @ts-ignore
@withRouter
@observer
class App extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Sentry.captureException(error, { extra: errorInfo });
  }

  componentDidMount(): void {
    Mixpanel.track('page_load', {});
    this.initializeApp();
    // @ts-ignore
    if (!window.GA_INITIALIZED) {
      initGA();
      // @ts-ignore
      window.GA_INITIALIZED = true;
    }
    // if (LINKEDIN_ID) {
    //   LinkedInTag.init(LINKEDIN_ID);
    // }

    const token: string = localStorage.getItem('accessToken') || '';
    let shopDomain = '';

    try {
      const parseToken: IToken = token ? jwtDecode(token) : {};
      shopDomain = parseToken.shopDomain || '';
      Mixpanel.identify(shopDomain);
    } catch (e) {
      console.log(e);
    }

    history.listen(() => {
      // Mixpanel.track('page_visit', { page: window.location.pathname });
      logPageView();
    });
  }

  initializeApp(): void {
    const { settingsStore, authStore } = this.injected.rootStore;
    const { subscriptionUpdate } = this.injected.rootStore.billingStore;
    // const { settings } = this.injected.rootStore.settingsStore;
    const urlParams = new URLSearchParams(location.search);
    // @ts-ignore
    const params: TokenData = fromEntries(urlParams.entries());
    const chargeId = params.charge_id || '';

    if (window.location.pathname === '/maxwell') {
      // Ignore receiving token for this Route
      return;
    }
    authStore
      .getTokenFromServer(chargeId ? {} : params)
      .then(settingsStore.getSettings)
      .then(async () => {
        if (
          location.pathname === '/subscribe/confirm' ||
          location.pathname === '/subscribe/confirm/settings'
        ) {
          const path =
            location.pathname === '/subscribe/confirm' ? '/onboarding/congrats' : '/settings?plan';
          await subscriptionUpdate(chargeId);
          await settingsStore.getSettings();
          history.push(path);
          return;
        }
      })
      .then(async () => {
        FullStoryAPI('identify', settingsStore.settings.domainName, {
          firstName: settingsStore.settings.firstName,
          appName: 'Maxwell SMS ReOrders',
        });
      })
      .catch((e: any) => e)
      .finally(() => {
        settingsStore.setIsLoaded(true);
        const ignoredForRedirect = ['/onboarding/congrats', '/settings'];
        if (!ignoredForRedirect.includes(location.pathname)) {
          history.push(settingsStore.settings.profile.onBoardingComplete ? '/home' : '/onboarding');
        }
      });
  }

  getBillingStatus = async (): Promise<void> => {
    const {
      settings: { isShowed },
      getStatus,
    } = this.injected.rootStore.billingStore;
    const { settings } = this.injected.rootStore.settingsStore;
    if (!isShowed && settings.onBoardingComplete) await getStatus();
  };

  updateThemeStore = () => {
    const { settings } = this.injected.rootStore.settingsStore;
    const { getTheme, updateOptions } = this.injected.rootStore.themeStore;
    const {
      activeTheme: { name: themeName, options: currentThemeOptions },
    } = settings;

    if (themeName) {
      const getThemeOptions = getTheme(themeName);
      const hasThemeOptions = Boolean(currentThemeOptions);
      const themeOptions = hasThemeOptions ? currentThemeOptions : getThemeOptions[0].options;

      updateOptions(themeName, themeOptions);
    }
  };

  render() {
    const { settingsStore } = this.injected.rootStore;
    let parseToken: IToken = {};
    try {
      parseToken = getParsedToken();
    } catch (e) {
      Sentry.captureException(e);
    }

    const intercomSettings = {
      store: parseToken.domainName || '',
      appName: 'Maxwell SMS ReOrders',
      first_name: settingsStore.settings.profile.firstName,
      fullstory:
        window.FS && window.FS.getCurrentSessionURL ? window.FS.getCurrentSessionURL() : '',
    };
    const isLoaded = settingsStore.isLoaded;
    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Maxwell | One Click Upsell & SMS ReOrder</title>
        </Helmet>
        <GlobalStyles />
        <Switch>
          <Route path="/onboarding" component={() => (isLoaded ? <OnBoarding /> : <Home />)} />
          <Route path="/" component={() => (isLoaded ? <Dashboard /> : <Home />)} />
          <Route path="/maxwell" component={OnBoarding} />
          <Route path="/subscription/update" />
          <Redirect path="*" to="/" />
        </Switch>
        <ModalError />
        <ModalSubscription />
        {FULLSTORY_ORG && (
          <FullStory org={FULLSTORY_ORG} host="fullstory.com" debug={false} namespace="FS" />
        )}
        <Intercom appID={INTERCOM_APP_ID} {...intercomSettings} />
      </Fragment>
    );
  }
}

export default App;
