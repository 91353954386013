import React, { ChangeEvent, Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { IRootStore } from '../../../../../typings/stores.typing';
// import { ContentBody, ContentCard } from '../../Content.styled';
import {
  ContentBody,
  ContentHeader,
  ContentTitle,
  ContentSubtitle,
  ContentWrapper,
  CategoriesList,
  CategoryItem,
  CategoryTitle,
  CategoryBody,
  CategoryDescription,
  CategoryProducts,
  ContentFooter,
  ProductImage,
  CategoryProductsCount,
} from './Settings.styled';
import { Card, Button } from '@shopify/polaris';
import _ from 'lodash';
import history from '../../../../../utils/history';
import 'react-step-progress-bar/styles.css';
//@ts-ignore;
import { ProgressBar, Step } from 'react-step-progress-bar';
import './Settings.css';

interface Props {}

interface ISelectedCategory {
  low: boolean;
  medium: boolean;
  high: boolean;
  [key: string]: boolean;
}

interface State {
  selectedCategories: ISelectedCategory;
  [k: string]: string | boolean | ISelectedCategory;
}

interface ICategoryItem {
  name: string;
  count: number;
  first_image: string;
  products: any;
  reorderPercentage: number;
}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

interface ICategory {
  [key: string]: string;
}

interface ICategories {
  [key: string]: string;
}

interface IStep {
  accomplished: boolean;
  index: number;
}

interface IProduct {
  productId: string;
}

@inject('rootStore')
@observer
class Products extends Component<Props, State> {
  get injected() {
    return this.props as InjectedProps;
  }

  constructor(props: Props) {
    super(props);
    const { settings } = this.injected.rootStore.settingsStore;
    const campaign = 're-order';
    this.state = {
      selectedCategories: {
        low: true,
        medium: true,
        high: true,
      },
    };
  }

  toastText: string = 'Message template has been updated';

  handleChange = (obj: { [key: string]: string }) => {
    const [key] = Object.keys(obj);

    this.setState(obj);
  };

  handleSubmit = async () => {
    const {
      openToast,
      closeToast,
      updateSettings,
      getSettings,
    } = this.injected.rootStore.settingsStore;
    const { text_message, text_message2, text_message3 } = this.state;
    try {
      closeToast();
      await updateSettings({
        campaign: 're-order',
        type: 'template',
        data: [
          {
            step: 0,
            text_message: text_message,
          },
          {
            step: 1,
            text_message: text_message2,
          },
          {
            step: 2,
            text_message: text_message3,
          },
        ],
      });
      openToast(this.toastText);
      this.setState({ submitted: true });
      await getSettings();
    } catch (err) {
      console.log('[error]: ', err);
    }
  };

  back = () => {
    history.push('/campaigns/automated-reorder');
  };

  next = async () => {
    const { settings, updateSettings } = this.injected.rootStore.settingsStore;
    const { selectedCategories } = this.state;
    let products = _.get(settings, 'products', []);
    let productsByCategories = _.chain(products)
      .groupBy('reorderChance')
      .mapValues((category) => {
        return _.map(category, 'productId');
      })
      .value();
    const categoriesList = _.map(selectedCategories, (selected, category) => {
      return selected ? category : '';
    });
    //@ts-ignore;
    let selectedProducts = _.pick(productsByCategories, categoriesList);

    let productIds: [] = [];
    _.each(selectedProducts, (item) => {
      //@ts-ignore;
      productIds = _.concat(productIds, item);
    });

    updateSettings({
      data: {
        productCategories: selectedCategories,
        productIds: productIds,
      },
      type: 'reorderSettings',
    });
    history.push('/campaigns/automated-reorder/customers');
  };

  async componentDidMount() {
    const { settings } = this.injected.rootStore.settingsStore;
    const { selectedCategories } = this.state;
    const productCategories = _.get(
      settings,
      'reorderSettings.productCategories',
      selectedCategories,
    );
    this.setState({
      selectedCategories: productCategories,
    });
  }

  selectCategory = (category: string) => {
    console.log(category);
    this.setState((prevState) => ({
      selectedCategories: {
        ...prevState.selectedCategories,
        [category]: !prevState.selectedCategories[category],
      },
    }));
  };

  render() {
    const { settings } = this.injected.rootStore.settingsStore;
    let { products } = settings;
    let categories = _.chain(products)
      .groupBy('reorderChance')
      .map(
        (items, key): ICategoryItem => {
          return {
            name: key,
            products: items,
            first_image: _.get(items, '[0].image', ''),
            count: items.length,
            reorderPercentage: _.multiply(_.get(items, '[0].reorderPercentage', 0), 100),
          };
        },
      )
      .value();

    const categoriesTitles: ICategories = {
      low: 'LOW LIKELIHOOD',
      moderate: 'MODERATE LIKELIHOOD',
      high: 'HIGH LIKELIHOOD',
      extreme: 'VERY HIGH LIKELIHOOD',
    };
    const { selectedCategories } = this.state;

    return (
      <Fragment>
        <ContentWrapper>
          <ContentHeader>
            <ContentTitle>ReOrder Campaign Setup</ContentTitle>
            <ContentSubtitle>Let's select one or multiple product types to include</ContentSubtitle>
          </ContentHeader>
          <ContentBody>
            <ProgressBar percent={0} width={'780px'} filledBackground={'#47c1bf'}>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    <div className={`indexedLabel`}>Products</div>
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Customers</div> */}
                  </Fragment>
                )}
              </Step>
              <Step>
                {({ accomplished, index }: IStep) => (
                  <Fragment>
                    <div className={`indexedStep ${accomplished ? 'accomplished' : ''}`}>
                      {accomplished ? '✔' : index + 1}
                    </div>
                    {/* <div className={`indexedLabel`}>Planning</div> */}
                  </Fragment>
                )}
              </Step>
            </ProgressBar>
            <CategoriesList>
              {categories.map((category: ICategoryItem, index: number) => (
                <CategoryItem key={index} onClick={() => this.selectCategory(category.name)}>
                  <CategoryTitle>{categoriesTitles[category.name]}</CategoryTitle>
                  <CategoryBody className={selectedCategories[category.name] ? 'selected' : ''}>
                    <CategoryProducts>
                      <ProductImage src={category.first_image} />
                    </CategoryProducts>
                    <CategoryDescription>
                      These products have a {category.reorderPercentage}% of being reordered
                    </CategoryDescription>
                  </CategoryBody>
                  <CategoryProductsCount>Products found: {category.count}</CategoryProductsCount>
                </CategoryItem>
              ))}
            </CategoriesList>
          </ContentBody>
          <ContentFooter>
            <Button plain onClick={this.back}>
              ← PREVIOUS
            </Button>
            <Button primary onClick={this.next}>
              NEXT →
            </Button>
          </ContentFooter>
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default Products;
