import React, { Component } from 'react';

interface Props {
  width?: string;
  height?: string;
  className?: string;

  onClick(e?: React.MouseEvent<HTMLElement>): void;
}

export default class EmojiIcon extends Component<Props> {
  static defaultProps: Partial<Props> = {
    width: '16px',
    height: '16px',
  };

  render(): React.ReactNode {
    const { width, height, className, onClick } = this.props;
    return (
      <div onClick={onClick} className={className}>
        <svg
          width={width}
          height={height}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          y="0px"
          viewBox="0 0 1000 1000"
          enableBackground="new 0 0 1000 1000"
          xmlSpace="preserve"
        >
          <g>
            <path d="M500,10C229.4,10,10,229.4,10,500c0,270.6,219.4,490,490,490c270.6,0,490-219.4,490-490C990,229.4,770.6,10,500,10z M500,928.8c-236.8,0-428.8-192-428.8-428.8c0-236.8,192-428.8,428.8-428.8c236.8,0,428.8,192,428.8,428.8C928.8,736.8,736.8,928.8,500,928.8z" />
            <path d="M255,377.5c0,33.8,27.4,61.3,61.3,61.3c33.8,0,61.3-27.4,61.3-61.3c0-33.8-27.4-61.3-61.3-61.3C282.4,316.3,255,343.7,255,377.5z" />
            <path d="M622.5,377.5c0,33.8,27.4,61.3,61.3,61.3s61.3-27.4,61.3-61.3c0-33.8-27.4-61.3-61.3-61.3S622.5,343.7,622.5,377.5z" />
            <path d="M653.1,591.9H346.9c-16.8,0-30.6,13.8-30.6,30.6c0,101.5,82.3,183.8,183.8,183.8c101.5,0,183.8-82.3,183.8-183.8C683.8,605.7,670,591.9,653.1,591.9z" />
          </g>
        </svg>
      </div>
    );
  }
}
