import Raven from 'raven-js';
import { version } from '../package.json';
import { ERROR_REPORTING_KEY } from './config';
import * as Sentry from '@sentry/react';

const configSentry = () => {
  ERROR_REPORTING_KEY
    ? Sentry.init({
        dsn: ERROR_REPORTING_KEY,
        environment: process.env.NODE_ENV,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
      })
    : null;
};

export default configSentry();
