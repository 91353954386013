import React, { Component } from 'react';
import {
  ChecklistItem,
  ChecklistItemButton,
  ChecklistItemText,
  ChecklistWrapper,
  CloseIcon,
  ProgressBarContainerTitle,
  ProgressBarContainerWrapper,
} from './ProgressBarContainer.styled';
import { ProgressBar, TextStyle } from '@shopify/polaris';
import CheckIcon from '../../../../shared/CheckIcon/CheckIcon';
import CloseNavigationIcon from '../../../../shared/CloseNavigationIcon/CloseNavigationIcon';
import { IRootStore } from '../../../../../typings/stores.typing';
import { inject, observer } from 'mobx-react';
import { IItemProgressBar } from '../../../../../typings/elements.typing';
import history from '../../../../../utils/history';

interface Props {}

interface InjectedProps extends Props {
  rootStore: IRootStore;
}

@inject('rootStore')
@observer
class ProgressBarContainer extends Component<Props> {
  get injected() {
    return this.props as InjectedProps;
  }

  colors = {
    gray: '#637381',
  };

  closeNotification = () => {
    const { updateNotification, progressBarNotification } = this.injected.rootStore.settingsStore;
    if (progressBarNotification) {
      updateNotification({ ...progressBarNotification, isClosed: true });
    }
  };

  upgradePlan = async (e: React.SyntheticEvent<EventTarget>) => {
    const {
      getRedirectURL,
      settings: { isSubscribed },
    } = this.injected.rootStore.billingStore;
    e.preventDefault();
    if (!isSubscribed) {
      try {
        const url = await getRedirectURL();
        if (url) {
          window.location.href = url;
        }
      } catch (e) {
        const error = await e.json();
        error.status = e.status;
        this.injected.rootStore.errorStore.setError(error);
      }
    }
  };

  async navigateTo(link: string, newTab = false, type: string) {
    const { updateSettings } = this.injected.rootStore.settingsStore;
    let data: any = {};
    data[type] = true;

    await updateSettings({
      data: data,
      type: 'profile',
    });
    if (!newTab) {
      return history.push(link);
    }
    return window.open(link, '_blank');
  }

  render() {
    const { itemsProgressBar, progressBar } = this.injected.rootStore.settingsStore;
    const { gray } = this.colors;

    return (
      <ProgressBarContainerWrapper>
        <CloseIcon onClick={this.closeNotification}>
          <CloseNavigationIcon color="#637381" width="15px" height="15px" />
        </CloseIcon>
        <ProgressBarContainerTitle>
          <TextStyle variation="subdued">
            Write us any time if there are any questions or ways in which we can help 😊
          </TextStyle>
        </ProgressBarContainerTitle>
        <ProgressBar progress={progressBar} />
        <ChecklistWrapper>
          {itemsProgressBar.map((el: IItemProgressBar) => {
            if (el.id === 'cartUpsellCampaign') {
              return (
                <ChecklistItem key={el.id}>
                  <CheckIcon isChecked={el.isChecked} />
                  <ChecklistItemText
                    onClick={() => this.navigateTo(el.link, false, 'cartUpsellCampaign')}
                    color={gray}
                  >
                    {el.title}
                  </ChecklistItemText>
                </ChecklistItem>
              );
            }
            if (el.id === 'postPurchaseUpsellCampaign') {
              return (
                <ChecklistItem key={el.id}>
                  <CheckIcon isChecked={el.isChecked} />
                  <ChecklistItemText
                    onClick={() => this.navigateTo(el.link, false, 'postPurchaseUpsellCampaign')}
                    color={gray}
                  >
                    {el.title}
                  </ChecklistItemText>
                </ChecklistItem>
              );
            }
            if (el.id === 'firstCartUpsell') {
              return (
                <ChecklistItem key={el.id}>
                  <CheckIcon isChecked={el.isChecked} />
                  <ChecklistItemButton color={gray}>{el.title}</ChecklistItemButton>
                </ChecklistItem>
              );
            }
            if (el.id === 'firstPostPurchaseUsell') {
              return (
                <ChecklistItem key={el.id}>
                  <CheckIcon isChecked={el.isChecked} />
                  <ChecklistItemButton color={gray}>{el.title}</ChecklistItemButton>
                </ChecklistItem>
              );
            }
          })}
        </ChecklistWrapper>
      </ProgressBarContainerWrapper>
    );
  }
}

export default ProgressBarContainer;
