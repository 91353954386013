import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
  flex: 1;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const DashboardBackground = styled.div`
  position: absolute;
  background-color: #3b3b3b;
  height: 100%;
  width: 100%;
`;

export const DashboardFrame = styled.div`
  .Polaris-Frame {
    min-height: auto;
  }
`;
