import * as _ from 'lodash';

export const getNumberWithOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

export const renderPrice = (price: string | number, money_with_currency_format: string) => {
  //@ts-ignore
  const prepared_price = parseFloat(price).toFixed(2);
  const cleanMoneyFormat = money_with_currency_format.replace(/<[^>]*>?/gm, '');
  const PRICE_PLACEHOLDER = new RegExp(/{{(amount_with_comma_separator|amount)}}/gi);
  return cleanMoneyFormat.replace(PRICE_PLACEHOLDER, prepared_price);
};
